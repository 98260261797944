import React from 'react';
import type { Size } from '../../types';

const looking = (width: Size = '323', height: Size = '391') => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 323 391"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                opacity="0.7"
                d="M292.365 226.24C301.592 150.424 247.556 81.4911 171.672 72.2729C95.7889 63.0547 26.7939 117.042 17.5674 192.858C8.34092 268.673 62.377 337.607 138.26 346.825C214.144 356.043 283.139 302.055 292.365 226.24Z"
                fill="#21BF96"
            />
            <g clipPath="url(#clip0_461_44785)">
                <path
                    opacity="0.8"
                    d="M152.134 389.706C186.658 386.777 213.866 376.174 212.968 366.026C212.027 355.877 183.321 350.018 148.797 352.948C114.316 355.877 87.1072 366.479 88.0056 376.628C88.904 386.777 117.653 392.635 152.134 389.706Z"
                    fill="url(#paint0_radial_461_44785)"
                />
                <path
                    d="M281.162 270.684C247.664 280.915 217.59 236.978 194.317 232.233C176.221 228.562 163.472 242.919 169.932 258.059C166.509 254.553 166.338 253.893 163.044 251.5C160.477 233.925 173.697 218.702 198.381 223.735C224.948 229.139 250.616 265.362 275.686 257.688L281.162 270.684Z"
                    fill="url(#paint1_linear_461_44785)"
                />
                <path
                    d="M169.802 257.853C169.845 257.936 169.845 258.019 169.888 258.061C169.845 258.019 169.845 258.019 169.802 257.978C169.802 257.978 169.802 257.895 169.802 257.853Z"
                    fill="url(#paint2_linear_461_44785)"
                />
                <path
                    d="M281.16 270.685C280.818 270.808 280.476 270.85 280.091 270.973L277.182 264.125C252.07 271.799 226.444 235.576 199.877 230.172C177.802 225.675 164.882 237.35 164.241 252.491C163.856 252.202 163.471 251.913 163 251.542C160.433 233.967 173.653 218.744 198.337 223.777C224.904 229.182 250.572 265.404 275.642 257.73L281.16 270.685Z"
                    fill="#123161"
                />
                <path
                    d="M285.952 254.099C285.438 251.046 280.262 252.779 275.727 254.883C271.406 256.863 266.144 260.411 265.759 265.238L267.77 264.908C268.241 262.804 275.856 260.617 278.166 259.957C281.674 258.967 286.508 257.152 285.952 254.099Z"
                    fill="url(#paint3_radial_461_44785)"
                />
                <path
                    d="M271.535 259.71L277.182 253.769L285.396 269.157L278.38 271.096C274.187 268.91 271.706 265.609 271.535 259.71Z"
                    fill="url(#paint4_radial_461_44785)"
                />
                <path
                    d="M291.855 259.215C293.438 262.02 288.39 264.578 283.599 266.476C279.021 268.25 273.588 264.99 270.166 261.566L271.92 260.287C273.631 261.566 280.048 261.896 282.144 260.659C285.438 258.72 290.273 256.409 291.855 259.215Z"
                    fill="url(#paint5_radial_461_44785)"
                />
                <path
                    d="M275.385 247.292C274.7 244.28 269.609 246.26 265.16 248.529C260.925 250.716 259.257 256.904 259.085 261.731L261.096 261.319C261.438 259.215 265.588 254.223 267.813 253.48C271.321 252.366 276.069 250.345 275.385 247.292Z"
                    fill="url(#paint6_radial_461_44785)"
                />
                <path
                    d="M154.573 355.794C154.573 355.794 117.525 337.023 108.156 325.719C99.728 304.513 83.9847 251.83 92.5408 205.087C92.5408 205.087 117.953 187.1 170.102 188.255C215.407 189.245 233.418 204.138 233.418 204.138C233.418 204.138 232.348 280.131 205.824 334.795C183.45 348.12 154.573 355.794 154.573 355.794Z"
                    fill="url(#paint7_radial_461_44785)"
                />
                <path
                    opacity="0.4"
                    d="M140.755 224.271C140.755 224.271 117.525 337.023 108.156 325.719C99.728 304.513 83.9847 251.83 92.5408 205.087C92.5408 205.087 117.953 187.1 170.102 188.255C215.407 189.245 233.418 204.138 233.418 204.138L140.755 224.271Z"
                    fill="url(#paint8_radial_461_44785)"
                />
                <path
                    opacity="0.4"
                    d="M205.824 333.434L154.572 355.794L233.417 204.139C228.668 250.923 221.225 295.438 205.824 333.434Z"
                    fill="url(#paint9_radial_461_44785)"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M195.557 249.149L225.289 267.59L221.225 282.608L191.492 264.166L195.557 249.149Z"
                    fill="url(#paint10_linear_461_44785)"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M195.599 248.984L200.648 248.736L229.054 265.899L225.033 282.112L221.353 282.648L221.268 282.483L225.289 267.549L195.813 249.273C195.685 249.149 195.599 248.984 195.599 248.984Z"
                    fill="url(#paint11_radial_461_44785)"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M229.396 235.494L259.128 253.935L255.064 268.952L225.332 250.552L229.396 235.494Z"
                    fill="url(#paint12_linear_461_44785)"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M229.439 235.37L234.487 235.081L262.893 252.243L258.872 268.457L255.15 268.993L255.064 268.869L259.086 253.893L229.61 235.658C229.524 235.493 229.439 235.37 229.439 235.37Z"
                    fill="url(#paint13_radial_461_44785)"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M206.124 262.433L263.792 244.281C263.792 244.281 305.76 269.941 306.145 274.108C306.53 278.275 300.455 300.883 297.888 306.948C295.364 312.971 275.6 319.077 275.6 319.077L243.985 312.022L203.087 286.939C203.129 286.98 200.434 275.8 206.124 262.433Z"
                    fill="#123161"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M306.187 275.016C305.888 280.833 300.327 301.254 297.931 306.989C295.407 313.012 275.642 319.118 275.642 319.118L244.241 312.146L249.631 291.188L306.187 275.016Z"
                    fill="#123161"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M302.038 293.87C300.498 299.398 298.958 304.514 297.888 306.948C295.364 312.971 275.599 319.077 275.599 319.077L243.984 312.022L203.086 286.939C203.086 286.939 200.434 276.089 205.91 262.887C212.669 267.755 238.423 286.237 247.535 290.28C258.059 294.942 276.113 302.987 276.113 302.987L302.038 293.87Z"
                    fill="url(#paint14_linear_461_44785)"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M302.038 293.87C300.498 299.398 298.958 304.514 297.888 306.948C295.364 312.971 275.599 319.077 275.599 319.077L244.198 312.105L249.546 291.229C260.198 295.974 276.07 302.987 276.07 302.987L302.038 293.87Z"
                    fill="url(#paint15_linear_461_44785)"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M272.904 317.798C271.193 313.425 274.958 303.895 276.54 301.956C278.123 300.017 298.23 290.858 302.166 293.004C302.893 293.416 305.631 295.561 306.958 296.964C312.776 303.111 322.572 316.726 321.417 320.232C319.963 324.523 308.369 334.383 304.134 332.774C300.327 331.33 274.444 321.8 272.904 317.798Z"
                    fill="url(#paint16_linear_461_44785)"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M307.043 297.005C308.369 298.408 309.824 300.141 311.364 302.08C306.872 302.121 302.936 302.864 299.342 304.184C295.193 305.752 291.514 308.186 288.048 311.362C284.797 314.291 282.829 318.706 281.246 323.285C279.407 322.378 277.695 321.511 276.369 320.686C278.123 315.777 280.391 310.991 284.284 307.484C288.262 303.854 292.54 301.09 297.375 299.233C300.369 298.161 303.578 297.377 307.043 297.005ZM316.455 309.011C317.695 310.826 318.808 312.6 319.621 314.25C317.952 314.126 310.252 313.961 303.834 320.108C300.883 322.955 298.744 326.668 297.289 330.175C295.749 329.556 294.038 328.896 292.241 328.153C293.952 324.11 296.433 319.737 299.941 316.354C305.674 310.867 312.262 309.341 316.455 309.011Z"
                    fill="url(#paint17_linear_461_44785)"
                />
                <path
                    d="M156.54 310.99C151.963 303.069 146.872 277.615 151.492 223.817C109.952 226.045 92.5404 205.087 92.5404 205.087C83.9842 251.83 99.7275 304.513 108.155 325.719C117.567 337.023 154.572 355.794 154.572 355.794C154.572 355.794 183.407 348.12 205.738 334.877C207.792 331.371 209.631 328.029 210.401 323.408C194.144 322.006 159.834 316.642 156.54 310.99Z"
                    fill="url(#paint18_radial_461_44785)"
                />
                <path
                    d="M149.31 223.941C144.433 280.132 146.572 306.906 151.236 314.662C154.53 320.191 191.834 324.523 209.759 326.132C210.016 325.265 210.23 324.358 210.401 323.367C194.102 321.965 159.834 316.601 156.54 310.908C151.963 302.987 146.872 277.532 151.492 223.735C150.765 223.859 150.038 223.9 149.31 223.941Z"
                    fill="url(#paint19_radial_461_44785)"
                />
                <path
                    d="M163 356.165C160.348 355.299 152.433 342.386 152.433 342.386L159.022 335.455C164.883 328.606 186.444 327.658 188.54 334.341C189.866 338.755 167.449 357.65 163 356.165Z"
                    fill="#C6A98D"
                />
                <path
                    d="M173.481 337.766C178.615 335.703 183.749 334.589 188.455 334.259C186.145 327.699 164.84 328.648 159.022 335.455L152.433 342.386C152.433 342.386 154.444 345.646 156.754 348.987C160.647 344.614 166.465 340.571 173.481 337.766Z"
                    fill="#AF8461"
                />
                <path
                    d="M175.92 356.537C176.947 346.182 171.086 339.127 163 338.385C154.957 337.642 147.77 343.5 146.744 353.814C145.717 364.169 151.578 371.224 159.663 371.967C167.706 372.709 174.85 366.851 175.92 356.537Z"
                    fill="#123161"
                />
                <path
                    d="M174.765 361.694C171.128 361.859 167.963 357.692 167.706 352.411C167.492 347.997 169.417 344.202 172.155 343.005C169.845 340.365 166.637 338.715 163 338.385C154.957 337.642 147.77 343.5 146.744 353.814C145.717 364.169 151.578 371.224 159.663 371.967C166.251 372.585 172.241 368.79 174.765 361.694Z"
                    fill="url(#paint20_linear_461_44785)"
                />
                <path
                    d="M166.722 354.597C167.364 348.203 163.984 343.871 159.278 343.458C154.615 343.005 150.423 346.676 149.781 353.03C149.139 359.424 152.562 363.756 157.225 364.169C161.888 364.623 166.08 360.992 166.722 354.597Z"
                    fill="url(#paint21_radial_461_44785)"
                />
                <path
                    d="M159.621 355.217C159.877 352.576 158.465 350.761 156.54 350.596C154.615 350.431 152.861 351.916 152.605 354.556C152.348 357.197 153.76 359.012 155.685 359.177C157.61 359.342 159.364 357.857 159.621 355.217Z"
                    fill="#123161"
                />
                <path
                    d="M156.155 355.794C162.915 356.578 177.374 325.1 177.374 325.1C177.374 325.1 116.369 308.639 108.369 326.049C113.546 333.268 144.177 354.391 156.155 355.794Z"
                    fill="url(#paint22_radial_461_44785)"
                />
                <path
                    d="M145.802 311.444C141.225 303.523 136.134 278.068 140.754 224.271C109.268 222.786 92.5404 205.087 92.5404 205.087C83.9842 251.83 99.7275 304.513 108.155 325.719C117.567 337.023 154.572 355.794 154.572 355.794C154.572 355.794 183.407 348.12 205.738 334.877C207.792 331.371 209.631 328.029 210.401 323.408C194.144 322.006 149.096 317.096 145.802 311.444Z"
                    fill="url(#paint23_linear_461_44785)"
                />
                <path
                    d="M93.7384 240.774C92.8827 220.806 94.6368 216.928 97.717 202.117C94.2517 203.891 92.5405 205.088 92.5405 205.088C83.9844 251.83 108.327 325.925 108.412 326.008C113.246 332.732 140.284 351.628 153.589 355.258C154.23 355.588 154.615 355.794 154.615 355.794C154.615 355.794 154.829 355.753 155.171 355.629C155.514 355.712 155.856 355.753 156.198 355.794C157.139 355.918 158.209 355.382 159.364 354.433C127.45 324.028 98.487 352.453 93.7384 240.774Z"
                    fill="url(#paint24_radial_461_44785)"
                />
                <path
                    d="M129.461 246.673C129.333 234.874 123.771 226.995 117.482 227.077C111.194 227.16 106.531 235.163 106.702 247.004C106.83 258.803 112.049 268.333 118.338 268.25C124.627 268.126 129.632 258.473 129.461 246.673Z"
                    fill="url(#paint25_linear_461_44785)"
                />
                <path
                    d="M119.108 243.332C122.83 243.166 126.381 244.28 129.461 246.302C129.204 234.709 123.728 226.994 117.482 227.036C111.194 227.118 106.531 235.122 106.702 246.962C106.702 247.787 106.744 248.571 106.787 249.355C110.124 245.807 114.359 243.538 119.108 243.332Z"
                    fill="url(#paint26_linear_461_44785)"
                />
                <path
                    d="M126.893 247.499C126.807 238.835 122.786 233.018 118.294 233.059C113.802 233.1 110.465 239 110.551 247.705C110.679 256.369 114.401 263.382 118.893 263.299C123.428 263.258 126.979 256.162 126.893 247.499Z"
                    fill="url(#paint27_linear_461_44785)"
                />
                <path
                    d="M109.695 294.983C102.936 276.501 108.07 255.131 123.214 245.105C125.866 244.817 127.449 248.076 125.31 249.974C116.583 255.254 112.134 277.656 117.096 291.188C124.112 310.331 151.449 318.293 220.711 293.292L224.348 305.421C183.963 319.984 125.524 338.178 109.695 294.983Z"
                    fill="#123161"
                />
                <path
                    d="M119.107 248.365C112.861 256.657 103.62 273.943 107.428 284.917C108.155 287.021 115.514 288.672 116.84 290.528C112.219 276.955 116.711 255.172 125.268 249.933C127.449 248.035 125.866 244.776 123.171 245.065C121.759 246.096 120.391 247.21 119.107 248.365Z"
                    fill="url(#paint28_linear_461_44785)"
                />
                <path
                    d="M221.909 297.335C184.476 310.248 130.315 327.452 110.636 297.253C127.663 337.601 184.69 319.696 224.305 305.38L221.909 297.335Z"
                    fill="url(#paint29_linear_461_44785)"
                />
                <path
                    d="M220.925 307.113L228.241 307.526L223.492 290.652L217.888 294.117C216.647 298.656 217.246 302.74 220.925 307.113Z"
                    fill="url(#paint30_linear_461_44785)"
                />
                <path
                    d="M225.674 307.525H227C227.642 307.525 228.069 306.865 227.898 306.205L223.791 291.601C223.663 291.064 223.15 290.776 222.679 290.941L221.567 291.271C221.224 291.353 221.011 291.683 220.925 292.013C220.027 296.428 221.695 302.946 225.032 307.154C225.16 307.402 225.417 307.525 225.674 307.525Z"
                    fill="url(#paint31_radial_461_44785)"
                />
                <path
                    d="M239.15 281.74C238.337 278.605 233.545 280.214 229.353 282.194C225.674 283.927 221.652 288.878 221.524 293.498C221.524 294.117 221.995 294.612 222.551 294.53L222.765 294.488C223.107 294.447 223.406 294.158 223.535 293.828C224.262 291.559 230.209 287.929 232.134 287.434C235.471 286.526 239.963 284.835 239.15 281.74Z"
                    fill="url(#paint32_radial_461_44785)"
                />
                <path
                    d="M245.353 303.895C246.85 306.577 242.444 308.639 238.166 310.042C234.486 311.28 227.385 308.227 224.176 305.215C223.706 304.761 223.749 303.978 224.262 303.606L224.305 303.565C224.561 303.359 224.904 303.359 225.203 303.524C227.214 304.638 234.872 305.545 236.626 304.638C239.577 303.029 243.855 301.213 245.353 303.895Z"
                    fill="url(#paint33_radial_461_44785)"
                />
                <path
                    d="M244.882 293.045C246.508 295.644 242.187 297.913 237.994 299.563C234.444 300.966 227 301.585 223.62 299.439C223.021 299.068 223.021 298.16 223.535 297.748C223.749 297.583 224.048 297.5 224.305 297.583C226.358 298.16 234.486 295.231 236.198 294.159C239.064 292.508 243.256 290.446 244.882 293.045Z"
                    fill="url(#paint34_radial_461_44785)"
                />
                <path
                    d="M164.925 350.555C168.177 346.924 190.123 331.412 190.123 331.412L205.781 333.433C205.824 333.433 170.358 351.916 164.925 350.555Z"
                    fill="url(#paint35_radial_461_44785)"
                />
                <path
                    d="M174.508 212.555C188.498 211.358 199.492 207.068 199.107 202.983C198.722 198.858 187.086 196.506 173.139 197.703C159.193 198.899 148.155 203.19 148.54 207.274C148.883 211.358 160.519 213.751 174.508 212.555Z"
                    fill="url(#paint36_linear_461_44785)"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M238.422 183.14L230.294 77.1956L215.877 71.0898L101.653 75.7104C98.7435 75.7929 95.0216 75.6692 94.2088 78.392C90.5296 90.8512 81.1607 126.414 85.1393 153.065C90.0591 185.945 103.792 214.618 103.792 214.618L114.872 216.392C117.481 216.805 120.134 216.887 122.786 216.557C150.166 213.133 199.193 205.665 238.422 183.14Z"
                    fill="url(#paint37_radial_461_44785)"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M238.422 183.139C247.877 146.752 250.914 109.828 230.294 77.1949L215.877 71.0891C215.877 71.0891 153.332 211.111 161.631 209.502C187.128 204.551 214.38 196.919 238.422 183.139Z"
                    fill="url(#paint38_radial_461_44785)"
                />
                <path
                    opacity="0.4"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M238.422 183.139C247.877 146.752 250.914 109.828 230.294 77.1949L215.877 71.0891C215.877 71.0891 153.332 211.111 161.631 209.502C187.128 204.551 214.38 196.919 238.422 183.139Z"
                    fill="url(#paint39_radial_461_44785)"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M129.46 93.9446C105.161 123.319 105.674 167.668 132.284 191.308C134.637 193.371 137.845 194.237 140.925 193.494L222.166 174.929C225.118 174.269 227.599 172.248 228.754 169.525C239.963 142.874 237.909 116.388 222.551 89.9428C220.754 86.8899 217.332 85.0334 213.695 85.2396C201.118 85.941 152.134 89.1589 136.989 90.1491C134.08 90.3553 131.3 91.7168 129.46 93.9446Z"
                    fill="url(#paint40_linear_461_44785)"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M167.706 186.522L222.893 174.764C225.417 174.228 227.471 172.536 228.412 170.185C240.647 140.316 237.225 113.458 222.037 88.87C220.54 86.4359 217.759 85.0745 214.85 85.2808L209.845 85.6521L167.706 186.522Z"
                    fill="url(#paint41_linear_461_44785)"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M148.797 105.579L157.054 105.208C158.765 105.125 160.262 106.487 160.305 108.219L161.674 138.336C161.759 139.945 160.562 141.389 158.979 141.554L150.722 142.585C148.925 142.833 147.3 141.43 147.214 139.615L145.802 108.838C145.759 107.147 147.086 105.661 148.797 105.579ZM195.898 102.774L201.973 102.485C203.684 102.402 205.182 103.764 205.267 105.496L206.679 134.54C206.765 136.273 205.438 137.717 203.727 137.8L197.652 138.088C195.941 138.171 194.444 136.809 194.358 135.077L192.947 106.033C192.861 104.341 194.187 102.856 195.898 102.774Z"
                    fill="url(#paint42_linear_461_44785)"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M150.679 103.516L158.936 103.145C160.647 103.062 162.144 104.424 162.187 106.156L163.556 136.273C163.642 137.882 162.444 139.326 160.861 139.491L152.604 140.522C150.808 140.77 149.182 139.367 149.096 137.552L147.685 106.775C147.642 105.084 148.968 103.598 150.679 103.516ZM197.781 100.711L203.856 100.422C205.567 100.339 207.064 101.701 207.15 103.433L208.562 132.477C208.647 134.21 207.321 135.654 205.61 135.737L199.535 136.025C197.824 136.108 196.326 134.746 196.241 133.014L194.829 103.97C194.743 102.278 196.07 100.793 197.781 100.711Z"
                    fill="url(#paint43_linear_461_44785)"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M151.449 106.197L156.583 105.95C157.653 105.909 158.551 106.734 158.594 107.806L159.278 122.452C159.321 123.442 158.594 124.35 157.61 124.474L152.519 125.134C151.407 125.258 150.38 124.432 150.337 123.277L149.653 108.219C149.567 107.146 150.38 106.239 151.449 106.197ZM198.508 103.681L202.273 103.516C203.342 103.475 204.241 104.3 204.283 105.372L204.968 120.596C205.011 121.668 204.198 122.576 203.128 122.617L199.364 122.782C198.294 122.823 197.396 121.998 197.353 120.926L196.669 105.702C196.626 104.671 197.439 103.763 198.508 103.681Z"
                    fill="url(#paint44_radial_461_44785)"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M229.995 77.071L216.904 71.5015C216.219 71.2127 215.449 71.0477 214.679 71.0889L96.134 74.7194C96.134 74.7194 95.0645 75.0907 94.2944 76.9885C89.9736 87.6737 71.749 116.223 75.8987 143.946C80.0057 171.423 96.4334 204.262 100.113 211.894C100.797 213.338 102.209 214.37 103.835 214.7L109.482 215.772C111.535 216.185 113.246 214.246 112.519 212.348C108.54 201.787 94.6794 178.601 93.011 143.946C91.9843 122.823 104.134 97.7813 108.925 87.5086C110.936 83.2181 115.043 80.1239 119.877 79.3813C136.476 76.6997 179.086 71.5427 229.995 77.071Z"
                    fill="url(#paint45_linear_461_44785)"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M229.995 77.0712L215.834 71.0479C169.973 66.6748 138.487 66.2622 96.1339 74.6784L113.76 81.7331C115.556 80.0828 122.016 79.0102 131.214 77.855C156.198 74.7196 201.546 73.3994 229.995 77.0712Z"
                    fill="url(#paint46_linear_461_44785)"
                />
                <path
                    d="M179.219 170.321C187.09 168.918 195.176 162.235 196.887 152.003C197.101 150.559 196.032 149.487 194.663 149.734L190.128 150.559C189.016 150.766 187.903 151.797 187.689 152.952C186.663 157.903 182.855 160.254 179.689 160.832C176.524 161.41 172.887 160.378 172.245 155.716C172.16 154.602 171.09 153.984 169.978 154.149L165.443 154.974C164.074 155.221 162.919 156.706 162.962 158.068C163.732 167.969 171.518 171.724 179.219 170.321Z"
                    fill="url(#paint47_linear_461_44785)"
                />
                <path
                    d="M180.642 170.762C188.514 169.36 196.6 162.676 198.311 152.445C198.525 151.001 197.455 149.928 196.086 150.176L191.551 151.001C190.439 151.207 189.327 152.239 189.113 153.394C188.086 158.344 184.279 160.696 181.113 161.273C177.947 161.851 174.311 160.82 173.669 156.158C173.584 155.044 172.514 154.425 171.402 154.59L166.867 155.415C165.498 155.663 164.343 157.148 164.386 158.509C165.113 168.411 172.899 172.165 180.642 170.762Z"
                    fill="url(#paint48_linear_461_44785)"
                />
                <g clipPath="url(#clip1_461_44785)">
                    <path
                        opacity="0.1"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M164.817 73.1225C165.05 72.1937 166.051 70.5971 166.98 70.8297L181.256 74.9062C182.186 75.1388 178.942 74.3268 181.256 74.9062C176.374 74.8677 180.482 74.8677 172.079 74.9062L166.078 75.2257C165.149 74.993 164.584 74.0514 164.817 73.1225Z"
                        fill="#041836"
                    />
                    <path
                        d="M167.291 71.9996C167.291 71.9996 166.06 43.279 186.675 24.4473"
                        stroke="#A4E4F3"
                        strokeWidth="4.4348"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </g>
                <path
                    d="M187.727 13.4548C192.005 13.4548 195.684 16.9616 195.684 21.1284C195.684 25.2539 192.048 28.8019 187.727 28.8019C183.449 28.8019 179.77 25.2952 179.77 21.1284C179.77 17.0028 183.278 13.4548 187.727 13.4548Z"
                    fill="url(#paint49_linear_461_44785)"
                />
                <path
                    d="M187.086 14.9814C190.251 14.9814 192.947 17.5805 192.947 20.6334C192.947 23.6863 190.251 26.2854 187.086 26.2854C183.92 26.2854 181.225 23.6863 181.225 20.6334C181.225 17.5805 183.92 14.9814 187.086 14.9814Z"
                    fill="url(#paint50_radial_461_44785)"
                />
                <path
                    d="M308.155 305.545C315.471 303.523 319.92 307.649 318.08 314.703C316.241 321.758 308.882 329.143 301.567 331.123C294.251 333.145 289.802 329.019 291.642 321.964C293.439 314.91 300.84 307.566 308.155 305.545Z"
                    fill="#E9F5FB"
                />
                <path
                    d="M321.16 313.796C321.16 313.837 321.16 313.837 321.16 313.796L316.412 309.34C310.979 304.266 297.118 312.682 294.551 322.707C293.096 328.359 295.791 329.597 300.626 334.176C300.583 334.135 300.54 334.052 300.454 333.97C305.717 339.457 319.877 330.958 322.487 320.809C323.299 317.715 322.7 315.281 321.16 313.796Z"
                    fill="#FFFBF3"
                />
                <path
                    d="M321.16 313.837C317.995 310.867 312.262 312.724 308.412 315.281C300.54 320.521 296.476 330.051 300.54 334.053C300.198 333.723 300.283 333.805 299.428 333.021H299.385C298.957 332.691 296.091 329.927 295.62 329.515H295.577C285.909 319.985 307.727 301.296 315.257 308.351C315.299 308.392 316.027 309.093 316.454 309.506H316.497C318.166 311.073 320.604 313.301 321.16 313.837Z"
                    fill="url(#paint51_linear_461_44785)"
                />
                <path
                    d="M312.946 315.364C317.524 314.044 320.347 316.725 319.192 321.346C318.037 325.967 313.374 330.752 308.797 332.031C304.219 333.351 301.396 330.67 302.551 326.049C303.706 321.47 308.369 316.684 312.946 315.364Z"
                    fill="#AAD9F0"
                />
                <path
                    d="M317.952 317.261C317.267 316.106 316.069 315.611 314.658 315.653C310.636 315.735 304.861 320.191 303.62 325.636C303.449 326.338 303.406 326.998 303.406 327.534C303.535 334.836 316.241 330.092 318.337 321.14C318.679 319.943 318.679 318.334 317.952 317.261Z"
                    fill="#005995"
                />
                <path
                    d="M318.381 321.139C316.284 330.092 303.536 334.836 303.45 327.534C306.402 330.422 314.231 325.554 315.557 319.613C315.942 317.839 315.643 316.436 314.701 315.652C316.113 315.611 317.311 316.106 317.996 317.261C318.68 318.334 318.68 319.943 318.381 321.139Z"
                    fill="url(#paint52_linear_461_44785)"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M285.353 284.958L266.444 275.304L258.144 280.832L244.241 259.091L262.551 269.033L272.262 264.495C272.262 264.536 285.267 284.752 285.353 284.958Z"
                    fill="url(#paint53_linear_461_44785)"
                />
                <path
                    d="M266.273 250.097C266.059 253.191 269.866 252.16 273.332 250.716C276.626 249.355 275.898 245.064 272.647 245.394C270.08 245.889 266.487 247.003 266.273 250.097Z"
                    fill="url(#paint54_radial_461_44785)"
                />
                <path
                    d="M278.08 256.121C277.866 259.215 280.775 258.843 284.241 257.399C287.535 256.038 286.808 251.747 283.556 252.077C280.989 252.573 278.294 253.026 278.08 256.121Z"
                    fill="url(#paint55_radial_461_44785)"
                />
                <path
                    d="M285.439 261.071C285.738 262.391 287.535 263.216 290.273 262.103C292.84 261.03 292.283 257.647 289.759 257.894C287.706 258.266 285.139 259.71 285.439 261.071Z"
                    fill="url(#paint56_radial_461_44785)"
                />
            </g>
            <defs>
                <radialGradient
                    id="paint0_radial_461_44785"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(150.471 371.308) rotate(-4.33852) scale(46.36 44.7265)"
                >
                    <stop offset="0.00504299" stopColor="#A4E4F3" />
                    <stop offset="1" stopColor="#A4E4F3" />
                </radialGradient>
                <linearGradient
                    id="paint1_linear_461_44785"
                    x1="162.774"
                    y1="250.38"
                    x2="279.961"
                    y2="245.008"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.1407" stopColor="#123161" />
                    <stop offset="1" stopColor="#041836" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_461_44785"
                    x1="169.802"
                    y1="257.97"
                    x2="169.881"
                    y2="257.966"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.1407" stopColor="#123161" />
                    <stop offset="1" stopColor="#041836" />
                </linearGradient>
                <radialGradient
                    id="paint3_radial_461_44785"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(275.815 258.765) rotate(-2.44098) scale(8.55423 8.2504)"
                >
                    <stop offset="0.387" stopColor="#D8E8F8" />
                    <stop offset="1" stopColor="#A4E4F3" />
                </radialGradient>
                <radialGradient
                    id="paint4_radial_461_44785"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(278.379 262.416) rotate(-2.44098) scale(7.94111 7.65906)"
                >
                    <stop offset="0.387" stopColor="#D8E8F8" />
                    <stop offset="1" stopColor="#A4E4F3" />
                </radialGradient>
                <radialGradient
                    id="paint5_radial_461_44785"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(281.215 262.608) rotate(-2.44098) scale(8.41615 8.11723)"
                >
                    <stop offset="0.387" stopColor="#D8E8F8" />
                    <stop offset="1" stopColor="#A4E4F3" />
                </radialGradient>
                <radialGradient
                    id="paint6_radial_461_44785"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(267.232 253.72) rotate(-2.44098) scale(8.24102 7.94833)"
                >
                    <stop offset="0.387" stopColor="#D8E8F8" />
                    <stop offset="1" stopColor="#A4E4F3" />
                </radialGradient>
                <radialGradient
                    id="paint7_radial_461_44785"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(164.044 271.736) rotate(-2.44098) scale(79.8375 77.0019)"
                >
                    <stop offset="0.387" stopColor="#D8E8F8" />
                    <stop offset="1" stopColor="#A4E4F3" />
                </radialGradient>
                <radialGradient
                    id="paint8_radial_461_44785"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(154.549 241.85) scale(42.283 40.7757)"
                >
                    <stop offset="0.387" stopColor="#D8E8F8" />
                    <stop offset="1" stopColor="#94BBD6" />
                </radialGradient>
                <radialGradient
                    id="paint9_radial_461_44785"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(231.989 231.611) scale(120.591 116.292)"
                >
                    <stop offset="0.323" stopColor="#F8F5FC" />
                    <stop offset="1" stopColor="#9AD6EA" />
                </radialGradient>
                <linearGradient
                    id="paint10_linear_461_44785"
                    x1="191.604"
                    y1="266.571"
                    x2="225.151"
                    y2="265.033"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#7EB0D7" />
                    <stop offset="1" stopColor="#B7D4E1" />
                </linearGradient>
                <radialGradient
                    id="paint11_radial_461_44785"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(212.679 265.635) rotate(-2.44098) scale(17.1942 16.5835)"
                >
                    <stop offset="0.387" stopColor="#F1FAFF" />
                    <stop offset="1" stopColor="#BFFDFF" />
                </radialGradient>
                <linearGradient
                    id="paint12_linear_461_44785"
                    x1="225.437"
                    y1="252.95"
                    x2="258.992"
                    y2="251.412"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#7EB0D7" />
                    <stop offset="1" stopColor="#B7D4E1" />
                </linearGradient>
                <radialGradient
                    id="paint13_radial_461_44785"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(246.528 251.992) rotate(-2.44098) scale(17.2007 16.5898)"
                >
                    <stop offset="0.387" stopColor="#F1FAFF" />
                    <stop offset="1" stopColor="#BFFDFF" />
                </radialGradient>
                <linearGradient
                    id="paint14_linear_461_44785"
                    x1="252.616"
                    y1="283.777"
                    x2="241.87"
                    y2="304.097"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.1407" stopColor="#123161" />
                    <stop offset="1" stopColor="#041836" />
                </linearGradient>
                <linearGradient
                    id="paint15_linear_461_44785"
                    x1="285.642"
                    y1="289.291"
                    x2="261.823"
                    y2="311.334"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.1407" stopColor="#123161" />
                    <stop offset="1" stopColor="#041836" />
                </linearGradient>
                <linearGradient
                    id="paint16_linear_461_44785"
                    x1="302.748"
                    y1="305.212"
                    x2="295.46"
                    y2="317.699"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.1407" stopColor="#123161" />
                    <stop offset="1" stopColor="#041836" />
                </linearGradient>
                <linearGradient
                    id="paint17_linear_461_44785"
                    x1="303.964"
                    y1="308.553"
                    x2="287.707"
                    y2="327.946"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.1407" stopColor="#123161" />
                    <stop offset="1" stopColor="#041836" />
                </linearGradient>
                <radialGradient
                    id="paint18_radial_461_44785"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(150.163 279.311) rotate(-2.44098) scale(69.6104 67.138)"
                >
                    <stop offset="0.387" stopColor="#D8E8F8" />
                    <stop offset="1" stopColor="#A4E4F3" />
                </radialGradient>
                <radialGradient
                    id="paint19_radial_461_44785"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(177.168 275.142) rotate(-2.44098) scale(44.2158 42.6454)"
                >
                    <stop offset="0.323" stopColor="#F8F5FC" />
                    <stop offset="1" stopColor="#9AD6EA" />
                </radialGradient>
                <linearGradient
                    id="paint20_linear_461_44785"
                    x1="146.572"
                    y1="355.79"
                    x2="174.431"
                    y2="354.513"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.1407" stopColor="#123161" />
                    <stop offset="1" stopColor="#041836" />
                </linearGradient>
                <radialGradient
                    id="paint21_radial_461_44785"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(158.242 353.816) rotate(-2.44098) scale(9.74068 9.39472)"
                >
                    <stop stopColor="#F8F5FC" />
                    <stop offset="1" stopColor="#9AD6EA" />
                </radialGradient>
                <radialGradient
                    id="paint22_radial_461_44785"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(143.395 336.907) rotate(-2.44098) scale(28.2424 27.2393)"
                >
                    <stop offset="0.387" stopColor="#D8E8F8" />
                    <stop offset="1" stopColor="#A4E4F3" />
                </radialGradient>
                <linearGradient
                    id="paint23_linear_461_44785"
                    x1="156.896"
                    y1="257.303"
                    x2="94.7381"
                    y2="366.462"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#7EB0D7" />
                    <stop offset="1" stopColor="#B7D4E1" />
                </linearGradient>
                <radialGradient
                    id="paint24_radial_461_44785"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(124.36 279.08) rotate(-2.44098) scale(61.3809 59.2008)"
                >
                    <stop offset="0.323" stopColor="#F8F5FC" />
                    <stop offset="1" stopColor="#9AD6EA" />
                </radialGradient>
                <linearGradient
                    id="paint25_linear_461_44785"
                    x1="106.717"
                    y1="248.099"
                    x2="129.515"
                    y2="247.054"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F8F5FC" />
                    <stop offset="1" stopColor="#9AD6EA" />
                </linearGradient>
                <linearGradient
                    id="paint26_linear_461_44785"
                    x1="106.277"
                    y1="238.445"
                    x2="129.063"
                    y2="237.401"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F8F5FC" />
                    <stop offset="1" stopColor="#9AD6EA" />
                </linearGradient>
                <linearGradient
                    id="paint27_linear_461_44785"
                    x1="118.087"
                    y1="248.82"
                    x2="124.018"
                    y2="238.79"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.1407" stopColor="#123161" />
                    <stop offset="1" stopColor="#041836" />
                </linearGradient>
                <linearGradient
                    id="paint28_linear_461_44785"
                    x1="106.044"
                    y1="268.421"
                    x2="127.233"
                    y2="267.45"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.1407" stopColor="#123161" />
                    <stop offset="1" stopColor="#041836" />
                </linearGradient>
                <linearGradient
                    id="paint29_linear_461_44785"
                    x1="111.263"
                    y1="310.548"
                    x2="224.332"
                    y2="305.365"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.1407" stopColor="#123161" />
                    <stop offset="1" stopColor="#041836" />
                </linearGradient>
                <linearGradient
                    id="paint30_linear_461_44785"
                    x1="225.592"
                    y1="299.167"
                    x2="218.771"
                    y2="300.682"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#7EB0D7" />
                    <stop offset="1" stopColor="#B7D4E1" />
                </linearGradient>
                <radialGradient
                    id="paint31_radial_461_44785"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(224.274 299.226) scale(5.94971 6.52369)"
                >
                    <stop offset="0.387" stopColor="#D8E8F8" />
                    <stop offset="1" stopColor="#A4E4F3" />
                </radialGradient>
                <radialGradient
                    id="paint32_radial_461_44785"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(230.352 287.197) rotate(-2.77498) scale(7.83028 8.5825)"
                >
                    <stop offset="0.387" stopColor="#D8E8F8" />
                    <stop offset="1" stopColor="#A4E4F3" />
                </radialGradient>
                <radialGradient
                    id="paint33_radial_461_44785"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(234.856 306.636) rotate(-2.775) scale(8.06377 8.8385)"
                >
                    <stop offset="0.387" stopColor="#D8E8F8" />
                    <stop offset="1" stopColor="#A4E4F3" />
                </radialGradient>
                <radialGradient
                    id="paint34_radial_461_44785"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(234.202 296.444) rotate(-2.77499) scale(8.32504 9.12481)"
                >
                    <stop offset="0.387" stopColor="#D8E8F8" />
                    <stop offset="1" stopColor="#A4E4F3" />
                </radialGradient>
                <radialGradient
                    id="paint35_radial_461_44785"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(185.31 340.709) rotate(-2.44098) scale(16.1551 15.5813)"
                >
                    <stop offset="0.387" stopColor="#D8E8F8" />
                    <stop offset="1" stopColor="#A4E4F3" />
                </radialGradient>
                <linearGradient
                    id="paint36_linear_461_44785"
                    x1="148.506"
                    y1="207.022"
                    x2="199.104"
                    y2="202.894"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.1407" stopColor="#123161" />
                    <stop offset="1" stopColor="#041836" />
                </linearGradient>
                <radialGradient
                    id="paint37_radial_461_44785"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(176.579 108.88) rotate(-2.44098) scale(101.314 101.791)"
                >
                    <stop offset="0.387" stopColor="#D8E8F8" />
                    <stop offset="1" stopColor="#A4E4F3" />
                </radialGradient>
                <radialGradient
                    id="paint38_radial_461_44785"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(201.907 139.704) rotate(-2.44098) scale(57.1513 57.4206)"
                >
                    <stop offset="0.387" stopColor="#D8E8F8" />
                    <stop offset="1" stopColor="#A4E4F3" />
                </radialGradient>
                <radialGradient
                    id="paint39_radial_461_44785"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(248.263 136.503) scale(88.8518 85.6843)"
                >
                    <stop offset="0.323" stopColor="#F8F5FC" />
                    <stop offset="1" stopColor="#9AD6EA" />
                </radialGradient>
                <linearGradient
                    id="paint40_linear_461_44785"
                    x1="111.552"
                    y1="140.096"
                    x2="235.896"
                    y2="134.396"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.1407" stopColor="#123161" />
                    <stop offset="1" stopColor="#041836" />
                </linearGradient>
                <linearGradient
                    id="paint41_linear_461_44785"
                    x1="165.41"
                    y1="136.127"
                    x2="235.847"
                    y2="132.898"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.1407" stopColor="#123161" />
                    <stop offset="1" stopColor="#041836" />
                </linearGradient>
                <linearGradient
                    id="paint42_linear_461_44785"
                    x1="172.343"
                    y1="106.876"
                    x2="174.02"
                    y2="140.221"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop
                        offset="0.0155217"
                        stopColor="#123161"
                        stopOpacity="0"
                    />
                    <stop offset="1" stopColor="#051938" />
                </linearGradient>
                <linearGradient
                    id="paint43_linear_461_44785"
                    x1="174.629"
                    y1="104.97"
                    x2="174.218"
                    y2="125.272"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#6BFFE8" />
                    <stop offset="0.0474436" stopColor="#68F8E5" />
                    <stop offset="0.5011" stopColor="#49B6C8" />
                    <stop offset="0.8307" stopColor="#358DB6" />
                    <stop offset="1" stopColor="#2E7DAF" />
                </linearGradient>
                <radialGradient
                    id="paint44_radial_461_44785"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(177.307 114.328) rotate(8.54163) scale(22.3924 21.6529)"
                >
                    <stop stopColor="#D7FFFF" />
                    <stop offset="1" stopColor="#2E7DAF" stopOpacity="0" />
                </radialGradient>
                <linearGradient
                    id="paint45_linear_461_44785"
                    x1="135.788"
                    y1="168.675"
                    x2="150.873"
                    y2="104.489"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#7EB0D7" />
                    <stop offset="1" stopColor="#B7D4E1" />
                </linearGradient>
                <linearGradient
                    id="paint46_linear_461_44785"
                    x1="156.131"
                    y1="91.8228"
                    x2="153.89"
                    y2="97.3568"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#7EB0D7" />
                    <stop offset="1" stopColor="#B7D4E1" />
                </linearGradient>
                <linearGradient
                    id="paint47_linear_461_44785"
                    x1="188.437"
                    y1="147.648"
                    x2="170.132"
                    y2="169.58"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop
                        offset="0.0155217"
                        stopColor="#123161"
                        stopOpacity="0"
                    />
                    <stop offset="1" stopColor="#051938" />
                </linearGradient>
                <linearGradient
                    id="paint48_linear_461_44785"
                    x1="189.834"
                    y1="148.091"
                    x2="171.528"
                    y2="170.023"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#6BFFE8" />
                    <stop offset="0.0474436" stopColor="#68F8E5" />
                    <stop offset="0.5011" stopColor="#49B6C8" />
                    <stop offset="0.8307" stopColor="#358DB6" />
                    <stop offset="1" stopColor="#2E7DAF" />
                </linearGradient>
                <linearGradient
                    id="paint49_linear_461_44785"
                    x1="163.71"
                    y1="10.7285"
                    x2="191.94"
                    y2="23.8674"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#6BFFE8" />
                    <stop offset="0.0474436" stopColor="#68F8E5" />
                    <stop offset="0.5011" stopColor="#49B6C8" />
                    <stop offset="0.8307" stopColor="#358DB6" />
                    <stop offset="1" stopColor="#2E7DAF" />
                </linearGradient>
                <radialGradient
                    id="paint50_radial_461_44785"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(185.214 18.8729) scale(5.05929 4.87894)"
                >
                    <stop stopColor="#D7FFFF" />
                    <stop
                        offset="0.1145"
                        stopColor="#CFF9FB"
                        stopOpacity="0.8855"
                    />
                    <stop
                        offset="0.2944"
                        stopColor="#B8E7F0"
                        stopOpacity="0.7056"
                    />
                    <stop
                        offset="0.5171"
                        stopColor="#94CBDF"
                        stopOpacity="0.4829"
                    />
                    <stop
                        offset="0.7713"
                        stopColor="#61A5C7"
                        stopOpacity="0.2287"
                    />
                    <stop offset="1" stopColor="#2E7DAF" stopOpacity="0" />
                </radialGradient>
                <linearGradient
                    id="paint51_linear_461_44785"
                    x1="111.303"
                    y1="273.013"
                    x2="348.037"
                    y2="334.669"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#128EF9" />
                    <stop offset="1" stopColor="#A9D4F3" />
                </linearGradient>
                <linearGradient
                    id="paint52_linear_461_44785"
                    x1="111.578"
                    y1="274.913"
                    x2="347.382"
                    y2="336.327"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#128EF9" />
                    <stop offset="1" stopColor="#A9D4F3" />
                </linearGradient>
                <linearGradient
                    id="paint53_linear_461_44785"
                    x1="281.533"
                    y1="283.473"
                    x2="270.351"
                    y2="243.724"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FCDE00" />
                    <stop offset="1" stopColor="#FDE1A9" />
                </linearGradient>
                <radialGradient
                    id="paint54_radial_461_44785"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(270.9 248.812) rotate(-168.237) scale(3.61946 5.09966)"
                >
                    <stop offset="0.387" stopColor="#D8E8F8" />
                    <stop offset="1" stopColor="#A4E4F3" />
                </radialGradient>
                <radialGradient
                    id="paint55_radial_461_44785"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(282.239 255.323) rotate(-168.238) scale(3.32991 4.6917)"
                >
                    <stop offset="0.387" stopColor="#D8E8F8" />
                    <stop offset="1" stopColor="#A4E4F3" />
                </radialGradient>
                <radialGradient
                    id="paint56_radial_461_44785"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(288.71 260.214) rotate(-168.237) scale(2.57527 3.62846)"
                >
                    <stop offset="0.387" stopColor="#D8E8F8" />
                    <stop offset="1" stopColor="#A4E4F3" />
                </radialGradient>
                <clipPath id="clip0_461_44785">
                    <rect
                        width="247.7"
                        height="377.076"
                        fill="white"
                        transform="translate(75.2997 13.2488)"
                    />
                </clipPath>
                <clipPath id="clip1_461_44785">
                    <rect
                        width="27.5109"
                        height="54.3107"
                        fill="white"
                        transform="translate(164.766 26.3135)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

export default looking;
