import React from 'react';
import type { Size } from '../../types';

const token = (width: Size = '284', height: Size = '398') => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 284 398"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M278.986 226.283C282.288 199.191 277.483 171.729 265.178 147.368C252.874 123.008 233.622 102.843 209.857 89.4226C186.093 76.0026 158.882 69.9305 131.667 71.974C104.452 74.0175 78.4533 84.0849 56.9592 100.903C35.4652 117.722 19.4406 140.536 10.9117 166.461C2.38273 192.386 1.73247 220.258 9.04309 246.552C16.3537 272.847 31.2969 296.383 51.9833 314.186C72.6696 331.988 98.1702 343.257 125.261 346.568C143.253 348.766 161.502 347.399 178.966 342.543C196.429 337.688 212.765 329.44 227.041 318.27C241.316 307.1 253.251 293.227 262.164 277.444C271.077 261.66 276.793 244.276 278.986 226.283Z"
                fill="#36CEA2"
            />
            <path
                d="M251.86 191.45C253.493 184.897 249.504 178.262 242.951 176.629C236.399 174.997 229.763 178.985 228.131 185.538C226.498 192.091 230.487 198.726 237.039 200.359C243.592 201.991 250.228 198.003 251.86 191.45Z"
                fill="url(#paint0_radial_9599_45580)"
            />
            <path
                d="M240.443 188.477H239.159V328.467H240.443V188.477Z"
                fill="url(#paint1_linear_9599_45580)"
            />
            <path
                d="M243.235 186.552C242.721 185.696 241.89 185.077 240.922 184.831C239.954 184.584 238.928 184.73 238.068 185.236C237.554 185.435 237.091 185.744 236.709 186.14C236.327 186.537 236.036 187.012 235.857 187.532C235.677 188.053 235.613 188.606 235.67 189.154C235.726 189.701 235.901 190.23 236.182 190.703C236.464 191.176 236.845 191.582 237.3 191.893C237.754 192.204 238.271 192.412 238.814 192.502C239.357 192.593 239.913 192.564 240.444 192.417C240.974 192.271 241.467 192.011 241.887 191.655C242.731 191.147 243.344 190.33 243.596 189.377C243.848 188.424 243.718 187.411 243.235 186.552Z"
                fill="#A7F9EF"
            />
            <path
                d="M23.0123 205.615C23.0123 205.615 23.0123 205.615 23.0123 205.808L17.4923 209.017V200.352C17.4715 200.324 17.4602 200.29 17.4602 200.256C17.4602 200.221 17.4715 200.187 17.4923 200.159L23.0123 196.95V205.615Z"
                fill="url(#paint2_linear_9599_45580)"
            />
            <path
                d="M23.0121 218.26C23.0264 218.29 23.0338 218.323 23.0338 218.356C23.0338 218.389 23.0264 218.422 23.0121 218.452L17.4921 221.662V212.997C17.4752 212.967 17.4663 212.934 17.4663 212.9C17.4663 212.867 17.4752 212.833 17.4921 212.804L23.0121 209.595V218.26Z"
                fill="url(#paint3_linear_9599_45580)"
            />
            <path
                d="M31.4207 207.412C31.435 207.442 31.4424 207.475 31.4424 207.509C31.4424 207.542 31.435 207.575 31.4207 207.605L25.9007 210.814V199.902C25.8864 199.872 25.879 199.84 25.879 199.806C25.879 199.773 25.8864 199.74 25.9007 199.71L31.4528 196.501L31.4207 207.412Z"
                fill="url(#paint4_linear_9599_45580)"
            />
            <path
                d="M44.932 130.132C44.9461 130.157 44.9535 130.184 44.9535 130.212C44.9535 130.241 44.9461 130.268 44.932 130.293L39.412 133.502V124.837C39.3979 124.812 39.3905 124.785 39.3905 124.757C39.3905 124.728 39.3979 124.701 39.412 124.676L44.932 121.467V130.132Z"
                fill="url(#paint5_linear_9599_45580)"
            />
            <path
                d="M44.9319 142.777C44.9462 142.807 44.9536 142.84 44.9536 142.873C44.9536 142.907 44.9462 142.939 44.9319 142.969L39.4119 146.179V137.514C39.395 137.484 39.3861 137.451 39.3861 137.417C39.3861 137.384 39.395 137.35 39.4119 137.321L44.9319 134.112V142.777Z"
                fill="url(#paint6_linear_9599_45580)"
            />
            <path
                d="M53.34 131.929C53.3543 131.96 53.3617 131.992 53.3617 132.026C53.3617 132.059 53.3543 132.092 53.34 132.122L47.82 135.331V124.259C47.8057 124.229 47.7983 124.196 47.7983 124.163C47.7983 124.13 47.8057 124.097 47.82 124.067L53.34 121.05V131.929Z"
                fill="url(#paint7_linear_9599_45580)"
            />
            <path
                d="M23.0121 231.065L17.3638 234.306V225.545L23.0121 222.271V231.065Z"
                fill="url(#paint8_linear_9599_45580)"
            />
            <path
                d="M189.222 277.118L186.301 278.819V277.728L189.222 276.027V277.118Z"
                fill="url(#paint9_linear_9599_45580)"
            />
            <g opacity="0.11">
                <path
                    opacity="0.11"
                    d="M137.841 302.6C136.584 301.867 135.156 301.481 133.701 301.481C132.246 301.481 130.818 301.867 129.561 302.6L80.202 331.035C80.029 331.137 79.8856 331.282 79.786 331.457C79.6864 331.631 79.634 331.829 79.634 332.03C79.634 332.23 79.6864 332.428 79.786 332.602C79.8856 332.777 80.029 332.922 80.202 333.024L129.978 361.908C131.09 362.554 132.352 362.893 133.637 362.893C134.922 362.893 136.184 362.554 137.295 361.908L187.136 333.024C187.316 332.925 187.467 332.778 187.572 332.6C187.677 332.423 187.732 332.22 187.732 332.014C187.732 331.807 187.677 331.605 187.572 331.427C187.467 331.249 187.316 331.103 187.136 331.003L137.841 302.6Z"
                    fill="url(#paint10_linear_9599_45580)"
                />
            </g>
            <g opacity="0.11">
                <path
                    opacity="0.11"
                    d="M137.841 294.352C136.582 293.626 135.155 293.243 133.701 293.243C132.248 293.243 130.82 293.626 129.561 294.352L80.2021 322.69C80.0295 322.797 79.8871 322.945 79.7883 323.122C79.6894 323.299 79.6376 323.499 79.6376 323.701C79.6376 323.904 79.6894 324.103 79.7883 324.28C79.8871 324.457 80.0295 324.606 80.2021 324.712L129.978 353.596C131.09 354.241 132.352 354.581 133.637 354.581C134.922 354.581 136.184 354.241 137.296 353.596L187.136 324.712C187.317 324.612 187.467 324.466 187.572 324.288C187.677 324.11 187.732 323.908 187.732 323.701C187.732 323.495 187.677 323.292 187.572 323.115C187.467 322.937 187.317 322.79 187.136 322.69L137.841 294.352Z"
                    fill="url(#paint11_linear_9599_45580)"
                />
            </g>
            <g opacity="0.11">
                <path
                    opacity="0.11"
                    d="M137.841 285.944C136.582 285.217 135.155 284.835 133.701 284.835C132.248 284.835 130.82 285.217 129.561 285.944L80.2021 314.378C80.0295 314.485 79.8871 314.633 79.7883 314.81C79.6894 314.987 79.6376 315.187 79.6376 315.389C79.6376 315.592 79.6894 315.791 79.7883 315.968C79.8871 316.145 80.0295 316.294 80.2021 316.4L129.978 345.284C131.095 345.914 132.355 346.245 133.637 346.245C134.919 346.245 136.179 345.914 137.296 345.284L187.136 316.4C187.317 316.3 187.467 316.154 187.572 315.976C187.677 315.798 187.732 315.596 187.732 315.389C187.732 315.183 187.677 314.98 187.572 314.802C187.467 314.625 187.317 314.478 187.136 314.378L137.841 285.944Z"
                    fill="url(#paint12_linear_9599_45580)"
                />
            </g>
            <g opacity="0.11">
                <path
                    opacity="0.11"
                    d="M137.841 277.632C136.582 276.905 135.155 276.522 133.701 276.522C132.248 276.522 130.82 276.905 129.561 277.632L80.2021 306.066C80.0295 306.172 79.8871 306.321 79.7883 306.498C79.6894 306.675 79.6376 306.874 79.6376 307.077C79.6376 307.28 79.6894 307.479 79.7883 307.656C79.8871 307.833 80.0295 307.982 80.2021 308.088L129.978 336.972C131.095 337.602 132.355 337.933 133.637 337.933C134.919 337.933 136.179 337.602 137.296 336.972L187.136 308.248C187.317 308.148 187.467 308.002 187.572 307.824C187.677 307.646 187.732 307.444 187.732 307.237C187.732 307.031 187.677 306.828 187.572 306.651C187.467 306.473 187.317 306.326 187.136 306.227L137.841 277.632Z"
                    fill="url(#paint13_linear_9599_45580)"
                />
            </g>
            <path
                d="M187.136 306.098L137.841 277.632C136.583 276.905 135.155 276.522 133.701 276.522C132.248 276.522 130.82 276.905 129.561 277.632L80.2022 306.066C80.0227 306.17 79.8743 306.32 79.7726 306.5C79.671 306.681 79.6199 306.886 79.6246 307.093V332.029C79.6256 332.231 79.6794 332.429 79.7808 332.604C79.8821 332.778 80.0274 332.923 80.2022 333.024L129.979 361.908C131.09 362.553 132.352 362.893 133.637 362.893C134.922 362.893 136.184 362.553 137.296 361.908L187.136 333.024C187.319 332.93 187.473 332.788 187.58 332.612C187.688 332.437 187.745 332.235 187.746 332.029V307.189C187.764 306.967 187.715 306.745 187.607 306.551C187.498 306.357 187.334 306.199 187.136 306.098Z"
                fill="url(#paint14_linear_9599_45580)"
            />
            <path
                d="M187.136 306.098L137.841 277.632C136.583 276.905 135.155 276.522 133.701 276.522C132.248 276.522 130.82 276.905 129.561 277.632L80.2022 306.066C80.0227 306.17 79.8743 306.32 79.7726 306.5C79.671 306.681 79.6199 306.886 79.6246 307.093L130.139 335.977C131.256 336.622 132.524 336.961 133.814 336.961C135.104 336.961 136.371 336.622 137.488 335.977L187.746 307.093C187.745 306.887 187.688 306.686 187.58 306.51C187.473 306.335 187.319 306.192 187.136 306.098Z"
                fill="url(#paint15_linear_9599_45580)"
            />
            <path
                d="M187.136 306.965L137.296 335.688C136.179 336.318 134.919 336.649 133.637 336.649C132.355 336.649 131.095 336.318 129.979 335.688L80.2023 306.804C80.0238 306.7 79.8704 306.557 79.753 306.387C79.6121 306.657 79.5801 306.97 79.6637 307.263C79.7473 307.556 79.9401 307.805 80.2023 307.96L129.979 336.843C131.095 337.473 132.355 337.804 133.637 337.804C134.919 337.804 136.179 337.473 137.296 336.843L187.136 308.12C187.407 307.965 187.606 307.71 187.69 307.41C187.774 307.11 187.736 306.788 187.585 306.515C187.487 306.709 187.33 306.866 187.136 306.965Z"
                fill="white"
            />
            <path
                opacity="0.2"
                d="M133.669 310.463C137.586 310.463 140.762 308.624 140.762 306.355C140.762 304.086 137.586 302.247 133.669 302.247C129.752 302.247 126.576 304.086 126.576 306.355C126.576 308.624 129.752 310.463 133.669 310.463Z"
                fill="#180D5B"
            />
            <g opacity="0.61">
                <path
                    opacity="0.61"
                    d="M137.135 278.563C136.096 277.963 134.917 277.647 133.717 277.647C132.517 277.647 131.338 277.963 130.299 278.563L89.5732 302.023C89.4265 302.107 89.3047 302.229 89.22 302.375C89.1353 302.522 89.0907 302.688 89.0907 302.857C89.0907 303.026 89.1353 303.192 89.22 303.339C89.3047 303.485 89.4265 303.607 89.5732 303.691L130.62 327.376C131.535 327.912 132.576 328.194 133.637 328.194C134.697 328.194 135.739 327.912 136.654 327.376L177.765 303.691C177.911 303.607 178.033 303.485 178.118 303.339C178.203 303.192 178.247 303.026 178.247 302.857C178.247 302.688 178.203 302.522 178.118 302.375C178.033 302.229 177.911 302.107 177.765 302.023L137.135 278.563Z"
                    fill="url(#paint16_linear_9599_45580)"
                />
                <path
                    opacity="0.61"
                    d="M137.135 271.695C136.096 271.095 134.917 270.779 133.717 270.779C132.517 270.779 131.338 271.095 130.299 271.695L89.5732 295.155C89.4265 295.239 89.3047 295.361 89.22 295.507C89.1353 295.654 89.0907 295.82 89.0907 295.989C89.0907 296.158 89.1353 296.324 89.22 296.471C89.3047 296.617 89.4265 296.739 89.5732 296.823L130.62 320.508C131.535 321.044 132.576 321.327 133.637 321.327C134.697 321.327 135.739 321.044 136.654 320.508L177.765 296.856C177.911 296.771 178.033 296.65 178.118 296.503C178.203 296.357 178.247 296.19 178.247 296.021C178.247 295.852 178.203 295.686 178.118 295.539C178.033 295.393 177.911 295.271 177.765 295.187L137.135 271.695Z"
                    fill="url(#paint17_linear_9599_45580)"
                />
                <path
                    opacity="0.61"
                    d="M137.135 264.859C136.096 264.259 134.917 263.943 133.717 263.943C132.517 263.943 131.338 264.259 130.299 264.859L89.5732 288.287C89.4265 288.371 89.3047 288.493 89.22 288.639C89.1353 288.786 89.0907 288.952 89.0907 289.121C89.0907 289.29 89.1353 289.457 89.22 289.603C89.3047 289.75 89.4265 289.871 89.5732 289.956L130.62 313.608C131.556 314.152 132.619 314.439 133.701 314.439C134.783 314.439 135.846 314.152 136.782 313.608L177.893 289.923C178.04 289.839 178.162 289.717 178.246 289.571C178.331 289.424 178.376 289.258 178.376 289.089C178.376 288.92 178.331 288.754 178.246 288.607C178.162 288.461 178.04 288.339 177.893 288.255L137.135 264.859Z"
                    fill="url(#paint18_linear_9599_45580)"
                />
            </g>
            <rect
                x="213.484"
                y="345.156"
                width="1.54046"
                height="1.54046"
                fill="url(#pattern0)"
            />
            <rect
                x="221.187"
                y="291.239"
                width="1.54046"
                height="1.54046"
                fill="url(#pattern1)"
            />
            <path
                opacity="0.4"
                d="M213.24 224.352C213.614 216.509 207.559 209.848 199.716 209.474C191.873 209.1 185.212 215.155 184.838 222.998C184.464 230.841 190.519 237.502 198.362 237.876C206.205 238.25 212.866 232.195 213.24 224.352Z"
                fill="url(#paint19_radial_9599_45580)"
            />
            <path
                d="M202.861 221.437C202.423 220.688 201.773 220.085 200.992 219.705C200.212 219.325 199.336 219.184 198.476 219.301C197.616 219.418 196.809 219.786 196.158 220.36C195.507 220.935 195.041 221.689 194.818 222.528C194.595 223.367 194.625 224.253 194.905 225.075C195.184 225.896 195.701 226.617 196.39 227.145C197.078 227.674 197.908 227.987 198.774 228.045C199.64 228.103 200.504 227.903 201.257 227.47C202.267 226.88 203.002 225.915 203.303 224.784C203.604 223.654 203.445 222.451 202.861 221.437Z"
                fill="url(#paint20_radial_9599_45580)"
            />
            <path
                opacity="0.4"
                d="M235.789 245.667C235.088 244.457 234.044 243.483 232.789 242.867C231.533 242.251 230.124 242.022 228.738 242.209C227.352 242.395 226.053 242.989 225.005 243.915C223.957 244.84 223.208 246.056 222.852 247.408C222.496 248.76 222.549 250.188 223.005 251.509C223.461 252.831 224.3 253.988 225.414 254.832C226.528 255.677 227.868 256.172 229.263 256.254C230.659 256.337 232.048 256.003 233.254 255.295C234.054 254.831 234.754 254.213 235.314 253.477C235.875 252.741 236.285 251.902 236.52 251.008C236.756 250.113 236.812 249.181 236.687 248.265C236.561 247.348 236.256 246.466 235.789 245.667Z"
                fill="url(#paint21_radial_9599_45580)"
            />
            <path
                d="M231.585 248.138C231.442 247.889 231.251 247.671 231.024 247.496C230.796 247.321 230.536 247.192 230.259 247.118C229.982 247.043 229.693 247.024 229.408 247.062C229.123 247.099 228.849 247.192 228.6 247.336C228.098 247.626 227.731 248.103 227.581 248.662C227.43 249.222 227.509 249.819 227.798 250.321C228.087 250.823 228.564 251.19 229.124 251.34C229.684 251.491 230.28 251.412 230.783 251.123C231.032 250.98 231.25 250.789 231.425 250.562C231.6 250.334 231.729 250.075 231.803 249.797C231.878 249.52 231.897 249.231 231.859 248.946C231.822 248.661 231.729 248.387 231.585 248.138Z"
                fill="url(#paint22_radial_9599_45580)"
            />
            <g opacity="0.5">
                <path
                    opacity="0.4"
                    d="M226.642 250.738C226.172 249.951 225.479 249.32 224.651 248.925C223.823 248.531 222.897 248.389 221.989 248.519C221.081 248.649 220.231 249.045 219.547 249.656C218.863 250.267 218.375 251.067 218.144 251.955C217.914 252.843 217.95 253.779 218.25 254.646C218.55 255.513 219.099 256.272 219.828 256.828C220.558 257.383 221.436 257.711 222.351 257.77C223.267 257.828 224.179 257.615 224.974 257.157C225.508 256.848 225.976 256.437 226.35 255.946C226.724 255.455 226.997 254.894 227.152 254.297C227.307 253.699 227.342 253.077 227.255 252.466C227.167 251.855 226.959 251.268 226.642 250.738Z"
                    fill="url(#paint23_radial_9599_45580)"
                />
                <path
                    opacity="0.5"
                    d="M223.818 252.375C223.624 252.042 223.306 251.799 222.934 251.697C222.563 251.595 222.166 251.642 221.828 251.829C221.497 252.025 221.256 252.344 221.16 252.718C221.063 253.091 221.119 253.487 221.315 253.819C221.511 254.151 221.83 254.391 222.203 254.488C222.577 254.584 222.973 254.528 223.305 254.332C223.63 254.138 223.866 253.825 223.962 253.459C224.058 253.092 224.006 252.703 223.818 252.375Z"
                    fill="url(#paint24_radial_9599_45580)"
                />
            </g>
            <path
                d="M220.224 247.785C219.836 247.135 219.208 246.663 218.476 246.471C217.744 246.279 216.965 246.382 216.308 246.758C215.986 246.95 215.704 247.203 215.48 247.504C215.255 247.805 215.092 248.146 215 248.51C214.908 248.874 214.888 249.252 214.942 249.623C214.995 249.994 215.122 250.351 215.314 250.674C215.496 251.001 215.743 251.288 216.039 251.518C216.335 251.748 216.675 251.915 217.037 252.011C217.4 252.107 217.778 252.129 218.149 252.076C218.52 252.022 218.876 251.895 219.197 251.701C219.522 251.512 219.806 251.26 220.034 250.961C220.262 250.661 220.428 250.32 220.524 249.956C220.619 249.593 220.642 249.213 220.59 248.841C220.539 248.468 220.414 248.11 220.224 247.785Z"
                fill="url(#paint25_radial_9599_45580)"
            />
            <path
                d="M218.395 248.78C218.335 248.679 218.256 248.591 218.162 248.521C218.068 248.452 217.961 248.402 217.847 248.375C217.733 248.348 217.614 248.344 217.499 248.364C217.383 248.384 217.273 248.428 217.175 248.491C217.075 248.547 216.987 248.623 216.918 248.715C216.849 248.806 216.799 248.911 216.772 249.022C216.745 249.134 216.741 249.249 216.761 249.362C216.78 249.475 216.823 249.583 216.886 249.679C216.943 249.778 217.018 249.865 217.109 249.935C217.199 250.005 217.303 250.057 217.413 250.087C217.523 250.116 217.639 250.124 217.752 250.109C217.865 250.094 217.975 250.057 218.074 250C218.276 249.879 218.424 249.684 218.484 249.456C218.544 249.228 218.512 248.985 218.395 248.78Z"
                fill="url(#paint26_radial_9599_45580)"
            />
            <path
                opacity="0.4"
                d="M223.594 209.338C222.839 208.034 221.714 206.983 220.362 206.319C219.009 205.656 217.49 205.408 215.997 205.609C214.504 205.81 213.104 206.449 211.975 207.447C210.845 208.444 210.038 209.755 209.655 211.212C209.271 212.669 209.329 214.207 209.821 215.631C210.313 217.055 211.217 218.301 212.418 219.21C213.619 220.12 215.063 220.653 216.567 220.74C218.071 220.828 219.567 220.468 220.866 219.704C222.597 218.686 223.855 217.025 224.366 215.083C224.877 213.141 224.599 211.076 223.594 209.338Z"
                fill="url(#paint27_radial_9599_45580)"
            />
            <path
                d="M219.068 211.97C218.753 211.429 218.237 211.036 217.632 210.876C217.027 210.717 216.384 210.804 215.843 211.119C215.303 211.434 214.909 211.951 214.75 212.556C214.59 213.16 214.678 213.804 214.993 214.344C215.308 214.885 215.824 215.278 216.429 215.438C217.034 215.597 217.677 215.51 218.218 215.195C218.758 214.88 219.152 214.363 219.311 213.758C219.471 213.153 219.383 212.51 219.068 211.97Z"
                fill="url(#paint28_radial_9599_45580)"
            />
            <path
                opacity="0.4"
                d="M206.456 203.914C205.693 202.627 204.567 201.594 203.218 200.946C201.87 200.298 200.36 200.064 198.878 200.274C197.397 200.483 196.011 201.127 194.895 202.123C193.778 203.12 192.982 204.424 192.607 205.872C192.232 207.321 192.293 208.848 192.785 210.261C193.276 211.674 194.175 212.91 195.368 213.813C196.561 214.716 197.995 215.245 199.488 215.334C200.982 215.423 202.468 215.067 203.76 214.312C204.622 213.809 205.376 213.14 205.978 212.344C206.581 211.548 207.02 210.64 207.27 209.674C207.521 208.708 207.578 207.701 207.438 206.713C207.298 205.724 206.964 204.773 206.456 203.914Z"
                fill="url(#paint29_radial_9599_45580)"
            />
            <path
                d="M201.159 209.762C202.254 209.074 202.583 207.627 201.894 206.532C201.205 205.437 199.759 205.107 198.664 205.796C197.569 206.485 197.239 207.931 197.928 209.027C198.617 210.122 200.063 210.451 201.159 209.762Z"
                fill="url(#paint30_radial_9599_45580)"
            />
            <path
                opacity="0.4"
                d="M55.394 245.827C65.0361 245.827 72.8526 238.011 72.8526 228.369C72.8526 218.727 65.0361 210.91 55.394 210.91C45.7519 210.91 37.9354 218.727 37.9354 228.369C37.9354 238.011 45.7519 245.827 55.394 245.827Z"
                fill="url(#paint31_radial_9599_45580)"
            />
            <path
                d="M60.0477 225.641C59.5081 224.722 58.708 223.983 57.7485 223.518C56.7891 223.053 55.7133 222.883 54.6573 223.029C53.6012 223.176 52.6123 223.632 51.8155 224.34C51.0186 225.049 50.4497 225.977 50.1806 227.009C49.9115 228.041 49.9542 229.129 50.3035 230.136C50.6528 231.144 51.2928 232.025 52.1428 232.668C52.9927 233.312 54.0144 233.689 55.0787 233.752C56.143 233.815 57.2021 233.561 58.1221 233.023C58.7335 232.665 59.2683 232.19 59.6959 231.625C60.1236 231.06 60.4358 230.417 60.6146 229.731C60.7935 229.045 60.8354 228.331 60.7382 227.63C60.6409 226.928 60.4063 226.252 60.0477 225.641Z"
                fill="url(#paint32_radial_9599_45580)"
            />
            <path
                opacity="0.4"
                d="M77.1269 279.733C80.8193 275.288 80.2097 268.692 75.7653 265C71.3209 261.307 64.7246 261.917 61.0321 266.361C57.3397 270.806 57.9493 277.402 62.3937 281.094C66.8381 284.787 73.4344 284.177 77.1269 279.733Z"
                fill="url(#paint33_radial_9599_45580)"
            />
            <path
                d="M89.7658 232.605C89.5065 232.08 89.1418 231.613 88.6943 231.234C88.2468 230.856 87.7261 230.574 87.1647 230.405C86.6033 230.237 86.0132 230.185 85.4312 230.255C84.8492 230.325 84.2877 230.513 83.7818 230.809C83.2759 231.105 82.8364 231.502 82.4907 231.976C82.145 232.449 81.9005 232.988 81.7724 233.56C81.6443 234.132 81.6353 234.725 81.7462 235.3C81.857 235.876 82.0851 236.422 82.4165 236.906C82.6757 237.432 83.0404 237.898 83.4879 238.277C83.9354 238.655 84.4561 238.938 85.0175 239.106C85.5789 239.275 86.169 239.326 86.751 239.256C87.333 239.187 87.8945 238.998 88.4004 238.702C88.9063 238.406 89.3458 238.009 89.6915 237.536C90.0372 237.062 90.2818 236.523 90.4099 235.951C90.538 235.379 90.5469 234.787 90.4361 234.211C90.3252 233.636 90.0971 233.089 89.7658 232.605Z"
                fill="url(#paint34_radial_9599_45580)"
            />
            <path
                d="M87.2306 234.082C87.0508 233.785 86.761 233.571 86.4244 233.487C86.0879 233.403 85.7317 233.455 85.4334 233.633C85.2805 233.716 85.1462 233.829 85.0386 233.966C84.9309 234.103 84.8522 234.26 84.8071 234.428C84.7621 234.596 84.7517 234.771 84.7766 234.944C84.8015 235.116 84.8612 235.281 84.952 235.43C85.1265 235.732 85.4139 235.952 85.7509 236.043C86.0879 236.133 86.447 236.086 86.7492 235.911C87.0535 235.73 87.2746 235.437 87.3647 235.095C87.4547 234.753 87.4066 234.389 87.2306 234.082Z"
                fill="url(#paint35_radial_9599_45580)"
            />
            <path
                d="M87.3266 220.153C86.7267 219.129 85.8367 218.306 84.7692 217.788C83.7016 217.27 82.5044 217.08 81.3289 217.242C80.1534 217.404 79.0523 217.911 78.1648 218.699C77.2774 219.487 76.6433 220.52 76.3429 221.668C76.0424 222.816 76.0891 224.027 76.4769 225.149C76.8647 226.27 77.5762 227.251 78.5216 227.969C79.467 228.686 80.6038 229.107 81.7883 229.178C82.9728 229.249 84.1518 228.968 85.1764 228.369C85.8606 227.974 86.4596 227.447 86.9388 226.819C87.4179 226.191 87.7676 225.474 87.9676 224.71C88.1676 223.946 88.2139 223.15 88.1039 222.367C87.9939 221.585 87.7297 220.833 87.3266 220.153Z"
                fill="url(#paint36_radial_9599_45580)"
            />
            <path
                d="M83.7321 222.239C83.6124 222.026 83.4512 221.839 83.2579 221.689C83.0646 221.539 82.8432 221.43 82.6068 221.367C82.3705 221.304 82.1239 221.289 81.8817 221.323C81.6395 221.357 81.4066 221.44 81.1968 221.565C80.986 221.69 80.8019 221.854 80.6548 222.05C80.5077 222.245 80.4005 222.468 80.3394 222.705C80.2783 222.942 80.2645 223.188 80.2987 223.431C80.3329 223.673 80.4145 223.906 80.5389 224.117C80.6632 224.328 80.8278 224.512 81.0233 224.659C81.2188 224.806 81.4414 224.913 81.6783 224.974C81.9152 225.035 82.1619 225.049 82.4041 225.015C82.6464 224.981 82.8795 224.899 83.0903 224.775C83.3009 224.652 83.485 224.489 83.6316 224.295C83.7783 224.1 83.8847 223.878 83.9445 223.642C84.0043 223.406 84.0163 223.16 83.9798 222.919C83.9433 222.678 83.8591 222.447 83.7321 222.239Z"
                fill="url(#paint37_radial_9599_45580)"
            />
            <path
                d="M82.545 232.894C82.2802 232.411 81.877 232.019 81.3873 231.767C80.8976 231.515 80.3437 231.416 79.7969 231.481C79.2501 231.546 78.7354 231.774 78.319 232.134C77.9026 232.495 77.6034 232.971 77.4601 233.503C77.3168 234.035 77.3358 234.597 77.5147 235.118C77.6936 235.639 78.0242 236.094 78.464 236.426C78.9038 236.757 79.4327 236.949 79.9827 236.978C80.5326 237.006 81.0785 236.869 81.5501 236.585C82.1602 236.217 82.6044 235.628 82.7899 234.94C82.9753 234.252 82.8875 233.519 82.545 232.894Z"
                fill="url(#paint38_radial_9599_45580)"
            />
            <path
                d="M80.908 233.857C80.852 233.757 80.776 233.67 80.6846 233.6C80.5933 233.531 80.4886 233.481 80.3772 233.454C80.2658 233.427 80.15 233.423 80.037 233.443C79.924 233.462 79.8163 233.505 79.7206 233.568C79.6215 233.621 79.5347 233.694 79.4656 233.782C79.3965 233.871 79.3467 233.973 79.3195 234.082C79.2923 234.191 79.2882 234.304 79.3075 234.415C79.3269 234.525 79.3692 234.63 79.4318 234.724C79.4852 234.822 79.5579 234.908 79.6454 234.978C79.733 235.048 79.8336 235.099 79.9414 235.129C80.0492 235.159 80.1619 235.167 80.2728 235.152C80.3838 235.138 80.4906 235.101 80.5871 235.044C80.7815 234.924 80.923 234.735 80.9826 234.514C81.0422 234.294 81.0155 234.059 80.908 233.857Z"
                fill="url(#paint39_radial_9599_45580)"
            />
            <path
                d="M112.744 222.175C112.561 221.861 112.317 221.587 112.028 221.367C111.738 221.147 111.408 220.986 111.057 220.894C110.705 220.801 110.339 220.779 109.978 220.828C109.618 220.877 109.271 220.997 108.957 221.18C108.643 221.364 108.368 221.607 108.148 221.897C107.928 222.186 107.768 222.516 107.675 222.868C107.583 223.22 107.561 223.586 107.61 223.946C107.659 224.306 107.779 224.653 107.962 224.967C108.142 225.286 108.383 225.564 108.672 225.787C108.961 226.01 109.292 226.173 109.645 226.266C109.998 226.359 110.367 226.38 110.728 226.327C111.089 226.275 111.437 226.151 111.749 225.962C112.064 225.78 112.34 225.537 112.56 225.247C112.781 224.958 112.942 224.628 113.034 224.276C113.127 223.924 113.149 223.557 113.099 223.197C113.049 222.836 112.929 222.489 112.744 222.175Z"
                fill="url(#paint40_radial_9599_45580)"
            />
            <path
                d="M111.108 223.138C110.988 222.943 110.796 222.803 110.573 222.748C110.351 222.694 110.116 222.73 109.92 222.849C109.821 222.902 109.734 222.975 109.665 223.063C109.596 223.152 109.546 223.254 109.519 223.362C109.492 223.471 109.488 223.585 109.507 223.695C109.526 223.806 109.569 223.911 109.631 224.004C109.685 224.103 109.757 224.189 109.845 224.259C109.933 224.328 110.033 224.38 110.141 224.41C110.249 224.44 110.361 224.448 110.472 224.433C110.583 224.419 110.69 224.382 110.787 224.325C110.981 224.205 111.123 224.016 111.182 223.795C111.242 223.574 111.215 223.339 111.108 223.138Z"
                fill="url(#paint41_radial_9599_45580)"
            />
            <path
                d="M272.918 181.36C274.55 174.807 270.562 168.171 264.009 166.539C257.456 164.906 250.821 168.895 249.188 175.448C247.556 182 251.544 188.636 258.097 190.268C264.65 191.901 271.285 187.912 272.918 181.36Z"
                fill="url(#paint42_radial_9599_45580)"
            />
            <path
                d="M261.495 178.4H260.212V318.39H261.495V178.4Z"
                fill="url(#paint43_linear_9599_45580)"
            />
            <path
                d="M264.32 176.475C263.945 175.825 263.387 175.302 262.715 174.97C262.043 174.639 261.287 174.514 260.544 174.612C259.801 174.71 259.104 175.026 258.541 175.52C257.977 176.015 257.574 176.665 257.38 177.389C257.187 178.113 257.213 178.878 257.454 179.588C257.696 180.297 258.143 180.919 258.738 181.374C259.333 181.829 260.05 182.098 260.798 182.146C261.546 182.193 262.291 182.018 262.94 181.642C263.8 181.133 264.427 180.306 264.685 179.34C264.943 178.374 264.812 177.345 264.32 176.475Z"
                fill="#A7F9EF"
            />
            <path
                d="M263.627 165.576C265.26 159.024 261.271 152.388 254.719 150.755C248.166 149.123 241.53 153.111 239.898 159.664C238.265 166.217 242.254 172.852 248.807 174.485C255.359 176.118 261.995 172.129 263.627 165.576Z"
                fill="url(#paint44_radial_9599_45580)"
            />
            <path
                d="M252.22 162.578H250.937V302.568H252.22V162.578Z"
                fill="url(#paint45_linear_9599_45580)"
            />
            <path
                d="M255.013 160.685C254.62 160.054 254.052 159.553 253.377 159.244C252.702 158.934 251.951 158.83 251.218 158.943C250.484 159.057 249.8 159.384 249.251 159.883C248.701 160.382 248.311 161.032 248.127 161.751C247.944 162.471 247.977 163.228 248.22 163.929C248.464 164.631 248.909 165.245 249.499 165.695C250.089 166.146 250.798 166.413 251.539 166.464C252.28 166.514 253.019 166.346 253.665 165.98C254.109 165.728 254.497 165.39 254.807 164.984C255.117 164.578 255.341 164.114 255.467 163.62C255.593 163.125 255.618 162.61 255.54 162.106C255.462 161.601 255.282 161.118 255.013 160.685Z"
                fill="#A7F9EF"
            />
            <path
                d="M40.5198 267.499C42.1524 260.946 38.1638 254.31 31.6111 252.678C25.0584 251.045 18.4229 255.034 16.7904 261.587C15.1578 268.139 19.1464 274.775 25.6991 276.407C32.2518 278.04 38.8873 274.051 40.5198 267.499Z"
                fill="url(#paint46_radial_9599_45580)"
            />
            <path
                d="M28.1792 264.538H29.4629L29.4629 124.548H28.1792L28.1792 264.538Z"
                fill="url(#paint47_linear_9599_45580)"
            />
            <path
                d="M25.3871 266.431C25.7645 267.079 26.3258 267.601 26.9998 267.929C27.6739 268.258 28.4304 268.379 29.1734 268.278C29.9164 268.176 30.6125 267.856 31.1734 267.358C31.7343 266.86 32.1348 266.207 32.3241 265.482C32.5134 264.756 32.483 263.99 32.2367 263.282C31.9904 262.574 31.5394 261.954 30.9408 261.503C30.3421 261.051 29.6229 260.787 28.8741 260.745C28.1254 260.702 27.3809 260.883 26.735 261.264C25.8798 261.779 25.2599 262.607 25.008 263.573C24.756 264.539 24.8921 265.565 25.3871 266.431Z"
                fill="#A7F9EF"
            />
            <path
                d="M47.8235 281.33C49.456 274.778 45.4674 268.142 38.9147 266.51C32.362 264.877 25.7265 268.866 24.094 275.418C22.4614 281.971 26.45 288.607 33.0027 290.239C39.5554 291.872 46.1909 287.883 47.8235 281.33Z"
                fill="url(#paint48_radial_9599_45580)"
            />
            <path
                d="M35.4964 278.37H36.7802L36.7802 138.38H35.4964L35.4964 278.37Z"
                fill="url(#paint49_linear_9599_45580)"
            />
            <path
                d="M32.7043 280.296C33.0824 280.94 33.643 281.459 34.3153 281.786C34.9877 282.113 35.7417 282.233 36.4824 282.132C37.2232 282.031 37.9174 281.713 38.4778 281.219C39.0381 280.724 39.4395 280.074 39.6313 279.352C39.8231 278.629 39.7967 277.866 39.5555 277.158C39.3143 276.451 38.8691 275.83 38.276 275.375C37.6828 274.92 36.9683 274.651 36.2223 274.601C35.4764 274.552 34.7325 274.724 34.0843 275.097C33.6507 275.346 33.2707 275.678 32.9663 276.075C32.6619 276.472 32.4391 276.925 32.3108 277.408C32.1825 277.892 32.1512 278.396 32.2188 278.891C32.2863 279.387 32.4513 279.864 32.7043 280.296Z"
                fill="#A7F9EF"
            />
            <path
                d="M217.015 80.2278L186.526 62.673C170.865 53.2376 149.17 54.3288 125.1 68.225C78.0199 95.3757 39.8292 161.263 39.8292 215.307C39.8292 243.228 50.0669 262.516 66.4343 270.892L97.1152 288.544L106.486 272.24C112.978 270.121 119.219 267.302 125.1 263.832C172.085 236.585 210.371 170.891 210.371 116.846C210.427 109.688 209.651 102.548 208.061 95.5683L217.015 80.2278Z"
                fill="url(#paint50_linear_9599_45580)"
            />
            <path
                d="M215.771 218.822C249.28 161.501 249.625 99.3567 216.543 80.0172C183.461 60.6778 129.478 91.4672 95.9696 148.787C62.4611 206.107 62.1154 268.252 95.1977 287.592C128.28 306.931 182.262 276.142 215.771 218.822Z"
                fill="url(#paint51_linear_9599_45580)"
            />
            <path
                d="M194.132 74.2583V75.6062C222.278 75.6062 239.768 98.0713 239.768 134.561C239.768 188.092 201.834 253.53 155.203 280.456C143.872 287.533 130.878 291.507 117.526 291.977C89.3804 291.977 71.9218 269.512 71.9218 233.022C71.9218 179.523 109.856 114.054 156.487 87.1276C167.809 80.0554 180.791 76.0823 194.132 75.6062V74.2583ZM194.132 74.2583C180.636 74.7835 167.508 78.8092 156.038 85.9402C108.957 113.155 70.7665 179.01 70.7665 233.022C70.7665 271.277 90.0223 293.357 117.751 293.357C131.327 292.814 144.525 288.732 156.038 281.515C203.118 254.332 241.309 188.445 241.309 134.433C241.148 96.3383 222.021 74.2583 194.132 74.2583Z"
                fill="#DEFCF2"
            />
            <path
                d="M201.53 210.497C227.068 166.81 227.329 119.445 202.112 104.703C176.895 89.9618 135.749 113.426 110.211 157.112C84.6721 200.799 84.4117 248.164 109.629 262.905C134.846 277.647 175.992 254.183 201.53 210.497Z"
                fill="url(#paint52_linear_9599_45580)"
            />
            <path
                d="M200.583 103.88C207.772 111.743 211.976 123.81 211.976 139.471C211.976 180.679 182.868 230.872 146.988 251.572C129.657 261.585 113.932 262.869 102.282 256.932C113.996 269.769 133.637 271.213 155.877 258.376C191.757 237.644 220.866 187.45 220.866 146.275C220.866 124.965 213.067 110.267 200.583 103.88Z"
                fill="url(#paint53_linear_9599_45580)"
            />
            <path
                opacity="0.2"
                d="M171.025 210.365L191.18 190.628L146.442 128.72L145.576 146.821L102.699 231.963L102.057 248.588L171.025 210.365Z"
                fill="url(#paint54_linear_9599_45580)"
            />
            <path
                d="M183.06 185.557L154.562 133.759L146.442 128.72L102.699 231.963L110.819 237.002L183.06 185.557Z"
                fill="url(#paint55_linear_9599_45580)"
            />
            <path
                d="M191.18 190.628L154.562 133.759L110.819 237.002L191.18 190.628Z"
                fill="url(#paint56_linear_9599_45580)"
            />
            <path
                d="M191.18 190.628L154.562 133.759V186.038L191.18 190.628Z"
                fill="url(#paint57_linear_9599_45580)"
            />
            <path
                d="M191.18 190.628L154.562 133.759V186.038L191.18 190.628Z"
                fill="url(#paint58_linear_9599_45580)"
            />
            <path
                d="M154.562 186.038V133.759L110.819 237.002L154.562 186.038Z"
                fill="url(#paint59_linear_9599_45580)"
            />
            <path
                d="M191.18 190.628L153.952 135.909L154.626 136.583L110.819 237.002L154.562 133.759L191.18 190.628Z"
                fill="#DEFCF2"
            />
            <defs>
                <pattern
                    id="pattern0"
                    patternContentUnits="objectBoundingBox"
                    width="1"
                    height="1"
                >
                    <use transform="scale(0.5 0.333333)" />
                </pattern>
                <pattern
                    id="pattern1"
                    patternContentUnits="objectBoundingBox"
                    width="1"
                    height="1"
                >
                    <use transform="scale(0.5 0.333333)" />
                </pattern>
                <radialGradient
                    id="paint0_radial_9599_45580"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(238.973 226.869) rotate(-30.2031) scale(12.2787)"
                >
                    <stop offset="0.01" stopColor="#CFF9FC" />
                    <stop offset="0.41" stopColor="#96F2F1" stopOpacity="0.3" />
                    <stop offset="1" stopColor="#42E8E0" stopOpacity="0" />
                </radialGradient>
                <linearGradient
                    id="paint1_linear_9599_45580"
                    x1="239.801"
                    y1="191.269"
                    x2="239.801"
                    y2="326.702"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#A7F9EF" />
                    <stop offset="0.29" stopColor="#42E8E0" />
                    <stop
                        offset="0.82"
                        stopColor="#42E8E0"
                        stopOpacity="0.25"
                    />
                    <stop offset="1" stopColor="#42E8E0" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_9599_45580"
                    x1="20.1881"
                    y1="208.985"
                    x2="20.1881"
                    y2="196.95"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#42E8E0" stopOpacity="0" />
                    <stop
                        offset="0.18"
                        stopColor="#42E8E0"
                        stopOpacity="0.25"
                    />
                    <stop offset="0.71" stopColor="#42E8E0" />
                    <stop offset="1" stopColor="#A7F9EF" />
                </linearGradient>
                <linearGradient
                    id="paint3_linear_9599_45580"
                    x1="82.4387"
                    y1="853.247"
                    x2="82.4387"
                    y2="808.116"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#42E8E0" stopOpacity="0" />
                    <stop
                        offset="0.18"
                        stopColor="#42E8E0"
                        stopOpacity="0.25"
                    />
                    <stop offset="0.71" stopColor="#42E8E0" />
                    <stop offset="1" stopColor="#A7F9EF" />
                </linearGradient>
                <linearGradient
                    id="paint4_linear_9599_45580"
                    x1="105.528"
                    y1="911.603"
                    x2="105.528"
                    y2="847.908"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#42E8E0" stopOpacity="0" />
                    <stop
                        offset="0.18"
                        stopColor="#42E8E0"
                        stopOpacity="0.25"
                    />
                    <stop offset="0.71" stopColor="#42E8E0" />
                    <stop offset="1" stopColor="#A7F9EF" />
                </linearGradient>
                <linearGradient
                    id="paint5_linear_9599_45580"
                    x1="142.362"
                    y1="432.93"
                    x2="142.362"
                    y2="387.799"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#42E8E0" stopOpacity="0" />
                    <stop
                        offset="0.18"
                        stopColor="#42E8E0"
                        stopOpacity="0.25"
                    />
                    <stop offset="0.71" stopColor="#42E8E0" />
                    <stop offset="1" stopColor="#A7F9EF" />
                </linearGradient>
                <linearGradient
                    id="paint6_linear_9599_45580"
                    x1="142.44"
                    y1="493.949"
                    x2="142.44"
                    y2="448.818"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#42E8E0" stopOpacity="0" />
                    <stop
                        offset="0.18"
                        stopColor="#42E8E0"
                        stopOpacity="0.25"
                    />
                    <stop offset="0.71" stopColor="#42E8E0" />
                    <stop offset="1" stopColor="#A7F9EF" />
                </linearGradient>
                <linearGradient
                    id="paint7_linear_9599_45580"
                    x1="165.296"
                    y1="498.65"
                    x2="165.296"
                    y2="435.098"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#42E8E0" stopOpacity="0" />
                    <stop
                        offset="0.18"
                        stopColor="#42E8E0"
                        stopOpacity="0.25"
                    />
                    <stop offset="0.71" stopColor="#42E8E0" />
                    <stop offset="1" stopColor="#A7F9EF" />
                </linearGradient>
                <linearGradient
                    id="paint8_linear_9599_45580"
                    x1="83.2802"
                    y1="911.75"
                    x2="83.2802"
                    y2="866.619"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#42E8E0" stopOpacity="0" />
                    <stop
                        offset="0.18"
                        stopColor="#42E8E0"
                        stopOpacity="0.25"
                    />
                    <stop offset="0.71" stopColor="#42E8E0" />
                    <stop offset="1" stopColor="#A7F9EF" />
                </linearGradient>
                <linearGradient
                    id="paint9_linear_9599_45580"
                    x1="372.889"
                    y1="474.713"
                    x2="372.889"
                    y2="472.284"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#42E8E0" stopOpacity="0" />
                    <stop
                        offset="0.18"
                        stopColor="#42E8E0"
                        stopOpacity="0.25"
                    />
                    <stop offset="0.71" stopColor="#42E8E0" />
                    <stop offset="1" stopColor="#A7F9EF" />
                </linearGradient>
                <linearGradient
                    id="paint10_linear_9599_45580"
                    x1="79.6243"
                    y1="332.126"
                    x2="187.746"
                    y2="332.126"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#5761D7" />
                    <stop offset="0.07" stopColor="#5472D8" />
                    <stop offset="0.28" stopColor="#4E9CDB" />
                    <stop offset="0.48" stopColor="#49BDDD" />
                    <stop offset="0.67" stopColor="#45D5DF" />
                    <stop offset="0.85" stopColor="#43E3E0" />
                    <stop offset="1" stopColor="#42E8E0" />
                </linearGradient>
                <linearGradient
                    id="paint11_linear_9599_45580"
                    x1="3343.01"
                    y1="5518.04"
                    x2="6984.71"
                    y2="5518.04"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#5761D7" />
                    <stop offset="0.07" stopColor="#5472D8" />
                    <stop offset="0.28" stopColor="#4E9CDB" />
                    <stop offset="0.48" stopColor="#49BDDD" />
                    <stop offset="0.67" stopColor="#45D5DF" />
                    <stop offset="0.85" stopColor="#43E3E0" />
                    <stop offset="1" stopColor="#42E8E0" />
                </linearGradient>
                <linearGradient
                    id="paint12_linear_9599_45580"
                    x1="3343.01"
                    y1="5356.09"
                    x2="6984.71"
                    y2="5356.09"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#5761D7" />
                    <stop offset="0.07" stopColor="#5472D8" />
                    <stop offset="0.28" stopColor="#4E9CDB" />
                    <stop offset="0.48" stopColor="#49BDDD" />
                    <stop offset="0.67" stopColor="#45D5DF" />
                    <stop offset="0.85" stopColor="#43E3E0" />
                    <stop offset="1" stopColor="#42E8E0" />
                </linearGradient>
                <linearGradient
                    id="paint13_linear_9599_45580"
                    x1="3343.01"
                    y1="5188.72"
                    x2="6984.71"
                    y2="5188.72"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#5761D7" />
                    <stop offset="0.07" stopColor="#5472D8" />
                    <stop offset="0.28" stopColor="#4E9CDB" />
                    <stop offset="0.48" stopColor="#49BDDD" />
                    <stop offset="0.67" stopColor="#45D5DF" />
                    <stop offset="0.85" stopColor="#43E3E0" />
                    <stop offset="1" stopColor="#42E8E0" />
                </linearGradient>
                <linearGradient
                    id="paint14_linear_9599_45580"
                    x1="79.6246"
                    y1="319.641"
                    x2="187.746"
                    y2="319.641"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#2D5382" />
                    <stop offset="1" stopColor="#3C88C4" />
                </linearGradient>
                <linearGradient
                    id="paint15_linear_9599_45580"
                    x1="79.6246"
                    y1="306.804"
                    x2="187.746"
                    y2="306.804"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#1D9DD7" />
                </linearGradient>
                <linearGradient
                    id="paint16_linear_9599_45580"
                    x1="4282.12"
                    y1="4696.02"
                    x2="4282.12"
                    y2="4166.28"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#42E8E0" stopOpacity="0" />
                    <stop
                        offset="0.18"
                        stopColor="#42E8E0"
                        stopOpacity="0.25"
                    />
                    <stop offset="0.71" stopColor="#42E8E0" />
                    <stop offset="1" stopColor="#A7F9EF" />
                </linearGradient>
                <linearGradient
                    id="paint17_linear_9599_45580"
                    x1="4276.77"
                    y1="4591.6"
                    x2="4279.35"
                    y2="3947.6"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#42E8E0" stopOpacity="0" />
                    <stop
                        offset="0.18"
                        stopColor="#42E8E0"
                        stopOpacity="0.25"
                    />
                    <stop offset="0.71" stopColor="#42E8E0" />
                    <stop offset="1" stopColor="#A7F9EF" />
                </linearGradient>
                <linearGradient
                    id="paint18_linear_9599_45580"
                    x1="4288.16"
                    y1="4389.94"
                    x2="4288.16"
                    y2="3969.31"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#42E8E0" stopOpacity="0" />
                    <stop
                        offset="0.18"
                        stopColor="#42E8E0"
                        stopOpacity="0.25"
                    />
                    <stop offset="0.71" stopColor="#42E8E0" />
                    <stop offset="1" stopColor="#A7F9EF" />
                </linearGradient>
                <radialGradient
                    id="paint19_radial_9599_45580"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(234.936 245.714) rotate(-30.4853) scale(14.2747)"
                >
                    <stop stopColor="#53A5F8" />
                    <stop offset="0.34" stopColor="#446AB6" />
                    <stop offset="0.79" stopColor="#312061" />
                    <stop offset="1" stopColor="#2A023F" />
                </radialGradient>
                <radialGradient
                    id="paint20_radial_9599_45580"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(163.956 190.531) rotate(-30.1735) scale(4.37382)"
                >
                    <stop stopColor="#42E8E0" />
                    <stop offset="1" stopColor="#42E8E0" />
                </radialGradient>
                <radialGradient
                    id="paint21_radial_9599_45580"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(11008.1 26409) rotate(-30.1735) scale(31.0704 31.0446)"
                >
                    <stop stopColor="#53A5F8" />
                    <stop offset="0.34" stopColor="#446AB6" />
                    <stop offset="0.79" stopColor="#312061" />
                    <stop offset="1" stopColor="#2A023F" />
                </radialGradient>
                <radialGradient
                    id="paint22_radial_9599_45580"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(-1404.05 -11139.1) scale(2.9729)"
                >
                    <stop stopColor="#42E8E0" />
                    <stop offset="1" stopColor="#42E8E0" />
                </radialGradient>
                <radialGradient
                    id="paint23_radial_9599_45580"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(6260.43 14734.3) rotate(-30.1735) scale(13.4248)"
                >
                    <stop stopColor="#53A5F8" />
                    <stop offset="0.34" stopColor="#446AB6" />
                    <stop offset="0.79" stopColor="#312061" />
                    <stop offset="1" stopColor="#2A023F" />
                </radialGradient>
                <radialGradient
                    id="paint24_radial_9599_45580"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(-866.756 -7274.13) scale(1.30298 1.30039)"
                >
                    <stop stopColor="#42E8E0" />
                    <stop offset="1" stopColor="#42E8E0" />
                </radialGradient>
                <radialGradient
                    id="paint25_radial_9599_45580"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(182.387 216.006) rotate(-30.1736) scale(2.84138)"
                >
                    <stop stopColor="#41E5DE" />
                    <stop offset="0.06" stopColor="#39B8C3" />
                    <stop offset="0.13" stopColor="#3190AA" />
                    <stop offset="0.2" stopColor="#2A6C95" />
                    <stop offset="0.27" stopColor="#254E83" />
                    <stop offset="0.36" stopColor="#203674" />
                    <stop offset="0.45" stopColor="#1C2469" />
                    <stop offset="0.56" stopColor="#1A1761" />
                    <stop offset="0.7" stopColor="#180F5C" />
                    <stop offset="1" stopColor="#180D5B" />
                </radialGradient>
                <radialGradient
                    id="paint26_radial_9599_45580"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(-447.724 -4344.75) scale(0.494255)"
                >
                    <stop stopColor="#42E8E0" />
                    <stop offset="1" stopColor="#42E8E0" />
                </radialGradient>
                <radialGradient
                    id="paint27_radial_9599_45580"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(12030.2 28922.1) rotate(-30.1735) scale(35.7134)"
                >
                    <stop stopColor="#53A5F8" />
                    <stop offset="0.34" stopColor="#446AB6" />
                    <stop offset="0.79" stopColor="#312061" />
                    <stop offset="1" stopColor="#2A023F" />
                </radialGradient>
                <radialGradient
                    id="paint28_radial_9599_45580"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(-1535.05 -12019.5) scale(3.44359)"
                >
                    <stop stopColor="#42E8E0" />
                    <stop offset="1" stopColor="#42E8E0" />
                </radialGradient>
                <radialGradient
                    id="paint29_radial_9599_45580"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(11875.2 28754.5) rotate(-30.1735) scale(35.5627)"
                >
                    <stop stopColor="#53A5F8" />
                    <stop offset="0.34" stopColor="#446AB6" />
                    <stop offset="0.79" stopColor="#312061" />
                    <stop offset="1" stopColor="#2A023F" />
                </radialGradient>
                <radialGradient
                    id="paint30_radial_9599_45580"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(1787.85 3536.98) rotate(-30.4516) scale(3.42201)"
                >
                    <stop stopColor="#42E8E0" />
                    <stop offset="1" stopColor="#42E8E0" />
                </radialGradient>
                <radialGradient
                    id="paint31_radial_9599_45580"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(29388.7 76754.8) rotate(-30.1735) scale(188.959)"
                >
                    <stop stopColor="#53A5F8" />
                    <stop offset="0.34" stopColor="#446AB6" />
                    <stop offset="0.79" stopColor="#312061" />
                    <stop offset="1" stopColor="#2A023F" />
                </radialGradient>
                <radialGradient
                    id="paint32_radial_9599_45580"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(-4444.45 -27504.6) scale(18.1159)"
                >
                    <stop stopColor="#42E8E0" />
                    <stop offset="1" stopColor="#42E8E0" />
                </radialGradient>
                <radialGradient
                    id="paint33_radial_9599_45580"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(16784.6 43301.1) rotate(-30.3948) scale(68.187)"
                >
                    <stop stopColor="#53A5F8" />
                    <stop offset="0.34" stopColor="#446AB6" />
                    <stop offset="0.79" stopColor="#312061" />
                    <stop offset="1" stopColor="#2A023F" />
                </radialGradient>
                <radialGradient
                    id="paint34_radial_9599_45580"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(-3540.24 -23129.6) scale(11.7623 12.0519)"
                >
                    <stop stopColor="#41E5DE" />
                    <stop offset="0.06" stopColor="#39B8C3" />
                    <stop offset="0.13" stopColor="#3190AA" />
                    <stop offset="0.2" stopColor="#2A6C95" />
                    <stop offset="0.27" stopColor="#254E83" />
                    <stop offset="0.36" stopColor="#203674" />
                    <stop offset="0.45" stopColor="#1C2469" />
                    <stop offset="0.56" stopColor="#1A1761" />
                    <stop offset="0.7" stopColor="#180F5C" />
                    <stop offset="1" stopColor="#180D5B" />
                </radialGradient>
                <radialGradient
                    id="paint35_radial_9599_45580"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(-998.719 -6570.55) scale(1.0847 1.08214)"
                >
                    <stop stopColor="#42E8E0" />
                    <stop offset="1" stopColor="#42E8E0" />
                </radialGradient>
                <radialGradient
                    id="paint36_radial_9599_45580"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(-4835.24 -30679.9) scale(22.4676 22.4452)"
                >
                    <stop stopColor="#41E5DE" />
                    <stop offset="0.06" stopColor="#39B8C3" />
                    <stop offset="0.13" stopColor="#3190AA" />
                    <stop offset="0.2" stopColor="#2A6C95" />
                    <stop offset="0.27" stopColor="#254E83" />
                    <stop offset="0.36" stopColor="#203674" />
                    <stop offset="0.45" stopColor="#1C2469" />
                    <stop offset="0.56" stopColor="#1A1761" />
                    <stop offset="0.7" stopColor="#180F5C" />
                    <stop offset="1" stopColor="#180D5B" />
                </radialGradient>
                <radialGradient
                    id="paint37_radial_9599_45580"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(-1440.39 -9374.73) scale(2.15761 2.16214)"
                >
                    <stop stopColor="#42E8E0" />
                    <stop offset="1" stopColor="#42E8E0" />
                </radialGradient>
                <radialGradient
                    id="paint38_radial_9599_45580"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(-2191.69 -13992.7) scale(4.7472)"
                >
                    <stop stopColor="#41E5DE" />
                    <stop offset="0.06" stopColor="#39B8C3" />
                    <stop offset="0.13" stopColor="#3190AA" />
                    <stop offset="0.2" stopColor="#2A6C95" />
                    <stop offset="0.27" stopColor="#254E83" />
                    <stop offset="0.36" stopColor="#203674" />
                    <stop offset="0.45" stopColor="#1C2469" />
                    <stop offset="0.56" stopColor="#1A1761" />
                    <stop offset="0.7" stopColor="#180F5C" />
                    <stop offset="1" stopColor="#180D5B" />
                </radialGradient>
                <radialGradient
                    id="paint39_radial_9599_45580"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(-626.977 -4221.71) scale(0.463907 0.466808)"
                >
                    <stop stopColor="#42E8E0" />
                    <stop offset="1" stopColor="#42E8E0" />
                </radialGradient>
                <radialGradient
                    id="paint40_radial_9599_45580"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(-2115.61 -14098.1) scale(4.76485 4.77535)"
                >
                    <stop stopColor="#41E5DE" />
                    <stop offset="0.06" stopColor="#39B8C3" />
                    <stop offset="0.13" stopColor="#3190AA" />
                    <stop offset="0.2" stopColor="#2A6C95" />
                    <stop offset="0.27" stopColor="#254E83" />
                    <stop offset="0.36" stopColor="#203674" />
                    <stop offset="0.45" stopColor="#1C2469" />
                    <stop offset="0.56" stopColor="#1A1761" />
                    <stop offset="0.7" stopColor="#180F5C" />
                    <stop offset="1" stopColor="#180D5B" />
                </radialGradient>
                <radialGradient
                    id="paint41_radial_9599_45580"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(-579.939 -4202.79) scale(0.463907 0.463367)"
                >
                    <stop stopColor="#42E8E0" />
                    <stop offset="1" stopColor="#42E8E0" />
                </radialGradient>
                <radialGradient
                    id="paint42_radial_9599_45580"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(2369.54 1141.2) scale(93.173)"
                >
                    <stop offset="0.01" stopColor="#CFF9FC" />
                    <stop offset="0.41" stopColor="#96F2F1" stopOpacity="0.3" />
                    <stop offset="1" stopColor="#42E8E0" stopOpacity="0" />
                </radialGradient>
                <linearGradient
                    id="paint43_linear_9599_45580"
                    x1="371.459"
                    y1="5881.58"
                    x2="371.459"
                    y2="11789.1"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#A7F9EF" />
                    <stop offset="0.29" stopColor="#42E8E0" />
                    <stop
                        offset="0.82"
                        stopColor="#42E8E0"
                        stopOpacity="0.25"
                    />
                    <stop offset="1" stopColor="#42E8E0" stopOpacity="0" />
                </linearGradient>
                <radialGradient
                    id="paint44_radial_9599_45580"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(21642.1 51434.7) rotate(-30.203) scale(93.5636)"
                >
                    <stop offset="0.01" stopColor="#CFF9FC" />
                    <stop offset="0.41" stopColor="#96F2F1" stopOpacity="0.3" />
                    <stop offset="1" stopColor="#42E8E0" stopOpacity="0" />
                </radialGradient>
                <linearGradient
                    id="paint45_linear_9599_45580"
                    x1="358.474"
                    y1="5175.61"
                    x2="358.474"
                    y2="11083.2"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#A7F9EF" />
                    <stop offset="0.29" stopColor="#42E8E0" />
                    <stop
                        offset="0.82"
                        stopColor="#42E8E0"
                        stopOpacity="0.25"
                    />
                    <stop offset="1" stopColor="#42E8E0" stopOpacity="0" />
                </linearGradient>
                <radialGradient
                    id="paint46_radial_9599_45580"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(72336.3 28533.7) rotate(180) scale(93.173)"
                >
                    <stop offset="0.01" stopColor="#CFF9FC" />
                    <stop offset="0.41" stopColor="#96F2F1" stopOpacity="0.3" />
                    <stop offset="1" stopColor="#42E8E0" stopOpacity="0" />
                </radialGradient>
                <linearGradient
                    id="paint47_linear_9599_45580"
                    x1="28.821"
                    y1="261.746"
                    x2="28.821"
                    y2="126.313"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#A7F9EF" />
                    <stop offset="0.29" stopColor="#42E8E0" />
                    <stop
                        offset="0.82"
                        stopColor="#42E8E0"
                        stopOpacity="0.25"
                    />
                    <stop offset="1" stopColor="#42E8E0" stopOpacity="0" />
                </linearGradient>
                <radialGradient
                    id="paint48_radial_9599_45580"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(53355.6 -21393.8) rotate(149.797) scale(93.5636)"
                >
                    <stop offset="0.01" stopColor="#CFF9FC" />
                    <stop offset="0.41" stopColor="#96F2F1" stopOpacity="0.3" />
                    <stop offset="1" stopColor="#42E8E0" stopOpacity="0" />
                </radialGradient>
                <linearGradient
                    id="paint49_linear_9599_45580"
                    x1="36.1383"
                    y1="275.578"
                    x2="36.1383"
                    y2="140.145"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#A7F9EF" />
                    <stop offset="0.29" stopColor="#42E8E0" />
                    <stop
                        offset="0.82"
                        stopColor="#42E8E0"
                        stopOpacity="0.25"
                    />
                    <stop offset="1" stopColor="#42E8E0" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                    id="paint50_linear_9599_45580"
                    x1="187.746"
                    y1="64.1171"
                    x2="58.5394"
                    y2="274.391"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="0.06" stopColor="#43E8E1" />
                    <stop offset="0.45" stopColor="#3A8FA2" />
                    <stop offset="0.84" stopColor="#387E97" />
                    <stop offset="1" stopColor="#385397" />
                </linearGradient>
                <linearGradient
                    id="paint51_linear_9599_45580"
                    x1="235.181"
                    y1="63.7286"
                    x2="87.5848"
                    y2="287.256"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.01" stopColor="white" />
                    <stop offset="0.17" stopColor="#AAF4F1" />
                    <stop offset="0.36" stopColor="#BDF7F4" />
                    <stop offset="0.38" stopColor="#E6FCFB" />
                    <stop offset="0.5" stopColor="#42E8E0" />
                    <stop offset="0.63" stopColor="#42E8E0" />
                    <stop offset="0.84" stopColor="#42E8E0" />
                    <stop offset="1" stopColor="#DAFAF9" />
                </linearGradient>
                <linearGradient
                    id="paint52_linear_9599_45580"
                    x1="198.177"
                    y1="106.669"
                    x2="80.6527"
                    y2="320.954"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.01" stopColor="white" />
                    <stop offset="1" stopColor="#42E8E0" />
                </linearGradient>
                <linearGradient
                    id="paint53_linear_9599_45580"
                    x1="135.755"
                    y1="262.709"
                    x2="253.761"
                    y2="51.2156"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.06" stopColor="#43E8E1" />
                    <stop offset="0.64" stopColor="#3A8FA2" />
                    <stop offset="1" stopColor="#387E97" />
                </linearGradient>
                <linearGradient
                    id="paint54_linear_9599_45580"
                    x1="152.764"
                    y1="232.862"
                    x2="142.43"
                    y2="210.525"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" stopOpacity="0" />
                    <stop offset="1" stopColor="#180D5B" />
                </linearGradient>
                <linearGradient
                    id="paint55_linear_9599_45580"
                    x1="4820.69"
                    y1="3325.2"
                    x2="1190.27"
                    y2="8367.27"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.06" stopColor="#43E8E1" />
                    <stop offset="0.64" stopColor="#3A8FA2" />
                    <stop offset="1" stopColor="#387E97" />
                </linearGradient>
                <linearGradient
                    id="paint56_linear_9599_45580"
                    x1="165.923"
                    y1="146.917"
                    x2="128.855"
                    y2="313.223"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#43E8E1" />
                    <stop offset="1" stopColor="#2A54D3" />
                </linearGradient>
                <linearGradient
                    id="paint57_linear_9599_45580"
                    x1="180.332"
                    y1="128.624"
                    x2="163.195"
                    y2="205.551"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#2A54D3" />
                    <stop offset="1" stopColor="#43E8E1" />
                </linearGradient>
                <linearGradient
                    id="paint58_linear_9599_45580"
                    x1="139.189"
                    y1="137.642"
                    x2="201.321"
                    y2="227.695"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#43E8E1" />
                    <stop offset="1" stopColor="#2A54D3" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                    id="paint59_linear_9599_45580"
                    x1="133.284"
                    y1="185.685"
                    x2="151.609"
                    y2="194.222"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#2E6DD5" />
                    <stop offset="0.85" stopColor="#43E8E1" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <image id="image0_9599_45580" width="2" height="3" />
            </defs>
        </svg>
    );
};

export default token;
