import React from 'react';
import type { Size } from '../../types';

const binanceLogo = (width: Size = '120', height: Size = '160') => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0,0,120,160"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M29.168 99.3139C32.9018 95.5571 36.6357 91.7039 40.3695 87.9471C40.4652 88.0434 40.561 88.1397 40.6567 88.2361C46.9755 94.5938 53.1986 100.855 59.5174 107.213C59.8046 107.502 59.7089 107.502 60.0919 107.213C66.4107 100.855 72.6337 94.5938 78.9526 88.2361C79.0483 88.1397 79.0483 88.0434 79.144 87.9471C82.9736 91.8002 86.7075 95.5571 90.4413 99.3139C90.4413 99.3139 90.3456 99.4102 90.2498 99.5066C80.1972 109.621 70.1445 119.736 60.0919 129.85C59.9004 130.043 59.9004 130.043 59.6132 129.85C49.5605 119.736 39.5078 109.621 29.4552 99.5066C29.2637 99.4102 29.168 99.4102 29.168 99.3139Z"
                fill="url(#paint0_linear_30_8698)"
                fillOpacity="0.7"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M40.3693 86.5276L59.8045 106.082L78.183 87.5907C78.2238 87.5122 78.3053 87.3714 78.4408 87.2351L79.1439 86.5276L91.8518 99.3138L91.1443 100.026L91.0779 99.9587C91.1443 100.026 91.1443 100.026 91.1443 100.026L60.7948 130.562C60.7924 130.565 60.7895 130.568 60.7861 130.571C60.751 130.607 60.6678 130.691 60.5789 130.759C60.4576 130.852 60.2213 131 59.8883 131C59.599 131 59.3831 130.884 59.3021 130.839C59.2204 130.794 59.1335 130.736 59.0759 130.697L58.9797 130.632L28.8833 100.351C28.8562 100.339 28.8243 100.325 28.7953 100.31C28.7311 100.278 28.5574 100.187 28.409 100C28.2319 99.7775 28.1719 99.5261 28.1719 99.3139V98.8965L28.4647 98.6019C30.329 96.7261 32.1937 94.8259 34.061 92.923L34.0621 92.9219C35.9282 91.0202 37.797 89.1158 39.6662 87.2351L40.3693 86.5276ZM30.6258 99.2651L59.8523 128.672L89.0304 99.3139L79.1917 89.4146L60.7447 107.975L60.688 108.018C60.678 108.026 60.6488 108.048 60.6145 108.075C60.5576 108.119 60.4864 108.174 60.4653 108.189C60.4644 108.19 60.4628 108.191 60.4607 108.193C60.4184 108.225 60.146 108.435 59.7686 108.435C59.3241 108.435 59.0441 108.159 58.991 108.107C58.9889 108.105 58.9871 108.103 58.9858 108.102C58.9502 108.067 58.9143 108.029 58.8934 108.007L58.8882 108.002C58.8629 107.975 58.8405 107.951 58.8142 107.925L40.3699 89.367C38.7396 91.0122 37.1089 92.674 35.4762 94.3379L35.4749 94.3392C33.86 95.9849 32.2431 97.6325 30.6258 99.2651ZM59.2962 106.562C59.2927 106.564 59.2933 106.564 59.2975 106.561C59.2971 106.561 59.2966 106.561 59.2962 106.562Z"
                fill="white"
            />
            <path
                d="M40.3695 71.9566C36.6357 68.1997 32.9018 64.4429 29.168 60.6861C29.168 60.5898 29.2637 60.5898 29.3594 60.4934C39.4121 50.3789 49.4648 40.2643 59.5174 30.1498C59.7089 29.9571 59.8046 29.9571 59.9961 30.1498C70.0488 40.2643 80.1014 50.3789 90.1541 60.4934C90.2498 60.5898 90.2498 60.5898 90.3456 60.6861C86.6117 64.4429 82.8779 68.1997 79.144 71.9566C79.144 71.9566 79.0483 71.8603 78.9526 71.7639C72.6337 65.4062 66.4107 59.1448 60.0919 52.7871C59.8046 52.4981 59.9004 52.4981 59.5174 52.7871C53.1986 59.0485 46.8798 65.4062 40.6567 71.6676C40.561 71.7639 40.4652 71.8603 40.3695 71.9566Z"
                fill="url(#paint1_linear_30_8698)"
                fillOpacity="0.7"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M59.7567 31.3283L88.9348 60.6861L79.144 70.5372L60.7949 52.0751C60.7686 52.0487 60.7462 52.025 60.7208 51.9982C60.7011 51.9773 60.662 51.9356 60.6233 51.8982C60.6219 51.8969 60.6202 51.8952 60.6181 51.8931C60.5649 51.8408 60.285 51.5651 59.8405 51.5651C59.4631 51.5651 59.1906 51.7746 59.1483 51.8072C59.1462 51.8088 59.1447 51.8099 59.1438 51.8106C59.1227 51.8261 59.0515 51.8812 58.9945 51.9253C58.9602 51.9518 58.9311 51.9744 58.921 51.982L58.8674 52.0224L58.8197 52.0697C52.6385 58.1947 46.4578 64.4114 40.3695 70.5372L30.5786 60.6861L59.7567 31.3283ZM78.4409 72.6686L79.144 73.376L91.7562 60.6861L91.0021 59.9273L91.0013 59.9265L90.9998 59.925L90.9975 59.9227L90.9925 59.9176C90.9957 59.9209 90.9892 59.9143 90.9925 59.9176L90.9461 59.871L90.9439 59.8687L90.9334 59.8581L90.931 59.8557L90.9297 59.8545L90.8915 59.816L60.6936 29.4321C60.5949 29.3327 60.265 29 59.7567 29C59.2484 29 58.9185 29.3327 58.8199 29.4321L28.7022 59.7353C28.6947 59.7403 28.6862 59.746 28.6766 59.7525C28.6294 59.7841 28.4886 59.8797 28.3693 60.0512C28.2304 60.2509 28.172 60.475 28.172 60.6861V61.1034L40.3695 73.376L41.3598 72.3796C47.4475 66.2544 53.6237 60.0401 59.8015 53.9144L78.4409 72.6686ZM60.3129 53.4384C60.3164 53.4358 60.3158 53.4364 60.3116 53.4394C60.312 53.4391 60.3124 53.4387 60.3129 53.4384Z"
                fill="white"
            />
            <path
                d="M59.7084 91.4151C55.9746 87.6582 52.145 83.8051 48.4111 80.0482C52.145 76.2914 55.9746 72.4382 59.8041 68.5851C63.538 72.3419 67.3676 76.1951 71.1014 79.9519C67.3676 83.8051 63.538 87.5619 59.7084 91.4151Z"
                fill="url(#paint2_linear_30_8698)"
                fillOpacity="0.7"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M59.804 67.1656L72.5031 79.9429L71.8132 80.6548C69.3218 83.2258 66.783 85.7589 64.2369 88.2994C62.9618 89.5715 61.685 90.8455 60.4113 92.127L59.7082 92.8344L47.0002 80.0482L59.804 67.1656ZM49.8217 80.0482L59.7087 89.9961C60.7596 88.9417 61.8081 87.8956 62.853 86.8531C65.1562 84.5551 67.442 82.2744 69.6984 79.9598L59.804 70.0045L49.8217 80.0482Z"
                fill="white"
            />
            <path
                d="M32.4228 79.952C32.4228 80.0483 32.3271 80.0483 32.3271 80.0483C32.3271 80.1446 32.2314 80.1446 32.2314 80.1446C28.689 83.7088 25.0509 87.3693 21.5085 90.9335C21.2213 91.2225 21.2213 91.2225 20.9341 90.9335C17.3917 87.3693 13.7536 83.7088 10.2113 80.1446C9.92404 79.8556 9.92404 79.8556 10.2113 79.663C13.7536 76.0988 17.3917 72.4383 20.9341 68.8741C21.1256 68.6815 21.1256 68.6815 21.4128 68.8741C24.9552 72.4383 28.5933 76.0988 32.1356 79.663C32.2314 79.7593 32.3271 79.8556 32.4228 79.952Z"
                fill="url(#paint3_linear_30_8698)"
                fillOpacity="0.7"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.1735 70.0527L31.0122 79.952L21.2214 89.803L11.3827 79.9038L21.1735 70.0527ZM32.8053 80.9867L22.2117 91.6454C22.2072 91.65 22.2024 91.6548 22.1973 91.6599C22.1407 91.7171 22.0493 91.8093 21.959 91.882C21.8661 91.9568 21.6053 92.1555 21.2214 92.1555C20.8375 92.1555 20.5767 91.9568 20.4838 91.882C20.3934 91.8093 20.3021 91.7171 20.2455 91.6599C20.2404 91.6548 20.2356 91.65 20.2311 91.6454L9.50824 80.8566C9.50371 80.852 9.49893 80.8472 9.49394 80.8422C9.4365 80.7845 9.35088 80.6986 9.28352 80.6167C9.28234 80.6152 9.28103 80.6137 9.27959 80.6119C9.22635 80.5476 9 80.2739 9 79.8677C9 79.3814 9.30825 79.0978 9.39515 79.0213C9.4543 78.9692 9.52226 78.9197 9.57697 78.8819L20.2311 68.1622C20.2335 68.1597 20.2364 68.1567 20.2398 68.1533C20.2749 68.1177 20.3581 68.0333 20.447 67.9652C20.5682 67.8723 20.8046 67.7244 21.1376 67.7244C21.4269 67.7244 21.6428 67.8399 21.7238 67.8849C21.8055 67.9303 21.8923 67.9887 21.95 68.0274C21.9549 68.0307 21.9596 68.0339 21.9641 68.0369L22.0462 68.0919L33.4189 79.5346V79.952C33.4189 80.1526 33.3661 80.3699 33.2358 80.5665C33.1795 80.6515 33.1163 80.7212 33.052 80.7782C32.9723 80.8693 32.8859 80.937 32.8053 80.9867Z"
                fill="white"
            />
            <path
                d="M109.589 79.952C109.589 80.0483 109.494 80.0483 109.494 80.0483C109.494 80.1446 109.398 80.1446 109.398 80.1446C105.855 83.7088 102.217 87.3693 98.675 90.9335C98.3878 91.2225 98.3878 91.2225 98.1963 90.9335C94.6539 87.3693 91.0158 83.7088 87.4734 80.1446C87.282 79.952 87.282 79.952 87.4734 79.663C91.1115 76.0025 94.6539 72.4383 98.292 68.7778C98.4835 68.5851 98.5792 68.5851 98.7707 68.7778C102.409 72.4383 105.951 76.0025 109.589 79.663C109.398 79.8556 109.494 79.8556 109.589 79.952Z"
                fill="url(#paint4_linear_30_8698)"
                fillOpacity="0.7"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M98.5312 69.9564L108.322 79.8075L98.4355 89.7549L88.6446 79.9038L98.5312 69.9564ZM109.971 80.9867L99.3779 91.6455C99.3734 91.65 99.3686 91.6548 99.3637 91.6598C99.3063 91.7176 99.2209 91.8038 99.1395 91.8715C99.1381 91.8727 99.1366 91.874 99.1349 91.8754C99.0707 91.9291 98.8002 92.1555 98.3996 92.1555C97.9204 92.1555 97.6398 91.8477 97.5628 91.7592C97.5111 91.6997 97.4619 91.6313 97.4242 91.5762L86.7702 80.8566C86.7677 80.8542 86.7648 80.8512 86.7614 80.8478C86.7261 80.8126 86.6421 80.7288 86.5743 80.6392C86.482 80.5173 86.3337 80.278 86.3337 79.9399C86.3337 79.6464 86.4495 79.4277 86.4942 79.3463C86.5394 79.2639 86.5975 79.1763 86.636 79.1184C86.6393 79.1134 86.6424 79.1087 86.6454 79.1041L86.7003 79.0213L97.5887 68.0658C97.5905 68.0641 97.5924 68.0622 97.5944 68.0602C97.693 67.9607 98.0229 67.6281 98.5312 67.6281C99.0395 67.6281 99.3694 67.9607 99.468 68.0602C99.47 68.0622 99.4719 68.0641 99.4737 68.0658L111 79.663L110.577 80.0886C110.558 80.2487 110.503 80.4138 110.402 80.5665C110.346 80.6515 110.283 80.7213 110.218 80.7783C110.138 80.8693 110.052 80.937 109.971 80.9867Z"
                fill="white"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_30_8698"
                    x1="59.8046"
                    y1="87.9471"
                    x2="59.8046"
                    y2="129.995"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="white" stopOpacity="0.3" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_30_8698"
                    x1="59.7568"
                    y1="30.0053"
                    x2="59.7568"
                    y2="71.9566"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="white" stopOpacity="0.3" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_30_8698"
                    x1="59.7563"
                    y1="68.5851"
                    x2="59.7563"
                    y2="91.4151"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="white" stopOpacity="0.3" />
                </linearGradient>
                <linearGradient
                    id="paint3_linear_30_8698"
                    x1="21.2093"
                    y1="68.7296"
                    x2="21.2093"
                    y2="91.1502"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="white" stopOpacity="0.3" />
                </linearGradient>
                <linearGradient
                    id="paint4_linear_30_8698"
                    x1="98.4596"
                    y1="68.6333"
                    x2="98.4596"
                    y2="91.1502"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="white" stopOpacity="0.3" />
                </linearGradient>
            </defs>
        </svg>
    );
};

export default binanceLogo;
