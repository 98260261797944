import React from 'react';
import type { Size } from '../../types';

const marketplace = (width: Size = '292', height: Size = '398') => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 292 398"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M279.726 225.434C283.044 198.214 278.216 170.62 265.852 146.143C253.489 121.666 234.145 101.405 210.267 87.9211C186.389 74.4371 159.049 68.336 131.704 70.3893C104.359 72.4426 78.2366 82.558 56.6401 99.4565C35.0435 116.355 18.9425 139.278 10.3728 165.327C1.80324 191.375 1.14988 219.38 8.49537 245.8C15.8409 272.22 30.8553 295.869 51.6404 313.756C72.4254 331.644 98.0476 342.966 125.267 346.293C143.345 348.502 161.681 347.128 179.228 342.249C196.775 337.371 213.189 329.083 227.533 317.86C241.876 306.637 253.868 292.698 262.824 276.839C271.779 260.98 277.522 243.513 279.726 225.434Z"
                fill="#ED1818"
            />
            <path
                d="M238.064 323.559L84.3468 337.103C77.834 337.68 71.3507 335.712 66.258 331.612C61.1653 327.511 57.8594 321.597 57.0343 315.111L31.7534 116.056C31.3693 112.608 31.7403 109.119 32.8408 105.829C33.9412 102.54 35.7446 99.5293 38.1258 97.007C40.5069 94.4848 43.4086 92.5111 46.6294 91.2233C49.8501 89.9355 53.3126 89.3643 56.7764 89.5495L209.429 97.9012C214.882 98.198 220.068 100.359 224.118 104.021C228.168 107.684 230.837 112.627 231.679 118.023L260.701 294.248C261.269 297.676 261.125 301.185 260.276 304.556C259.428 307.926 257.893 311.085 255.769 313.836C253.645 316.587 250.976 318.87 247.93 320.543C244.883 322.216 241.525 323.243 238.064 323.559Z"
                fill="url(#paint0_linear_10025_65882)"
            />
            <path
                d="M238.193 322.301L82.7992 336.844C76.596 337.104 70.525 335.002 65.8097 330.963C61.0945 326.924 58.0855 321.248 57.3893 315.078L31.7859 114.056C31.257 110.056 31.907 105.988 33.6562 102.351C32.5921 103.705 27.9809 82.4229 27.9809 82.4229L24.4338 81.9714L28.4968 120.248L54.2937 320.173C54.9899 326.343 57.9989 332.019 62.7141 336.058C67.4294 340.097 73.5004 342.199 79.7036 341.939L235.098 327.396C239.604 327.208 243.972 325.781 247.721 323.273C251.47 320.764 254.455 317.27 256.348 313.176C254.153 315.91 251.396 318.14 248.264 319.715C245.131 321.289 241.697 322.171 238.193 322.301Z"
                fill="url(#paint1_linear_10025_65882)"
            />
            <path
                d="M35.494 144.658L61.2586 198.863L228.584 196.284L236.129 145.851L248.254 128.18L228.197 113.25L52.5844 109.219L35.494 144.658Z"
                fill="url(#paint2_linear_10025_65882)"
            />
            <path
                d="M266.183 340.23L46.8125 352L14.7277 112.057L275.889 122.763L266.183 340.23Z"
                fill="url(#paint3_radial_10025_65882)"
            />
            <path
                d="M130.394 127.567C97.6968 127.987 74.7053 159.072 79.6067 195.284C82.8313 221.081 100.309 245.653 125.106 254.714C133.415 257.441 142.192 258.451 150.903 257.68C159.505 256.438 167.651 253.028 174.572 247.77C181.494 242.513 186.964 235.581 190.469 227.627C191.756 224.852 192.834 221.985 193.693 219.05C194.841 215.209 195.619 211.268 196.015 207.28C200.626 166.94 167.929 126.536 130.394 127.567Z"
                fill="url(#paint4_linear_10025_65882)"
            />
            <path
                d="M182.955 152.59L177.183 155.009L175.861 143.174L181.601 140.724L182.955 152.59Z"
                fill="#3F0F56"
            />
            <path
                d="M181.601 140.724L175.861 143.174L170.444 143.271L176.216 140.82L181.601 140.724Z"
                fill="#3C0E52"
            />
            <path
                d="M176.216 140.821L170.444 143.271L169.831 137.79L175.603 135.371L176.216 140.821Z"
                fill="#3F0F56"
            />
            <path
                d="M190.405 180.193L184.632 182.611L183.343 171.067L189.115 168.649L190.405 180.193Z"
                fill="#3F0F56"
            />
            <path
                d="M184.761 168.713L178.989 171.164L177.183 155.009L182.956 152.59L184.761 168.713Z"
                fill="#3F0F56"
            />
            <path
                d="M189.115 168.649L183.343 171.067L178.99 171.164L184.762 168.713L189.115 168.649Z"
                fill="#3C0E52"
            />
            <path
                d="M182.956 152.59L177.184 155.009L171.766 155.106L177.538 152.687L182.956 152.59Z"
                fill="#255B64"
            />
            <path
                d="M178.183 158.427L172.411 160.878L171.766 155.106L177.538 152.687L178.183 158.427Z"
                fill="#020D1D"
            />
            <path
                d="M190.405 180.193L184.633 182.611L180.279 182.676L186.051 180.257L190.405 180.193Z"
                fill="#020C1B"
            />
            <path
                d="M179.312 168.81L173.572 171.261L172.411 160.877L178.183 158.427L179.312 168.81Z"
                fill="#020D1D"
            />
            <path
                d="M178.183 158.427L172.411 160.877L167.8 160.942L173.54 158.524L178.183 158.427Z"
                fill="#255B64"
            />
            <path
                d="M178.2 158.394L178.168 158.398L179.33 168.781L179.362 168.777L178.2 158.394Z"
                fill="url(#paint5_linear_10025_65882)"
            />
            <path
                d="M179.312 168.81L173.572 171.261L172.411 160.877L178.151 158.459L179.312 168.81Z"
                fill="#3F0F56"
            />
            <path
                d="M179.332 168.831L173.566 171.259L173.579 171.289L179.344 168.861L179.332 168.831Z"
                fill="#3C0E52"
            />
            <path
                d="M174.217 164.586L168.477 167.037L167.8 160.942L173.54 158.524L174.217 164.586Z"
                fill="#638487"
            />
            <path
                d="M179.312 168.81L173.572 171.261L168.155 171.358L173.927 168.907L179.312 168.81Z"
                fill="#5E7D80"
            />
            <path
                d="M173.927 168.907L168.155 171.357L163.511 171.422L169.283 169.003L173.927 168.907Z"
                fill="#5E7D80"
            />
            <path
                d="M190.437 219.501L184.665 221.952L180.279 182.676L186.051 180.258L190.437 219.501Z"
                fill="#3F0F56"
            />
            <path
                d="M181.279 186.126L175.507 188.545L170.089 188.642L175.862 186.223L181.279 186.126Z"
                fill="#020C1B"
            />
            <path
                d="M175.861 186.223L170.089 188.642L169.444 182.87L175.216 180.451L175.861 186.223Z"
                fill="#020D1D"
            />
            <path
                d="M170.573 180.515L164.801 182.966L164.221 177.484L169.96 175.066L170.573 180.515Z"
                fill="#020D1D"
            />
            <path
                d="M175.217 180.451L169.445 182.87L164.801 182.966L170.573 180.516L175.217 180.451Z"
                fill="#020C1B"
            />
            <path
                d="M185.019 219.598L179.279 222.016L175.507 188.545L181.279 186.126L185.019 219.598Z"
                fill="#020D1D"
            />
            <path
                d="M190.437 219.501L184.665 221.952L179.28 222.016L185.019 219.598L190.437 219.501Z"
                fill="#020C1B"
            />
            <path
                d="M185.019 219.598L179.279 222.016L175.507 188.545L181.247 186.126L185.019 219.598Z"
                fill="#3F0F56"
            />
            <path
                d="M185.664 225.37L179.924 227.788L179.28 222.016L185.019 219.598L185.664 225.37Z"
                fill="#3F0F56"
            />
            <path
                d="M174.217 164.586L168.477 167.037L152.741 167.295L158.513 164.876L174.217 164.586Z"
                fill="#255B64"
            />
            <path
                d="M158.513 164.876L152.741 167.295L152.064 161.233L157.836 158.814L158.513 164.876Z"
                fill="#27606A"
            />
            <path
                d="M179.28 214.503L173.54 216.921L172.83 210.859L178.602 208.44L179.28 214.503Z"
                fill="#638487"
            />
            <path
                d="M169.283 169.004L163.511 171.422L153.74 171.583L159.48 169.165L169.283 169.004Z"
                fill="#5E7D80"
            />
            <path
                d="M185.02 219.598L179.248 222.016L173.83 222.145L179.602 219.695L185.02 219.598Z"
                fill="#5E7D80"
            />
            <path
                d="M179.667 220.146L173.895 222.564L173.83 222.145L179.602 219.694L179.667 220.146Z"
                fill="#020D1D"
            />
            <path
                d="M179.667 220.146L173.895 222.564H173.637L179.409 220.146H179.667Z"
                fill="#5E7D80"
            />
            <path
                d="M169.961 175.066L164.221 177.484L154.418 177.678L160.19 175.259L169.961 175.066Z"
                fill="#020C1B"
            />
            <path
                d="M185.664 225.37L179.924 227.788L174.765 227.885L180.505 225.467L185.664 225.37Z"
                fill="#020C1B"
            />
            <path
                d="M180.215 225.176L174.443 227.627L173.894 222.564L179.666 220.146L180.215 225.176Z"
                fill="#020D1D"
            />
            <path
                d="M179.409 220.146H179.667L180.215 225.176H179.989L179.409 220.146Z"
                fill="url(#paint6_linear_10025_65882)"
            />
            <path
                d="M179.989 225.176L174.217 227.627L173.637 222.564L179.409 220.146L179.989 225.176Z"
                fill="#3F0F56"
            />
            <path
                d="M180.215 225.176L174.443 227.627H174.217L179.989 225.176H180.215Z"
                fill="#3C0E52"
            />
            <path
                d="M160.803 180.709L155.031 183.128L154.418 177.678L160.19 175.26L160.803 180.709Z"
                fill="#638487"
            />
            <path
                d="M181.15 231.239L175.41 233.657L174.765 227.885L180.505 225.467L181.15 231.239Z"
                fill="#3F0F56"
            />
            <path
                d="M159.481 169.165L153.741 171.583L149.13 171.68L154.902 169.262L159.481 169.165Z"
                fill="#5E7D80"
            />
            <path
                d="M168.735 203.152L162.963 205.571L161.093 188.803L166.865 186.384L168.735 203.152Z"
                fill="#638487"
            />
            <path
                d="M166.865 186.384L161.093 188.803L155.708 188.899L161.447 186.481L166.865 186.384Z"
                fill="#5E7D80"
            />
            <path
                d="M160.803 180.709L155.031 183.127L150.42 183.224L156.192 180.773L160.803 180.709Z"
                fill="#020C1B"
            />
            <path
                d="M175.603 135.371L169.831 137.79L129.911 138.499L135.65 136.081L175.603 135.371Z"
                fill="#3C0E52"
            />
            <path
                d="M156.837 186.546L151.064 188.996L150.42 183.224L156.192 180.774L156.837 186.546Z"
                fill="#638487"
            />
            <path
                d="M163.35 203.217L157.578 205.668L155.708 188.9L161.447 186.481L163.35 203.217Z"
                fill="#3E787A"
            />
            <path
                d="M163.35 203.217L161.447 186.481L166.865 186.384L168.735 203.152L163.35 203.217Z"
                fill="url(#paint7_linear_10025_65882)"
            />
            <path
                d="M168.735 203.152L162.963 205.571L157.578 205.668L163.35 203.217L168.735 203.152Z"
                fill="#3B7274"
            />
            <path
                d="M136.231 141.272L130.491 143.69L129.911 138.499L135.65 136.08L136.231 141.272Z"
                fill="#27606A"
            />
            <path
                d="M154.901 169.262L149.129 171.68L138.553 171.841L144.293 169.423L154.901 169.262Z"
                fill="#5E7D80"
            />
            <path
                d="M136.231 141.272L130.491 143.69L125.622 143.787L131.362 141.336L136.231 141.272Z"
                fill="#3C0E52"
            />
            <path
                d="M157.836 158.814L152.064 161.233L132.49 161.555L138.263 159.137L157.836 158.814Z"
                fill="#255B64"
            />
            <path
                d="M132.039 147.399L126.299 149.817L125.622 143.787L131.362 141.337L132.039 147.399Z"
                fill="#27606A"
            />
            <path
                d="M144.293 169.423L138.553 171.841L133.91 171.938L139.682 169.52L144.293 169.423Z"
                fill="#5E7D80"
            />
            <path
                d="M156.836 186.546L151.064 188.996L140.487 189.19L146.227 186.739L156.836 186.546Z"
                fill="#020C1B"
            />
            <path
                d="M146.227 186.739L140.488 189.19L139.843 183.386L145.582 180.967L146.227 186.739Z"
                fill="#020D1D"
            />
            <path
                d="M138.263 159.136L132.491 161.555L128.105 161.652L133.877 159.201L138.263 159.136Z"
                fill="#255B64"
            />
            <path
                d="M133.877 159.201L128.105 161.652H127.621L133.361 159.233L133.877 159.201Z"
                fill="#255B64"
            />
            <path
                d="M179.989 225.176L174.217 227.627L155.417 227.949L161.19 225.531L179.989 225.176Z"
                fill="#5E7D80"
            />
            <path
                d="M178.603 208.44L172.83 210.859L148.388 211.278L154.16 208.86L178.603 208.44Z"
                fill="#5E7D80"
            />
            <path
                d="M132.039 147.399L126.299 149.817L121.366 149.914L127.138 147.463L132.039 147.399Z"
                fill="#3C0E52"
            />
            <path
                d="M145.582 180.967L139.843 183.386L135.199 183.482L140.971 181.032L145.582 180.967Z"
                fill="#020C1B"
            />
            <path
                d="M140.971 181.031L135.199 183.482L134.587 178.032L140.359 175.582L140.971 181.031Z"
                fill="#020D1D"
            />
            <path
                d="M181.15 231.239L175.41 233.657L156.095 234.012L161.867 231.561L181.15 231.239Z"
                fill="#020C1B"
            />
            <path
                d="M179.28 214.503L173.54 216.921L149.065 217.373L154.837 214.922L179.28 214.503Z"
                fill="#020C1B"
            />
            <path
                d="M154.837 214.922L154.16 208.86L178.602 208.44L179.279 214.503L154.837 214.922Z"
                fill="#041735"
            />
            <path
                d="M154.837 214.922L149.065 217.373L148.388 211.278L154.16 208.86L154.837 214.922Z"
                fill="#020D1D"
            />
            <path
                d="M135.038 169.584L129.266 172.035L128.105 161.652L133.877 159.201L135.038 169.584Z"
                fill="#638487"
            />
            <path
                d="M174.249 164.586L173.54 158.395H178.151L179.312 168.778L135.038 169.552L133.877 159.169L157.836 158.75L158.513 164.844L174.249 164.586Z"
                fill="url(#paint8_linear_10025_65882)"
            />
            <path
                d="M139.681 169.52L133.909 171.938L129.266 172.035L135.038 169.584L139.681 169.52Z"
                fill="#5E7D80"
            />
            <path
                d="M127.783 153.268L122.011 155.686L121.366 149.914L127.138 147.463L127.783 153.268Z"
                fill="#27606A"
            />
            <path
                d="M140.358 175.582L134.586 178.032L129.943 178.097L135.715 175.679L140.358 175.582Z"
                fill="#020C1B"
            />
            <path
                d="M133.361 159.233L127.621 161.651L122.72 161.748L128.492 159.297L133.361 159.233Z"
                fill="#255B64"
            />
            <path
                d="M161.189 225.531L155.417 227.949L150.258 228.046L156.03 225.595L161.189 225.531Z"
                fill="#5E7D80"
            />
            <path
                d="M156.03 225.595L150.258 228.046H150L155.772 225.595H156.03Z"
                fill="#5E7D80"
            />
            <path
                d="M136.36 181.483L130.62 183.902L129.943 178.097L135.715 175.679L136.36 181.483Z"
                fill="#638487"
            />
            <path
                d="M164.124 247.684L158.352 250.103L156.095 234.012L161.867 231.561L164.124 247.684Z"
                fill="#3F0F56"
            />
            <path
                d="M129.105 165.102L123.333 167.52L122.72 161.748L128.492 159.297L129.105 165.102Z"
                fill="#020D1D"
            />
            <path
                d="M164.124 247.684L158.352 250.103H157.933L163.705 247.652H164.124"
                fill="#020C1C"
            />
            <path
                d="M129.137 165.102L123.365 167.52L117.947 167.617L123.687 165.199L129.137 165.102Z"
                fill="#255B64"
            />
            <path
                d="M130.104 174.034L124.332 176.485L123.333 167.52L129.105 165.102L130.104 174.034Z"
                fill="#020D1D"
            />
            <path
                d="M130.298 175.775L124.526 178.194L124.332 176.485L130.104 174.034L130.298 175.775Z"
                fill="#020D1D"
            />
            <path
                d="M135.715 175.679L129.943 178.097L124.526 178.194L130.298 175.776L135.715 175.679Z"
                fill="#020C1B"
            />
            <path
                d="M127.783 153.268L122.011 155.686L111.982 155.88L117.754 153.429L127.783 153.268Z"
                fill="#3C0E52"
            />
            <path
                d="M124.235 170.068L118.496 172.519L117.947 167.617L123.687 165.199L124.235 170.068Z"
                fill="#3F0F56"
            />
            <path
                d="M129.653 169.971L123.881 172.422L118.496 172.518L124.236 170.068L129.653 169.971Z"
                fill="#3C0E52"
            />
            <path
                d="M124.687 174.131L118.947 176.549L118.496 172.519L124.236 170.068L124.687 174.131Z"
                fill="#020D1D"
            />
            <path
                d="M117.754 153.429L111.982 155.88L107.629 155.944L113.369 153.526L117.754 153.429Z"
                fill="#3C0E52"
            />
            <path
                d="M124.235 170.068L118.496 172.519L113.852 172.583L119.624 170.165L124.235 170.068Z"
                fill="#255B64"
            />
            <path
                d="M113.369 153.526L107.629 155.944H106.823L112.595 153.526H113.369Z"
                fill="#3C0E52"
            />
            <path
                d="M120.269 175.937L114.497 178.355L113.852 172.583L119.624 170.165L120.269 175.937Z"
                fill="#3F0F56"
            />
            <path
                d="M112.595 153.526L106.823 155.944L101.438 156.041L107.177 153.622L112.595 153.526Z"
                fill="#3C0E52"
            />
            <path
                d="M175.539 135.371L176.152 140.821H181.537L182.891 152.687H177.474L178.119 158.427H173.475L174.185 164.489L158.513 164.844L157.836 158.75L128.492 159.265L129.137 165.07H123.687L124.236 169.939H119.624L120.269 175.711H115.11L113.24 158.975H107.822L107.177 153.203L127.783 152.848L127.17 147.496H132.071L131.394 141.433H136.263L135.683 136.242L175.539 135.371Z"
                fill="url(#paint9_linear_10025_65882)"
            />
            <path
                d="M182.956 152.59L184.762 168.713H189.115L190.405 180.257H186.051L190.437 219.662H185.02L181.279 186.126H175.862L175.217 180.354H170.573L169.961 174.905H160.287L160.899 180.386H156.288L156.933 186.159L146.324 186.352L145.583 180.967H140.971L140.359 175.517L130.395 175.775C129.911 171.422 129.04 163.618 128.589 159.298H133.974L135.135 169.681L179.409 168.907C179.022 165.231 178.054 156.395 177.635 152.784L182.956 152.59Z"
                fill="#041735"
            />
            <path
                d="M136.36 181.483L130.62 183.901L125.203 183.998L130.975 181.58L136.36 181.483Z"
                fill="#3B7274"
            />
            <path
                d="M130.975 181.579L125.203 183.998L124.558 178.194L130.33 175.775L130.975 181.579Z"
                fill="#3E787A"
            />
            <path
                d="M130.975 181.58L130.33 175.776L135.715 175.679L136.36 181.483L130.975 181.58Z"
                fill="url(#paint10_linear_10025_65882)"
            />
            <path
                d="M179.602 219.662H185.019L185.664 225.434H180.505L181.15 231.239L161.802 231.561L164.059 247.684C162.286 247.91 160.48 248.039 158.706 248.103L155.708 225.531L180.15 225.08L179.602 220.049V219.662Z"
                fill="#041735"
            />
            <path
                d="M131.523 186.707L125.751 189.125L125.138 183.676L130.91 181.257L131.523 186.707Z"
                fill="#638487"
            />
            <path
                d="M136.683 186.61L130.91 189.061L125.751 189.125L131.523 186.707L136.683 186.61Z"
                fill="#5E7D80"
            />
            <path
                d="M129.653 169.971L124.235 170.068L123.687 165.199L129.105 165.102L129.653 169.971Z"
                fill="url(#paint11_linear_10025_65882)"
            />
            <path
                d="M130.104 174.034L130.298 175.775H124.881L124.236 169.971H129.653L130.104 174.034Z"
                fill="#041735"
            />
            <path
                d="M130.298 175.775L124.526 178.194L119.141 178.29L124.913 175.872L130.298 175.775Z"
                fill="#020C1B"
            />
            <path
                d="M124.913 175.872L119.141 178.291L118.947 176.549L124.687 174.131L124.913 175.872Z"
                fill="#020D1D"
            />
            <path
                d="M125.493 181.354L119.753 183.772L119.141 178.291L124.913 175.872L125.493 181.354Z"
                fill="#638487"
            />
            <path
                d="M130.911 181.257L125.139 183.676L119.753 183.772L125.493 181.354L130.911 181.257Z"
                fill="#5E7D80"
            />
            <path
                d="M120.269 175.937L119.624 170.165H124.236L124.881 175.969L120.269 175.937Z"
                fill="url(#paint12_linear_10025_65882)"
            />
            <path
                d="M124.913 175.872L119.141 178.291L114.497 178.355L120.269 175.937L124.913 175.872Z"
                fill="#3C0E52"
            />
            <path
                d="M126.783 192.866L121.043 195.316L119.786 184.03L125.526 181.612L126.783 192.866Z"
                fill="#020D1D"
            />
            <path
                d="M143.873 246.427L138.101 248.877L130.911 189.061L136.683 186.61L143.873 246.427Z"
                fill="#638487"
            />
            <path
                d="M131.974 192.769L126.202 195.22L121.043 195.316L126.783 192.866L131.974 192.769Z"
                fill="#020C1B"
            />
            <path
                d="M125.525 181.612L119.786 184.03L114.368 184.127L120.14 181.709L125.525 181.612Z"
                fill="#020C1B"
            />
            <path
                d="M120.269 175.937L114.497 178.355L109.338 178.452L115.11 176.034L120.269 175.937Z"
                fill="#255B64"
            />
            <path
                d="M120.14 181.709L114.368 184.127L113.723 178.387L119.495 175.969L120.14 181.709Z"
                fill="#020D1D"
            />
            <path
                d="M115.11 176.033L109.338 178.452L107.468 161.716L113.24 159.297L115.11 176.033Z"
                fill="#27606A"
            />
            <path
                d="M107.822 159.394L102.082 161.813L101.438 156.041L107.177 153.622L107.822 159.394Z"
                fill="#27606A"
            />
            <path
                d="M113.24 159.297L107.468 161.716L102.083 161.813L107.822 159.394L113.24 159.297Z"
                fill="#255B64"
            />
            <path
                d="M155.772 225.628L158.771 248.2C153.753 248.334 148.741 247.781 143.873 246.556L136.844 186.61H131.684L131.072 181.161H125.687L125.042 175.679H130.459L131.136 181.483H136.521L135.876 175.679H140.52L140.971 180.967H145.582L146.227 186.739L156.836 186.546L156.191 180.774H160.803L160.19 175.292H169.864L170.476 180.741H175.12L175.765 186.513H181.15L185.019 219.662H179.602V220.114H179.409L179.989 225.144L155.772 225.628ZM154.16 208.86L154.837 214.922L179.28 214.503L178.764 208.441L154.321 208.86H154.16ZM161.609 186.481L163.479 203.217H168.896L167.026 186.481H161.609Z"
                fill="url(#paint13_linear_10025_65882)"
            />
            <path
                d="M138.263 244.717L132.491 247.136L126.203 195.22L131.975 192.769L138.263 244.717Z"
                fill="#020D1D"
            />
            <path
                d="M131.523 186.707H136.682L143.873 246.555C141.971 246.072 140.1 245.491 138.23 244.814L131.974 192.898H126.815L125.525 181.644H120.14L119.495 175.872H124.88L125.525 181.354H130.91L131.523 186.707Z"
                fill="#041735"
            />
            <path
                d="M92.7634 185.288C87.862 149.043 110.853 117.99 143.551 117.571C181.118 116.475 213.815 156.911 209.204 197.251C208.816 201.238 208.049 205.179 206.914 209.021C206.06 211.958 204.982 214.826 203.69 217.598C200.177 225.55 194.705 232.48 187.785 237.742C180.866 243.003 172.725 246.424 164.124 247.684L161.867 231.561L181.214 231.238L180.569 225.434H185.729L185.019 219.662H190.437L186.051 180.257H190.404L189.115 168.713H184.761L181.537 140.724H176.152L175.539 135.371L135.586 136.08L136.167 141.272H131.297L131.975 147.334H127.073L127.718 153.106L107.113 153.461L107.758 159.233H113.175L115.045 175.969H119.431L120.076 181.741H125.461L126.751 192.994H131.91L138.166 244.911C113.433 235.785 96.0202 211.02 92.7634 185.288Z"
                fill="url(#paint14_linear_10025_65882)"
            />
            <path
                d="M138.23 244.717C95.7945 230.723 76.4791 170.842 105.339 135.822C109.954 130.13 115.776 125.533 122.385 122.366C128.993 119.198 136.223 117.538 143.551 117.507C136.291 117.728 129.166 119.523 122.669 122.769C116.171 126.015 110.456 130.634 105.92 136.306C77.5755 171.519 97.6648 228.627 138.166 244.685L138.23 244.717Z"
                fill="#D2EBF3"
            />
            <path
                d="M174.249 245.459L176.667 244.427H176.377L174.636 245.169L176.377 244.427L173.926 245.459H173.41L172.798 245.685L172.153 245.878C169.53 246.689 166.844 247.282 164.124 247.652L158.352 250.07C161.083 249.701 163.779 249.108 166.413 248.297L167.026 248.103L167.638 247.91L168.187 247.716C168.864 247.491 170.089 247.007 171.411 246.459L170.637 246.781H170.928L171.637 246.459L174.249 245.459Z"
                fill="#3E0F55"
            />
            <path
                d="M162.995 247.813H162.318H161.609H160.899H158.771H152.644H151.935H151.064C148.642 247.525 146.24 247.083 143.873 246.491C141.971 246.007 140.101 245.427 138.23 244.75C113.433 235.785 96.0202 211.02 92.7634 185.288C88.8616 156.524 102.663 130.663 125.009 121.408C99.2126 129.857 82.8316 156.105 87.0235 187.706C90.2482 213.503 107.726 238.075 132.523 247.136C134.362 247.813 136.234 248.395 138.134 248.877C140.501 249.466 142.902 249.908 145.324 250.199H146.195H147.066H148.968H155.16H155.869H156.578H157.256H157.965L163.737 247.749L162.995 247.813Z"
                fill="url(#paint15_linear_10025_65882)"
            />
            <path
                d="M218.878 314.272L90.7639 325.494C88.48 325.724 86.1958 325.06 84.3917 323.641C82.5875 322.221 81.404 320.158 81.0901 317.884L76.2209 283.703C76.0477 282.408 76.1508 281.091 76.5234 279.838C76.896 278.585 77.5296 277.426 78.3825 276.436C79.2355 275.446 80.2884 274.648 81.4721 274.094C82.6557 273.54 83.9432 273.243 85.2498 273.223L214.234 270.256C216.387 270.19 218.491 270.909 220.152 272.28C221.814 273.65 222.919 275.579 223.263 277.705L227.197 303.502C227.411 304.763 227.362 306.054 227.052 307.295C226.742 308.535 226.179 309.698 225.397 310.71C224.616 311.722 223.633 312.561 222.511 313.174C221.388 313.787 220.151 314.161 218.878 314.272Z"
                fill="url(#paint16_linear_10025_65882)"
            />
            <path
                d="M223.328 307.984L95.214 316.949C92.9171 317.119 90.6411 316.412 88.8456 314.969C87.05 313.527 85.8687 311.457 85.5401 309.177L80.7032 274.9C80.3831 273.34 80.4073 271.73 80.7744 270.181C81.1414 268.632 81.8423 267.182 82.8282 265.932C83.814 264.682 85.0609 263.662 86.4818 262.944C87.9026 262.227 89.4632 261.828 91.0542 261.776L218.62 263.872C220.798 263.849 222.911 264.618 224.566 266.035C226.22 267.453 227.304 269.422 227.616 271.578L231.55 297.601C231.741 298.83 231.675 300.085 231.358 301.287C231.04 302.489 230.478 303.613 229.706 304.588C228.934 305.563 227.969 306.368 226.871 306.952C225.774 307.536 224.567 307.888 223.328 307.984Z"
                fill="#28B287"
            />
            <path
                d="M226.359 304.921L98.1482 313.885C95.8562 314.057 93.5843 313.354 91.7896 311.918C89.9948 310.482 88.8103 308.42 88.4744 306.146L83.6375 271.869C83.4556 270.576 83.5559 269.26 83.9315 268.01C84.3072 266.761 84.9493 265.607 85.8136 264.63C86.6779 263.652 87.7438 262.873 88.938 262.347C90.1321 261.821 91.4262 261.56 92.7309 261.582L221.715 260.84C223.89 260.831 225.996 261.605 227.647 263.02C229.299 264.435 230.387 266.397 230.712 268.547L234.646 294.57C234.826 295.801 234.752 297.055 234.426 298.256C234.101 299.456 233.532 300.577 232.754 301.548C231.977 302.519 231.008 303.32 229.907 303.9C228.807 304.48 227.599 304.828 226.359 304.921Z"
                fill="#36CEA2"
            />
            <path
                opacity="0.6"
                d="M226.359 304.921L98.1482 313.885C95.8562 314.057 93.5843 313.354 91.7896 311.918C89.9948 310.482 88.8103 308.42 88.4744 306.146L83.6375 271.869C83.4556 270.576 83.5559 269.26 83.9315 268.01C84.3072 266.761 84.9493 265.607 85.8136 264.63C86.6779 263.652 87.7438 262.873 88.938 262.347C90.1321 261.821 91.4262 261.56 92.7309 261.582L221.715 260.84C223.89 260.831 225.996 261.605 227.647 263.02C229.299 264.435 230.387 266.397 230.712 268.547L234.646 294.57C234.826 295.801 234.752 297.055 234.426 298.256C234.101 299.456 233.532 300.577 232.754 301.548C231.977 302.519 231.008 303.32 229.907 303.9C228.807 304.48 227.599 304.828 226.359 304.921Z"
                fill="url(#paint17_radial_10025_65882)"
            />
            <path
                d="M88.6679 306.146C87.2813 297.891 86.1204 289.636 84.8951 281.381C84.0268 277.278 83.5838 273.096 83.573 268.902C83.9786 266.792 85.1237 264.895 86.8023 263.553C88.4809 262.212 90.5831 261.513 92.7309 261.582C90.6788 261.69 88.722 262.482 87.173 263.832C85.624 265.182 84.5722 267.013 84.1857 269.031C84.274 273.164 84.6945 277.283 85.4433 281.349C86.5396 289.604 87.7005 297.859 88.6679 306.178V306.146Z"
                fill="#D2EBF3"
            />
            <path
                d="M54.0033 92.2257L27.9485 82.4229L213.879 98.1912L253.445 106.124L54.0033 92.2257Z"
                fill="url(#paint18_linear_10025_65882)"
            />
            <path
                opacity="0.4"
                d="M54.0033 92.2257L27.9485 82.4229L42.1368 83.616L78.8328 94.0959L54.0033 92.2257Z"
                fill="url(#paint19_linear_10025_65882)"
            />
            <path
                opacity="0.8"
                d="M103.727 95.676L66.5471 85.6797L42.1368 83.616L78.8329 94.0959L103.727 95.676Z"
                fill="url(#paint20_linear_10025_65882)"
            />
            <path
                opacity="0.8"
                d="M103.727 95.6762L66.547 85.6799L89.6674 87.6469L128.524 97.4175L103.727 95.6762Z"
                fill="url(#paint21_linear_10025_65882)"
            />
            <path
                opacity="0.4"
                d="M128.524 97.4173L89.6998 87.6467L121.043 90.3876L153.579 99.1586L128.524 97.4173Z"
                fill="url(#paint22_linear_10025_65882)"
            />
            <path
                opacity="0.8"
                d="M178.602 100.9L145.453 92.387L121.043 90.3877L153.579 99.1586L178.602 100.9Z"
                fill="url(#paint23_linear_10025_65882)"
            />
            <path
                opacity="0.8"
                d="M175.249 100.255L144.906 92.29L161.029 93.9023L200.078 101.996L175.249 100.255Z"
                fill="url(#paint24_linear_10025_65882)"
            />
            <path
                opacity="0.4"
                d="M201.335 101.964L161.027 93.3862L194.885 97.1273L229.003 103.899L201.335 101.964Z"
                fill="url(#paint25_linear_10025_65882)"
            />
            <path
                opacity="0.8"
                d="M252.929 106.156L215.846 98.7393L191.661 97.127L229.003 103.899L252.929 106.156Z"
                fill="url(#paint26_linear_10025_65882)"
            />
            <path
                d="M255.477 121.699L232.163 145.593L227.39 112.637L255.477 121.699Z"
                fill="url(#paint27_linear_10025_65882)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M236.129 145.851L232.163 145.593L252.929 123.601L254.058 127.309L236.129 145.851Z"
                fill="#197DE3"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M35.494 144.658L40.2342 144.367L60.8394 117.99L58.034 111.315L35.494 144.658Z"
                fill="url(#paint28_linear_10025_65882)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M70.965 124.762C64.0966 124.407 57.5507 117.571 56.3898 109.509L53.9069 92.2256L78.8331 94.0959L81.316 110.993C82.4769 118.958 77.8657 125.117 70.965 124.762Z"
                fill="url(#paint29_linear_10025_65882)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M95.8911 126.149C88.9904 125.794 82.4767 119.054 81.3159 111.09L78.8007 94.0959L103.759 95.8372L106.21 112.476C107.403 120.344 102.759 126.407 95.8911 126.149Z"
                fill="url(#paint30_linear_10025_65882)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M120.721 127.341C113.852 126.987 107.338 120.344 106.178 112.476L103.727 95.8369L128.653 97.546L131.104 113.959C132.297 121.731 127.686 127.728 120.721 127.341Z"
                fill="url(#paint31_linear_10025_65882)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M145.711 128.664C142.009 128.149 138.58 126.43 135.951 123.773C133.323 121.115 131.642 117.667 131.168 113.959L128.718 97.5461L153.676 99.2552L156.094 115.378C157.223 123.117 152.58 129.018 145.711 128.664Z"
                fill="url(#paint32_linear_10025_65882)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M170.605 129.954C166.952 129.445 163.565 127.754 160.962 125.14C158.36 122.525 156.684 119.131 156.191 115.475L153.773 99.3523L178.699 101.061L181.118 117.184C182.149 124.504 177.474 130.309 170.605 129.954Z"
                fill="url(#paint33_linear_10025_65882)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M195.531 131.244C191.88 130.785 188.482 129.137 185.859 126.556C183.237 123.974 181.537 120.602 181.021 116.958L178.602 100.835L203.561 102.577L205.947 118.313C207.075 125.858 202.4 131.598 195.531 131.244Z"
                fill="url(#paint34_linear_10025_65882)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M220.425 132.598C216.804 132.152 213.428 130.534 210.814 127.989C208.199 125.444 206.49 122.114 205.947 118.506L203.561 102.77L228.519 104.479L230.873 119.989C231.969 127.245 227.294 132.888 220.425 132.598Z"
                fill="url(#paint35_linear_10025_65882)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M245.319 133.823C241.722 133.407 238.361 131.822 235.751 129.311C233.142 126.801 231.428 123.503 230.873 119.925L228.519 104.415L253.445 106.124L255.799 121.44C256.175 122.977 256.182 124.581 255.819 126.121C255.456 127.661 254.734 129.093 253.712 130.3C252.69 131.508 251.397 132.457 249.938 133.069C248.48 133.681 246.897 133.939 245.319 133.823Z"
                fill="url(#paint36_linear_10025_65882)"
            />
            <path
                d="M54.0033 92.2257L27.9485 82.4229L35.4941 144.658L57.2602 112.96L54.0033 92.2257Z"
                fill="url(#paint37_linear_10025_65882)"
            />
            <path
                d="M150.645 285.186C151.163 285.342 151.661 285.559 152.128 285.831C152.565 286.077 152.966 286.381 153.321 286.734C153.663 287.12 153.945 287.555 154.159 288.024C154.399 288.569 154.562 289.144 154.643 289.733C154.793 290.478 154.793 291.245 154.643 291.99C154.454 292.65 154.099 293.249 153.611 293.732C153.089 294.27 152.449 294.678 151.741 294.925C150.873 295.247 149.958 295.422 149.032 295.441L139.907 295.989L137.391 276.254H146.42C146.925 276.22 147.431 276.22 147.936 276.254C148.374 276.31 148.806 276.407 149.226 276.544L150.354 277.06C150.693 277.271 151.007 277.52 151.29 277.802L151.805 278.382C151.986 278.598 152.138 278.837 152.257 279.092C152.384 279.352 152.492 279.622 152.579 279.898C152.678 280.191 152.743 280.494 152.773 280.801C152.838 281.293 152.838 281.792 152.773 282.284C152.718 282.702 152.587 283.107 152.386 283.477C151.998 284.165 151.43 284.734 150.741 285.122L150.645 285.186ZM149.742 289.249C149.716 289 149.639 288.758 149.516 288.54C149.387 288.33 149.21 288.153 149 288.024C148.773 287.854 148.507 287.743 148.226 287.701C147.872 287.664 147.516 287.664 147.162 287.701H143.486L143.937 291.313L147.71 291.12C148.031 291.171 148.357 291.171 148.678 291.12C148.917 291.037 149.137 290.905 149.323 290.733C149.508 290.583 149.643 290.381 149.709 290.152C149.775 289.909 149.775 289.653 149.709 289.411L149.742 289.249ZM142.486 280.672L142.938 283.896H146.162C146.48 283.883 146.794 283.828 147.098 283.735C147.35 283.695 147.585 283.583 147.775 283.413C147.939 283.264 148.062 283.075 148.129 282.865C148.198 282.622 148.198 282.365 148.129 282.123C148.088 281.877 148.012 281.638 147.904 281.413C147.776 281.222 147.612 281.057 147.42 280.93C147.203 280.784 146.963 280.675 146.711 280.607C146.389 280.572 146.065 280.572 145.743 280.607H142.519"
                fill="#F2F6FF"
            />
            <path
                d="M187.696 275.706L184.116 286.605L185.181 293.312L181.15 293.57L180.086 286.831L172.701 275.867L177.409 275.835L181.408 282.155L183.342 275.77L187.696 275.706Z"
                fill="#F2F6FF"
            />
            <path
                d="M170.508 275.899L172.024 285.863C172.215 287.135 172.215 288.429 172.024 289.701C171.88 290.673 171.516 291.599 170.96 292.409C170.428 293.145 169.718 293.733 168.896 294.118C167.958 294.527 166.952 294.757 165.929 294.796C164.842 294.887 163.747 294.766 162.705 294.441C161.744 294.164 160.861 293.667 160.125 292.99C159.311 292.265 158.663 291.375 158.223 290.378C157.669 289.172 157.299 287.889 157.126 286.573L155.643 276.061H160.222L161.705 286.347C161.78 286.948 161.943 287.535 162.189 288.088C162.361 288.545 162.625 288.962 162.963 289.314C163.242 289.625 163.598 289.858 163.995 289.991C164.382 290.152 164.802 290.218 165.22 290.184C165.634 290.176 166.041 290.077 166.413 289.894C166.74 289.719 167.018 289.464 167.219 289.153C167.429 288.774 167.561 288.357 167.606 287.927C167.686 287.371 167.686 286.807 167.606 286.25L166.091 275.996L170.508 275.899Z"
                fill="#F2F6FF"
            />
            <g opacity="0.6">
                <g opacity="0.6">
                    <path
                        d="M273.406 205.474C275.936 205.679 278.307 206.79 280.084 208.602C281.861 210.415 282.925 212.808 283.079 215.341L285.337 235.785C285.524 236.945 285.465 238.132 285.162 239.267C284.86 240.402 284.321 241.461 283.582 242.374C282.842 243.287 281.918 244.034 280.87 244.565C279.823 245.097 278.674 245.402 277.501 245.459L206.559 246.781C204.056 246.595 201.702 245.516 199.926 243.741C198.151 241.965 197.072 239.611 196.886 237.107L194.886 213.826C194.704 212.666 194.767 211.482 195.072 210.349C195.376 209.215 195.915 208.159 196.654 207.247C197.392 206.335 198.314 205.588 199.359 205.055C200.405 204.522 201.55 204.214 202.722 204.152L273.406 205.474Z"
                        fill="url(#paint38_radial_10025_65882)"
                    />
                    <path
                        d="M273.406 205.474C275.936 205.679 278.307 206.79 280.084 208.602C281.861 210.415 282.925 212.808 283.079 215.341L285.337 235.785C285.524 236.945 285.465 238.132 285.162 239.267C284.86 240.402 284.321 241.461 283.582 242.374C282.842 243.287 281.918 244.034 280.87 244.565C279.823 245.097 278.674 245.402 277.501 245.459L206.559 246.781C204.056 246.595 201.702 245.516 199.926 243.741C198.151 241.965 197.072 239.611 196.886 237.107L194.886 213.826C194.704 212.666 194.767 211.482 195.072 210.349C195.376 209.215 195.915 208.159 196.654 207.247C197.392 206.335 198.314 205.588 199.359 205.055C200.405 204.522 201.55 204.214 202.722 204.152L273.406 205.474Z"
                        fill="url(#paint39_radial_10025_65882)"
                    />
                </g>
            </g>
            <path
                d="M277.501 245.653L206.56 246.975C204.056 246.788 201.702 245.709 199.926 243.934C198.151 242.159 197.072 239.805 196.886 237.301L194.886 213.826C194.616 211.938 194.959 210.014 195.865 208.337C196.77 206.659 198.191 205.316 199.917 204.507L195.951 206.119C194.214 206.916 192.785 208.256 191.877 209.937C190.97 211.619 190.634 213.549 190.92 215.438L193.145 238.784C193.344 241.283 194.427 243.63 196.2 245.403C197.973 247.176 200.32 248.259 202.819 248.458H223.585C224.585 248.458 275.566 246.942 276.437 246.588L280.403 245.008C279.484 245.401 278.5 245.62 277.501 245.653Z"
                fill="url(#paint40_radial_10025_65882)"
            />
            <path
                d="M277.501 245.653L206.56 246.975C204.056 246.788 201.702 245.709 199.926 243.934C198.151 242.159 197.072 239.805 196.886 237.301L194.886 213.826C194.616 211.938 194.959 210.014 195.865 208.337C196.77 206.659 198.191 205.316 199.917 204.507L195.951 206.119C194.214 206.916 192.785 208.256 191.877 209.937C190.97 211.619 190.634 213.549 190.92 215.438L193.145 238.784C193.344 241.283 194.427 243.63 196.2 245.403C197.973 247.176 200.32 248.259 202.819 248.458H223.585C224.585 248.458 275.566 246.942 276.437 246.588L280.403 245.008C279.484 245.401 278.5 245.62 277.501 245.653Z"
                fill="url(#paint41_radial_10025_65882)"
            />
            <path
                d="M211.009 216.76C213.299 216.76 214.685 218.34 214.976 221.468L215.814 230.368C216.104 233.238 215.427 235.237 212.589 235.237C209.752 235.237 208.881 233.27 208.623 230.4L207.785 221.629C207.43 218.372 208.559 216.76 211.009 216.76ZM213.654 230.561L212.783 221.306C212.783 219.759 212.073 219.017 211.138 219.017C210.203 219.017 209.784 219.694 209.945 221.371L210.816 230.626C210.816 232.173 211.461 232.947 212.46 232.947C213.46 232.947 213.815 232.27 213.654 230.561Z"
                fill="white"
            />
            <path
                d="M220.812 231.045L221.167 235.011H218.974L218.587 231.045H220.812Z"
                fill="white"
            />
            <path
                d="M225.843 216.76C228.326 216.76 229.293 218.501 229.519 220.984V221.661C229.713 223.725 229.197 224.692 228.326 225.112C229.033 225.429 229.626 225.954 230.029 226.616C230.432 227.278 230.625 228.046 230.583 228.82V230.303C230.873 233.205 230.261 235.237 227.359 235.237C224.456 235.237 223.683 233.592 223.425 231.077V230.529H225.456V231.077C225.456 232.173 225.972 233.205 227.133 233.205C228.294 233.205 228.519 232.27 228.39 230.626L228.197 228.852C228.036 227.014 227.391 226.337 226.004 226.337H225.327L225.134 224.176H225.779C226.972 224.176 227.423 223.628 227.262 221.919V220.92C227.262 219.759 226.843 218.824 225.843 218.824C224.843 218.824 224.618 219.759 224.714 220.823V221.5H222.941V220.984C222.715 219.081 223.005 216.76 225.843 216.76Z"
                fill="white"
            />
            <path
                d="M239.773 228.304H241.031V230.368H240.031L240.45 235.011H238.419L237.967 230.368H233.453L233.259 228.53L235.807 216.986H238.741L239.773 228.304ZM237.387 224.628C237.193 222.855 237.097 221.081 236.968 219.308C236.452 222.339 235.904 225.305 235.323 228.304H237.709L237.387 224.628Z"
                fill="white"
            />
            <path
                d="M253.091 216.502L253.284 218.791H249.415L249.931 224.048H253.575L253.8 226.337H250.124L250.737 232.722H254.929L254.864 235.011H248.738L246.996 216.502H253.091Z"
                fill="white"
            />
            <path
                d="M262.829 216.502L263.055 218.791H260.411L261.958 235.011H259.733L258.186 218.791H255.509L255.316 216.502H262.829Z"
                fill="white"
            />
            <path
                d="M272.728 216.502L274.502 235.011H272.277L271.439 226.208H268.407L269.246 235.011H267.021L265.28 216.502H267.505L268.182 223.983H271.213L270.503 216.502H272.728Z"
                fill="white"
            />
            <g opacity="0.6">
                <g opacity="0.6">
                    <g opacity="0.6">
                        <path
                            d="M283.66 255.133C285.293 255.274 286.818 256.007 287.946 257.196C289.075 258.384 289.73 259.944 289.787 261.582L291.625 275.803C291.754 276.542 291.724 277.301 291.537 278.028C291.35 278.755 291.01 279.434 290.541 280.02C290.071 280.606 289.482 281.085 288.812 281.425C288.143 281.765 287.409 281.959 286.659 281.994H272.31C270.717 281.872 269.223 281.178 268.102 280.04C266.982 278.902 266.312 277.397 266.215 275.803L264.796 261.098C264.702 260.386 264.754 259.661 264.949 258.97C265.144 258.278 265.479 257.633 265.931 257.075C266.384 256.517 266.946 256.056 267.582 255.723C268.219 255.389 268.916 255.188 269.633 255.133H283.66Z"
                            fill="url(#paint42_radial_10025_65882)"
                        />
                        <path
                            d="M283.66 255.133C285.293 255.274 286.818 256.007 287.946 257.196C289.075 258.384 289.73 259.944 289.787 261.582L291.625 275.803C291.754 276.542 291.724 277.301 291.537 278.028C291.35 278.755 291.01 279.434 290.541 280.02C290.071 280.606 289.482 281.085 288.812 281.425C288.143 281.765 287.409 281.959 286.659 281.994H272.31C270.717 281.872 269.223 281.178 268.102 280.04C266.982 278.902 266.312 277.397 266.215 275.803L264.796 261.098C264.702 260.386 264.754 259.661 264.949 258.97C265.144 258.278 265.479 257.633 265.931 257.075C266.384 256.517 266.946 256.056 267.582 255.723C268.219 255.389 268.916 255.188 269.633 255.133H283.66Z"
                            fill="url(#paint43_radial_10025_65882)"
                        />
                    </g>
                    <g opacity="0.6">
                        <path
                            d="M283.66 255.133C285.293 255.274 286.818 256.007 287.946 257.196C289.075 258.384 289.73 259.944 289.787 261.582L291.625 275.803C291.754 276.542 291.724 277.301 291.537 278.028C291.35 278.755 291.01 279.434 290.541 280.02C290.071 280.606 289.482 281.085 288.812 281.425C288.143 281.765 287.409 281.959 286.659 281.994H272.31C270.717 281.872 269.223 281.178 268.102 280.04C266.982 278.902 266.312 277.397 266.215 275.803L264.796 261.098C264.702 260.386 264.754 259.661 264.949 258.97C265.144 258.278 265.479 257.633 265.931 257.075C266.384 256.517 266.946 256.056 267.582 255.723C268.219 255.389 268.916 255.188 269.633 255.133H283.66Z"
                            fill="url(#paint44_radial_10025_65882)"
                        />
                        <path
                            d="M283.66 255.133C285.293 255.274 286.818 256.007 287.946 257.196C289.075 258.384 289.73 259.944 289.787 261.582L291.625 275.803C291.754 276.542 291.724 277.301 291.537 278.028C291.35 278.755 291.01 279.434 290.541 280.02C290.071 280.606 289.482 281.085 288.812 281.425C288.143 281.765 287.409 281.959 286.659 281.994H272.31C270.717 281.872 269.223 281.178 268.102 280.04C266.982 278.902 266.312 277.397 266.215 275.803L264.796 261.098C264.702 260.386 264.754 259.661 264.949 258.97C265.144 258.278 265.479 257.633 265.931 257.075C266.384 256.517 266.946 256.056 267.582 255.723C268.219 255.389 268.916 255.188 269.633 255.133H283.66Z"
                            fill="url(#paint45_radial_10025_65882)"
                        />
                    </g>
                </g>
            </g>
            <path
                d="M286.562 281.994H272.212C270.62 281.872 269.126 281.178 268.005 280.04C266.885 278.902 266.214 277.397 266.118 275.803L264.699 261.098C264.529 259.903 264.751 258.685 265.333 257.626C265.914 256.568 266.823 255.727 267.924 255.229L265.408 256.229C264.306 256.732 263.395 257.579 262.814 258.643C262.233 259.707 262.012 260.93 262.184 262.13L263.603 276.834C263.668 278.455 264.324 279.995 265.446 281.165C266.569 282.335 268.081 283.054 269.697 283.187H282.789C283.816 283.136 284.834 282.963 285.82 282.671L288.335 281.671C287.771 281.895 287.169 282.005 286.562 281.994Z"
                fill="url(#paint46_radial_10025_65882)"
            />
            <path
                d="M286.562 281.994H272.212C270.62 281.872 269.126 281.178 268.005 280.04C266.885 278.902 266.214 277.397 266.118 275.803L264.699 261.098C264.529 259.903 264.751 258.685 265.333 257.626C265.914 256.568 266.823 255.727 267.924 255.229L265.408 256.229C264.306 256.732 263.395 257.579 262.814 258.643C262.233 259.707 262.012 260.93 262.184 262.13L263.603 276.834C263.668 278.455 264.324 279.995 265.446 281.165C266.569 282.335 268.081 283.054 269.697 283.187H282.789C283.816 283.136 284.834 282.963 285.82 282.671L288.335 281.671C287.771 281.895 287.169 282.005 286.562 281.994Z"
                fill="url(#paint47_radial_10025_65882)"
            />
            <g opacity="0.6">
                <g opacity="0.6">
                    <g opacity="0.6">
                        <path
                            d="M257.778 277.705C258.608 277.778 259.387 278.141 259.977 278.731C260.566 279.32 260.93 280.099 261.002 280.93L261.905 288.54C261.969 288.927 261.951 289.323 261.851 289.702C261.75 290.081 261.571 290.435 261.324 290.74C261.077 291.044 260.769 291.293 260.419 291.47C260.069 291.647 259.685 291.747 259.293 291.765H251.683C250.85 291.698 250.068 291.337 249.477 290.746C248.886 290.155 248.525 289.373 248.458 288.54L247.749 280.93C247.685 280.546 247.703 280.152 247.801 279.775C247.899 279.398 248.076 279.046 248.319 278.742C248.562 278.437 248.867 278.188 249.213 278.009C249.56 277.831 249.94 277.727 250.329 277.705H257.778Z"
                            fill="url(#paint48_radial_10025_65882)"
                        />
                        <path
                            d="M257.778 277.705C258.608 277.778 259.387 278.141 259.977 278.731C260.566 279.32 260.93 280.099 261.002 280.93L261.905 288.54C261.969 288.927 261.951 289.323 261.851 289.702C261.75 290.081 261.571 290.435 261.324 290.74C261.077 291.044 260.769 291.293 260.419 291.47C260.069 291.647 259.685 291.747 259.293 291.765H251.683C250.85 291.698 250.068 291.337 249.477 290.746C248.886 290.155 248.525 289.373 248.458 288.54L247.749 280.93C247.685 280.546 247.703 280.152 247.801 279.775C247.899 279.398 248.076 279.046 248.319 278.742C248.562 278.437 248.867 278.188 249.213 278.009C249.56 277.831 249.94 277.727 250.329 277.705H257.778Z"
                            fill="url(#paint49_radial_10025_65882)"
                        />
                    </g>
                    <g opacity="0.6">
                        <path
                            d="M257.778 277.705C258.608 277.778 259.387 278.141 259.977 278.731C260.566 279.32 260.93 280.099 261.002 280.93L261.905 288.54C261.969 288.927 261.951 289.323 261.851 289.702C261.75 290.081 261.571 290.435 261.324 290.74C261.077 291.044 260.769 291.293 260.419 291.47C260.069 291.647 259.685 291.747 259.293 291.765H251.683C250.85 291.698 250.068 291.337 249.477 290.746C248.886 290.155 248.525 289.373 248.458 288.54L247.749 280.93C247.685 280.546 247.703 280.152 247.801 279.775C247.899 279.398 248.076 279.046 248.319 278.742C248.562 278.437 248.867 278.188 249.213 278.009C249.56 277.831 249.94 277.727 250.329 277.705H257.778Z"
                            fill="url(#paint50_radial_10025_65882)"
                        />
                        <path
                            d="M257.778 277.705C258.608 277.778 259.387 278.141 259.977 278.731C260.566 279.32 260.93 280.099 261.002 280.93L261.905 288.54C261.969 288.927 261.951 289.323 261.851 289.702C261.75 290.081 261.571 290.435 261.324 290.74C261.077 291.044 260.769 291.293 260.419 291.47C260.069 291.647 259.685 291.747 259.293 291.765H251.683C250.85 291.698 250.068 291.337 249.477 290.746C248.886 290.155 248.525 289.373 248.458 288.54L247.749 280.93C247.685 280.546 247.703 280.152 247.801 279.775C247.899 279.398 248.076 279.046 248.319 278.742C248.562 278.437 248.867 278.188 249.213 278.009C249.56 277.831 249.94 277.727 250.329 277.705H257.778Z"
                            fill="url(#paint51_radial_10025_65882)"
                        />
                    </g>
                </g>
            </g>
            <path
                d="M259.229 291.829H251.619C250.786 291.763 250.004 291.402 249.413 290.811C248.822 290.22 248.461 289.438 248.394 288.605L247.749 280.93C247.622 280.266 247.734 279.578 248.064 278.989C248.394 278.399 248.922 277.944 249.555 277.706L248.201 278.222C247.607 278.501 247.121 278.968 246.818 279.551C246.515 280.133 246.412 280.799 246.524 281.446L247.298 289.25C247.358 290.085 247.717 290.871 248.309 291.463C248.901 292.055 249.687 292.414 250.522 292.474H257.455C258 292.438 258.539 292.352 259.068 292.216L260.39 291.668C260.023 291.82 259.623 291.876 259.229 291.829Z"
                fill="url(#paint52_radial_10025_65882)"
            />
            <path
                d="M259.229 291.829H251.619C250.786 291.763 250.004 291.402 249.413 290.811C248.822 290.22 248.461 289.438 248.394 288.605L247.749 280.93C247.622 280.266 247.734 279.578 248.064 278.989C248.394 278.399 248.922 277.944 249.555 277.706L248.201 278.222C247.607 278.501 247.121 278.968 246.818 279.551C246.515 280.133 246.412 280.799 246.524 281.446L247.298 289.25C247.358 290.085 247.717 290.871 248.309 291.463C248.901 292.055 249.687 292.414 250.522 292.474H257.455C258 292.438 258.539 292.352 259.068 292.216L260.39 291.668C260.023 291.82 259.623 291.876 259.229 291.829Z"
                fill="url(#paint53_radial_10025_65882)"
            />
            <g opacity="0.6">
                <g opacity="0.6">
                    <g opacity="0.6">
                        <path
                            d="M42.6676 158.524C45.1455 158.722 47.4676 159.811 49.2045 161.59C50.9414 163.368 51.9756 165.715 52.1157 168.197L54.7921 190.35C55.0158 191.497 54.9897 192.678 54.7155 193.814C54.4412 194.949 53.9254 196.012 53.2032 196.931C52.4809 197.849 51.5691 198.6 50.5301 199.134C49.491 199.668 48.349 199.972 47.182 200.024L24.9967 200.314C22.5197 200.122 20.1976 199.033 18.4647 197.253C16.7318 195.473 15.7066 193.122 15.5809 190.641L13.3881 168.068C13.1755 166.923 13.2096 165.745 13.4881 164.614C13.7667 163.483 14.2834 162.425 15.0036 161.509C15.7239 160.593 16.6311 159.842 17.6649 159.305C18.6986 158.768 19.835 158.458 20.9982 158.395L42.6676 158.524Z"
                            fill="url(#paint54_radial_10025_65882)"
                        />
                        <path
                            d="M42.6676 158.524C45.1455 158.722 47.4676 159.811 49.2045 161.59C50.9414 163.368 51.9756 165.715 52.1157 168.197L54.7921 190.35C55.0158 191.497 54.9897 192.678 54.7155 193.814C54.4412 194.949 53.9254 196.012 53.2032 196.931C52.4809 197.849 51.5691 198.6 50.5301 199.134C49.491 199.668 48.349 199.972 47.182 200.024L24.9967 200.314C22.5197 200.122 20.1976 199.033 18.4647 197.253C16.7318 195.473 15.7066 193.122 15.5809 190.641L13.3881 168.068C13.1755 166.923 13.2096 165.745 13.4881 164.614C13.7667 163.483 14.2834 162.425 15.0036 161.509C15.7239 160.593 16.6311 159.842 17.6649 159.305C18.6986 158.768 19.835 158.458 20.9982 158.395L42.6676 158.524Z"
                            fill="url(#paint55_radial_10025_65882)"
                        />
                        <path
                            d="M42.6676 158.524C45.1455 158.722 47.4676 159.811 49.2045 161.59C50.9414 163.368 51.9756 165.715 52.1157 168.197L54.7921 190.35C55.0158 191.497 54.9897 192.678 54.7155 193.814C54.4412 194.949 53.9254 196.012 53.2032 196.931C52.4809 197.849 51.5691 198.6 50.5301 199.134C49.491 199.668 48.349 199.972 47.182 200.024L24.9967 200.314C22.5197 200.122 20.1976 199.033 18.4647 197.253C16.7318 195.473 15.7066 193.122 15.5809 190.641L13.3881 168.068C13.1755 166.923 13.2096 165.745 13.4881 164.614C13.7667 163.483 14.2834 162.425 15.0036 161.509C15.7239 160.593 16.6311 159.842 17.6649 159.305C18.6986 158.768 19.835 158.458 20.9982 158.395L42.6676 158.524Z"
                            fill="url(#paint56_radial_10025_65882)"
                        />
                    </g>
                    <g opacity="0.6">
                        <path
                            d="M42.6676 158.524C45.1455 158.722 47.4676 159.811 49.2045 161.59C50.9414 163.368 51.9756 165.715 52.1157 168.197L54.7921 190.35C55.0158 191.497 54.9897 192.678 54.7155 193.814C54.4412 194.949 53.9254 196.012 53.2032 196.931C52.4809 197.849 51.5691 198.6 50.5301 199.134C49.491 199.668 48.349 199.972 47.182 200.024L24.9967 200.314C22.5197 200.122 20.1976 199.033 18.4647 197.253C16.7318 195.473 15.7066 193.122 15.5809 190.641L13.3881 168.068C13.1755 166.923 13.2096 165.745 13.4881 164.614C13.7667 163.483 14.2834 162.425 15.0036 161.509C15.7239 160.593 16.6311 159.842 17.6649 159.305C18.6986 158.768 19.835 158.458 20.9982 158.395L42.6676 158.524Z"
                            fill="url(#paint57_radial_10025_65882)"
                        />
                        <path
                            d="M42.6676 158.524C45.1455 158.722 47.4676 159.811 49.2045 161.59C50.9414 163.368 51.9756 165.715 52.1157 168.197L54.7921 190.35C55.0158 191.497 54.9897 192.678 54.7155 193.814C54.4412 194.949 53.9254 196.012 53.2032 196.931C52.4809 197.849 51.5691 198.6 50.5301 199.134C49.491 199.668 48.349 199.972 47.182 200.024L24.9967 200.314C22.5197 200.122 20.1976 199.033 18.4647 197.253C16.7318 195.473 15.7066 193.122 15.5809 190.641L13.3881 168.068C13.1755 166.923 13.2096 165.745 13.4881 164.614C13.7667 163.483 14.2834 162.425 15.0036 161.509C15.7239 160.593 16.6311 159.842 17.6649 159.305C18.6986 158.768 19.835 158.458 20.9982 158.395L42.6676 158.524Z"
                            fill="url(#paint58_radial_10025_65882)"
                        />
                        <path
                            d="M42.6676 158.524C45.1455 158.722 47.4676 159.811 49.2045 161.59C50.9414 163.368 51.9756 165.715 52.1157 168.197L54.7921 190.35C55.0158 191.497 54.9897 192.678 54.7155 193.814C54.4412 194.949 53.9254 196.012 53.2032 196.931C52.4809 197.849 51.5691 198.6 50.5301 199.134C49.491 199.668 48.349 199.972 47.182 200.024L24.9967 200.314C22.5197 200.122 20.1976 199.033 18.4647 197.253C16.7318 195.473 15.7066 193.122 15.5809 190.641L13.3881 168.068C13.1755 166.923 13.2096 165.745 13.4881 164.614C13.7667 163.483 14.2834 162.425 15.0036 161.509C15.7239 160.593 16.6311 159.842 17.6649 159.305C18.6986 158.768 19.835 158.458 20.9982 158.395L42.6676 158.524Z"
                            fill="url(#paint59_radial_10025_65882)"
                        />
                    </g>
                </g>
            </g>
            <path
                d="M47.1175 199.8L24.9322 200.09C22.4552 199.897 20.1331 198.809 18.4002 197.028C16.6673 195.248 15.6421 192.897 15.5164 190.416L13.3236 167.844C13.0577 166.003 13.3905 164.125 14.2731 162.488C15.1556 160.85 16.5411 159.54 18.225 158.75L14.42 160.234C12.7353 161.014 11.3478 162.317 10.4645 163.95C9.58124 165.583 9.24943 167.458 9.51859 169.295L11.7113 191.867C11.8302 194.351 12.8532 196.705 14.5875 198.487C16.3217 200.269 18.6477 201.355 21.1272 201.541H41.3455C42.9272 201.451 44.4948 201.192 46.0211 200.767L49.8907 199.187C49.0191 199.582 48.0745 199.791 47.1175 199.8Z"
                fill="url(#paint60_radial_10025_65882)"
            />
            <path
                opacity="0.6"
                d="M51.053 212.408C54.5016 212.68 57.7366 214.186 60.1648 216.65C62.5931 219.113 64.0519 222.37 64.2739 225.822L68.0467 256.778C68.3226 258.37 68.2574 260.003 67.8555 261.567C67.4535 263.132 66.724 264.594 65.715 265.856C64.7061 267.118 63.4408 268.151 62.0028 268.888C60.5648 269.624 58.9869 270.047 57.3733 270.128L26.3848 270.515C22.9451 270.257 19.715 268.763 17.2908 266.31C14.8667 263.856 13.4123 260.608 13.1961 257.165L10.165 225.435C9.45557 218.051 14.228 212.021 20.7739 212.021L51.053 212.408Z"
                fill="url(#paint61_radial_10025_65882)"
            />
            <path
                d="M57.373 270.128L26.3846 270.515C22.9449 270.257 19.7148 268.763 17.2906 266.31C14.8665 263.856 13.4121 260.608 13.1959 257.165L10.1648 225.435C9.77389 222.867 10.2295 220.241 11.4628 217.955C12.6961 215.669 14.6401 213.846 17.001 212.762L11.6481 215.052C9.29976 216.129 7.36064 217.934 6.1178 220.199C4.87497 222.464 4.39447 225.069 4.74744 227.628L7.77857 259.358C8.00134 262.798 9.45793 266.043 11.8808 268.495C14.3036 270.947 17.53 272.443 20.9672 272.708H49.2148C51.399 272.587 53.5625 272.219 55.664 271.612L61.0813 269.451C59.8961 269.898 58.6399 270.128 57.373 270.128Z"
                fill="url(#paint62_radial_10025_65882)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_10025_65882"
                    x1="31.6244"
                    y1="213.374"
                    x2="261.152"
                    y2="213.374"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#B2F5F2" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_10025_65882"
                    x1="24.4016"
                    y1="212.02"
                    x2="256.348"
                    y2="212.02"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#90CFEB" />
                    <stop offset="1" stopColor="#1D9DD7" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_10025_65882"
                    x1="143.002"
                    y1="186.83"
                    x2="142.813"
                    y2="109.207"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#D6FAF8" stopOpacity="0" />
                    <stop
                        offset="0.16"
                        stopColor="#C1E9EF"
                        stopOpacity="0.13"
                    />
                    <stop
                        offset="0.51"
                        stopColor="#8CBED7"
                        stopOpacity="0.47"
                    />
                    <stop offset="1" stopColor="#3879B2" />
                </linearGradient>
                <radialGradient
                    id="paint3_radial_10025_65882"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(138.392 214.244) rotate(-0.572939) scale(121.413 109.248)"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="white" stopOpacity="0" />
                </radialGradient>
                <linearGradient
                    id="paint4_linear_10025_65882"
                    x1="114.948"
                    y1="267.967"
                    x2="140.229"
                    y2="184.353"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop
                        offset="0.01"
                        stopColor="#BAE3EB"
                        stopOpacity="0.18"
                    />
                    <stop
                        offset="0.36"
                        stopColor="#A4D1E2"
                        stopOpacity="0.32"
                    />
                    <stop
                        offset="0.99"
                        stopColor="#80B4D2"
                        stopOpacity="0.55"
                    />
                </linearGradient>
                <linearGradient
                    id="paint5_linear_10025_65882"
                    x1="174.81"
                    y1="109.23"
                    x2="183.156"
                    y2="193.603"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#5D1780" />
                    <stop offset="0.25" stopColor="#6C2E93" />
                    <stop offset="0.78" stopColor="#946AC3" />
                    <stop offset="1" stopColor="#A584D8" />
                </linearGradient>
                <linearGradient
                    id="paint6_linear_10025_65882"
                    x1="237.403"
                    y1="338.905"
                    x2="291.117"
                    y2="445.224"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#5D1780" />
                    <stop offset="0.25" stopColor="#6C2E93" />
                    <stop offset="0.78" stopColor="#946AC3" />
                    <stop offset="1" stopColor="#A584D8" />
                </linearGradient>
                <linearGradient
                    id="paint7_linear_10025_65882"
                    x1="160.738"
                    y1="171.874"
                    x2="166.413"
                    y2="201.766"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#47B2C4" />
                    <stop offset="0.41" stopColor="#5EC9D3" />
                    <stop offset="0.75" stopColor="#73DFE2" />
                </linearGradient>
                <linearGradient
                    id="paint8_linear_10025_65882"
                    x1="156.718"
                    y1="161.256"
                    x2="160.027"
                    y2="189.578"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.06" stopColor="#95D3E0" />
                    <stop offset="0.41" stopColor="#B8F5F9" />
                </linearGradient>
                <linearGradient
                    id="paint9_linear_10025_65882"
                    x1="144.118"
                    y1="134.475"
                    x2="148.455"
                    y2="194.873"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#00728B" />
                    <stop offset="0.24" stopColor="#17879E" />
                    <stop offset="0.66" stopColor="#47B2C4" />
                </linearGradient>
                <linearGradient
                    id="paint10_linear_10025_65882"
                    x1="412.715"
                    y1="410.599"
                    x2="422.894"
                    y2="465.382"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#47B2C4" />
                    <stop offset="0.41" stopColor="#5EC9D3" />
                    <stop offset="0.75" stopColor="#73DFE2" />
                </linearGradient>
                <linearGradient
                    id="paint11_linear_10025_65882"
                    x1="378.488"
                    y1="284.518"
                    x2="389.411"
                    y2="414.295"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#5D1780" />
                    <stop offset="0.25" stopColor="#6C2E93" />
                    <stop offset="0.78" stopColor="#946AC3" />
                    <stop offset="1" stopColor="#A584D8" />
                </linearGradient>
                <linearGradient
                    id="paint12_linear_10025_65882"
                    x1="338.282"
                    y1="319.5"
                    x2="354.175"
                    y2="470.597"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#5D1780" />
                    <stop offset="0.25" stopColor="#6C2E93" />
                    <stop offset="0.78" stopColor="#946AC3" />
                    <stop offset="1" stopColor="#A584D8" />
                </linearGradient>
                <linearGradient
                    id="paint13_linear_10025_65882"
                    x1="151.311"
                    y1="161.88"
                    x2="154.62"
                    y2="190.202"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.06" stopColor="#B8F5F9" />
                    <stop offset="0.41" stopColor="#B8F5F9" />
                </linearGradient>
                <linearGradient
                    id="paint14_linear_10025_65882"
                    x1="5788.58"
                    y1="4300.86"
                    x2="6145.7"
                    y2="7689.68"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#5D1780" />
                    <stop offset="0.25" stopColor="#6C2E93" />
                    <stop offset="0.78" stopColor="#946AC3" />
                    <stop offset="1" stopColor="#A584D8" />
                </linearGradient>
                <linearGradient
                    id="paint15_linear_10025_65882"
                    x1="127.105"
                    y1="230.4"
                    x2="117.657"
                    y2="162.812"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.1" stopColor="#572C5F" />
                    <stop offset="1" stopColor="#3E0F55" />
                </linearGradient>
                <linearGradient
                    id="paint16_linear_10025_65882"
                    x1="122.461"
                    y1="329.794"
                    x2="126.278"
                    y2="291.478"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop
                        offset="0.01"
                        stopColor="#BAE3EB"
                        stopOpacity="0.18"
                    />
                    <stop
                        offset="0.36"
                        stopColor="#A4D1E2"
                        stopOpacity="0.32"
                    />
                    <stop
                        offset="0.99"
                        stopColor="#80B4D2"
                        stopOpacity="0.55"
                    />
                </linearGradient>
                <radialGradient
                    id="paint17_radial_10025_65882"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(159.505 259.477) rotate(-4.51) scale(84.388 61.6033)"
                >
                    <stop stopColor="#3CD1A9" stopOpacity="0.6" />
                    <stop
                        offset="0.01"
                        stopColor="#3ED1AA"
                        stopOpacity="0.61"
                    />
                    <stop offset="1" stopColor="#D4E8E6" />
                </radialGradient>
                <linearGradient
                    id="paint18_linear_10025_65882"
                    x1="27.9485"
                    y1="94.2572"
                    x2="253.445"
                    y2="94.2572"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.01" stopColor="white" />
                    <stop offset="1" stopColor="#42E8E0" />
                </linearGradient>
                <linearGradient
                    id="paint19_linear_10025_65882"
                    x1="752.032"
                    y1="225.418"
                    x2="1554.99"
                    y2="225.418"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#90CFEB" />
                    <stop offset="1" stopColor="#1D9DD7" />
                </linearGradient>
                <linearGradient
                    id="paint20_linear_10025_65882"
                    x1="1189.56"
                    y1="236.537"
                    x2="2365.93"
                    y2="236.537"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.01" stopColor="white" />
                    <stop offset="1" stopColor="#42E8E0" />
                </linearGradient>
                <linearGradient
                    id="paint21_linear_10025_65882"
                    x1="73.8991"
                    y1="84.7448"
                    x2="118.012"
                    y2="97.4175"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#EAAFBD" />
                    <stop offset="1" stopColor="#E03760" />
                </linearGradient>
                <linearGradient
                    id="paint22_linear_10025_65882"
                    x1="2222"
                    y1="247.086"
                    x2="3487.45"
                    y2="247.086"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#90CFEB" />
                    <stop offset="1" stopColor="#1D9DD7" />
                </linearGradient>
                <linearGradient
                    id="paint23_linear_10025_65882"
                    x1="2601.85"
                    y1="243.235"
                    x2="3628.7"
                    y2="243.235"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.01" stopColor="white" />
                    <stop offset="1" stopColor="#42E8E0" />
                </linearGradient>
                <linearGradient
                    id="paint24_linear_10025_65882"
                    x1="3279.46"
                    y1="237.918"
                    x2="3557.44"
                    y2="659.814"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#EAAFBD" />
                    <stop offset="1" stopColor="#ED1818" />
                </linearGradient>
                <linearGradient
                    id="paint25_linear_10025_65882"
                    x1="3271.89"
                    y1="271.167"
                    x2="4074.84"
                    y2="271.167"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#90CFEB" />
                    <stop offset="1" stopColor="#1D9DD7" />
                </linearGradient>
                <linearGradient
                    id="paint26_linear_10025_65882"
                    x1="4207.98"
                    y1="283.477"
                    x2="5384.35"
                    y2="283.477"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.01" stopColor="white" />
                    <stop offset="1" stopColor="#42E8E0" />
                </linearGradient>
                <linearGradient
                    id="paint27_linear_10025_65882"
                    x1="2363.92"
                    y1="933.888"
                    x2="2608.83"
                    y2="933.888"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#90CFEB" />
                    <stop offset="1" stopColor="#1D9DD7" />
                </linearGradient>
                <linearGradient
                    id="paint28_linear_10025_65882"
                    x1="35.494"
                    y1="127.986"
                    x2="60.8394"
                    y2="127.986"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#29A2D9" stopOpacity="0" />
                    <stop offset="1" stopColor="#3879B2" />
                </linearGradient>
                <linearGradient
                    id="paint29_linear_10025_65882"
                    x1="668.567"
                    y1="695.393"
                    x2="904.444"
                    y2="695.393"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#90CFEB" />
                    <stop offset="1" stopColor="#1D9DD7" />
                </linearGradient>
                <linearGradient
                    id="paint30_linear_10025_65882"
                    x1="906.961"
                    y1="703.887"
                    x2="1142.91"
                    y2="703.887"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.01" stopColor="white" />
                    <stop offset="1" stopColor="#42E8E0" />
                </linearGradient>
                <linearGradient
                    id="paint31_linear_10025_65882"
                    x1="103.791"
                    y1="111.605"
                    x2="131.33"
                    y2="111.605"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.18" stopColor="#E05986" />
                    <stop offset="0.56" stopColor="#E03760" />
                </linearGradient>
                <linearGradient
                    id="paint32_linear_10025_65882"
                    x1="1381.51"
                    y1="718.643"
                    x2="1616.75"
                    y2="718.643"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#90CFEB" />
                    <stop offset="1" stopColor="#1D9DD7" />
                </linearGradient>
                <linearGradient
                    id="paint33_linear_10025_65882"
                    x1="1616.65"
                    y1="724.551"
                    x2="1851.11"
                    y2="724.551"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.01" stopColor="white" />
                    <stop offset="1" stopColor="#42E8E0" />
                </linearGradient>
                <linearGradient
                    id="paint34_linear_10025_65882"
                    x1="1855.5"
                    y1="736.385"
                    x2="2090.19"
                    y2="736.385"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.18" stopColor="#EF296B" />
                    <stop offset="0.56" stopColor="#ED1818" />
                </linearGradient>
                <linearGradient
                    id="paint35_linear_10025_65882"
                    x1="2090.6"
                    y1="740.117"
                    x2="2324.66"
                    y2="740.117"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#90CFEB" />
                    <stop offset="1" stopColor="#1D9DD7" />
                </linearGradient>
                <linearGradient
                    id="paint36_linear_10025_65882"
                    x1="2335.46"
                    y1="746.7"
                    x2="2570.06"
                    y2="746.7"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.01" stopColor="white" />
                    <stop offset="1" stopColor="#42E8E0" />
                </linearGradient>
                <linearGradient
                    id="paint37_linear_10025_65882"
                    x1="445.054"
                    y1="1332.72"
                    x2="711.497"
                    y2="1332.72"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#90CFEB" />
                    <stop offset="1" stopColor="#1D9DD7" />
                </linearGradient>
                <radialGradient
                    id="paint38_radial_10025_65882"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(240.03 219.662) rotate(1.36393) scale(67.736 31.6774)"
                >
                    <stop stopColor="#42E8E0" stopOpacity="0" />
                    <stop
                        offset="0.18"
                        stopColor="#42E8E0"
                        stopOpacity="0.25"
                    />
                    <stop offset="0.833401" stopColor="#42E8E0" />
                    <stop offset="1" stopColor="#D7F9EF" />
                </radialGradient>
                <radialGradient
                    id="paint39_radial_10025_65882"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(247.658 213.622) scale(93.3566 43.6163)"
                >
                    <stop stopColor="#BCDBD5" stopOpacity="0.8" />
                    <stop offset="0.71" stopColor="#42E8E0" />
                    <stop offset="1" stopColor="#BDE9DC" />
                </radialGradient>
                <radialGradient
                    id="paint40_radial_10025_65882"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(6604.02 2346.26) scale(2290.62 1123.84)"
                >
                    <stop stopColor="#42E8E0" stopOpacity="0" />
                    <stop
                        offset="0.18"
                        stopColor="#42E8E0"
                        stopOpacity="0.25"
                    />
                    <stop offset="0.71" stopColor="#42E8E0" />
                    <stop offset="1" stopColor="#D7F9EF" />
                </radialGradient>
                <radialGradient
                    id="paint41_radial_10025_65882"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(235.529 220.498) rotate(1.4231) scale(66.9333 32.659)"
                >
                    <stop stopColor="#42E8E0" stopOpacity="0" />
                    <stop
                        offset="0.18"
                        stopColor="#42E8E0"
                        stopOpacity="0.25"
                    />
                    <stop offset="0.833401" stopColor="#42E8E0" />
                    <stop offset="1" stopColor="#D7F9EF" />
                </radialGradient>
                <radialGradient
                    id="paint42_radial_10025_65882"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(2758.17 2023.66) scale(231.795 231.005)"
                >
                    <stop stopColor="#42E8E0" stopOpacity="0" />
                    <stop
                        offset="0.18"
                        stopColor="#42E8E0"
                        stopOpacity="0.25"
                    />
                    <stop offset="0.71" stopColor="#42E8E0" />
                    <stop offset="1" stopColor="#D7F9EF" />
                </radialGradient>
                <radialGradient
                    id="paint43_radial_10025_65882"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(280.47 261.1) scale(27.755 27.4829)"
                >
                    <stop stopColor="#BCDBD5" stopOpacity="0.8" />
                    <stop offset="0.71" stopColor="#42E8E0" />
                    <stop offset="1" stopColor="#BDE9DC" />
                </radialGradient>
                <radialGradient
                    id="paint44_radial_10025_65882"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(2758.17 2023.66) scale(231.795 231.005)"
                >
                    <stop stopColor="#42E8E0" stopOpacity="0" />
                    <stop
                        offset="0.18"
                        stopColor="#42E8E0"
                        stopOpacity="0.25"
                    />
                    <stop offset="0.71" stopColor="#42E8E0" />
                    <stop offset="1" stopColor="#D7F9EF" />
                </radialGradient>
                <radialGradient
                    id="paint45_radial_10025_65882"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(280.47 261.1) scale(27.755 27.4829)"
                >
                    <stop stopColor="#BCDBD5" stopOpacity="0.8" />
                    <stop offset="0.71" stopColor="#42E8E0" />
                    <stop offset="1" stopColor="#BDE9DC" />
                </radialGradient>
                <radialGradient
                    id="paint46_radial_10025_65882"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(2591.27 2054.28) scale(280.168 298.864)"
                >
                    <stop stopColor="#42E8E0" stopOpacity="0" />
                    <stop
                        offset="0.18"
                        stopColor="#42E8E0"
                        stopOpacity="0.25"
                    />
                    <stop offset="0.71" stopColor="#42E8E0" />
                    <stop offset="1" stopColor="#D7F9EF" />
                </radialGradient>
                <radialGradient
                    id="paint47_radial_10025_65882"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(275.207 265.402) rotate(3.09178) scale(19.6047 20.7505)"
                >
                    <stop stopColor="#42E8E0" stopOpacity="0" />
                    <stop
                        offset="0.18"
                        stopColor="#42E8E0"
                        stopOpacity="0.25"
                    />
                    <stop offset="0.833401" stopColor="#42E8E0" />
                    <stop offset="1" stopColor="#D7F9EF" />
                </radialGradient>
                <radialGradient
                    id="paint48_radial_10025_65882"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(1457.51 1303.19) scale(64.8943 64.1104)"
                >
                    <stop stopColor="#42E8E0" stopOpacity="0" />
                    <stop
                        offset="0.18"
                        stopColor="#42E8E0"
                        stopOpacity="0.25"
                    />
                    <stop offset="0.71" stopColor="#42E8E0" />
                    <stop offset="1" stopColor="#D7F9EF" />
                </radialGradient>
                <radialGradient
                    id="paint49_radial_10025_65882"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(256.011 280.829) scale(14.6547 14.3848)"
                >
                    <stop stopColor="#BCDBD5" stopOpacity="0.8" />
                    <stop offset="0.71" stopColor="#42E8E0" />
                    <stop offset="1" stopColor="#BDE9DC" />
                </radialGradient>
                <radialGradient
                    id="paint50_radial_10025_65882"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(1457.51 1303.19) scale(64.8943 64.1104)"
                >
                    <stop stopColor="#42E8E0" stopOpacity="0" />
                    <stop
                        offset="0.18"
                        stopColor="#42E8E0"
                        stopOpacity="0.25"
                    />
                    <stop offset="0.71" stopColor="#42E8E0" />
                    <stop offset="1" stopColor="#D7F9EF" />
                </radialGradient>
                <radialGradient
                    id="paint51_radial_10025_65882"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(256.011 280.829) scale(14.6547 14.3848)"
                >
                    <stop stopColor="#BCDBD5" stopOpacity="0.8" />
                    <stop offset="0.71" stopColor="#42E8E0" />
                    <stop offset="1" stopColor="#BDE9DC" />
                </radialGradient>
                <radialGradient
                    id="paint52_radial_10025_65882"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(1402.11 1343.27) scale(78.7295 83.5909)"
                >
                    <stop stopColor="#42E8E0" stopOpacity="0" />
                    <stop
                        offset="0.18"
                        stopColor="#42E8E0"
                        stopOpacity="0.25"
                    />
                    <stop offset="0.71" stopColor="#42E8E0" />
                    <stop offset="1" stopColor="#D7F9EF" />
                </radialGradient>
                <radialGradient
                    id="paint53_radial_10025_65882"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(253.422 283.079) rotate(3.07736) scale(10.4048 10.9618)"
                >
                    <stop stopColor="#42E8E0" stopOpacity="0" />
                    <stop
                        offset="0.18"
                        stopColor="#42E8E0"
                        stopOpacity="0.25"
                    />
                    <stop offset="0.833401" stopColor="#42E8E0" />
                    <stop offset="1" stopColor="#D7F9EF" />
                </radialGradient>
                <radialGradient
                    id="paint54_radial_10025_65882"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(37.6049 167.52) scale(42.7906)"
                >
                    <stop stopColor="#42E8E0" stopOpacity="0" />
                    <stop
                        offset="0.18"
                        stopColor="#42E8E0"
                        stopOpacity="0.25"
                    />
                    <stop offset="0.71" stopColor="#42E8E0" />
                    <stop offset="1" stopColor="#D7F9EF" />
                </radialGradient>
                <radialGradient
                    id="paint55_radial_10025_65882"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(37.5659 167.707) scale(42.9326 42.8905)"
                >
                    <stop stopColor="#C1E8E0" stopOpacity="0.8" />
                    <stop offset="0.71" stopColor="#42E8E0" />
                    <stop offset="1" stopColor="#D7F9EF" />
                </radialGradient>
                <radialGradient
                    id="paint56_radial_10025_65882"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(37.5659 167.707) scale(42.9326 42.8905)"
                >
                    <stop stopColor="#C1E8E0" stopOpacity="0.8" />
                    <stop offset="0.71" stopColor="#42E8E0" />
                    <stop offset="1" stopColor="#D7F9EF" />
                </radialGradient>
                <radialGradient
                    id="paint57_radial_10025_65882"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(37.6049 167.52) scale(42.7906)"
                >
                    <stop stopColor="#42E8E0" stopOpacity="0" />
                    <stop
                        offset="0.18"
                        stopColor="#42E8E0"
                        stopOpacity="0.25"
                    />
                    <stop offset="0.71" stopColor="#42E8E0" />
                    <stop offset="1" stopColor="#D7F9EF" />
                </radialGradient>
                <radialGradient
                    id="paint58_radial_10025_65882"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(37.5659 167.707) scale(42.9326 42.8905)"
                >
                    <stop stopColor="#C1E8E0" stopOpacity="0.8" />
                    <stop offset="0.71" stopColor="#42E8E0" />
                    <stop offset="1" stopColor="#D7F9EF" />
                </radialGradient>
                <radialGradient
                    id="paint59_radial_10025_65882"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(37.5659 167.707) scale(42.9326 42.8905)"
                >
                    <stop stopColor="#C1E8E0" stopOpacity="0.8" />
                    <stop offset="0.71" stopColor="#42E8E0" />
                    <stop offset="1" stopColor="#D7F9EF" />
                </radialGradient>
                <radialGradient
                    id="paint60_radial_10025_65882"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(33.0263 168.257) scale(41.6671 43.7813)"
                >
                    <stop stopColor="#C1E8E0" stopOpacity="0.8" />
                    <stop offset="0.71" stopColor="#42E8E0" />
                    <stop offset="1" stopColor="#D7F9EF" />
                </radialGradient>
                <radialGradient
                    id="paint61_radial_10025_65882"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(43.9911 225.016) scale(59.8487)"
                >
                    <stop stopColor="#C1E8E0" stopOpacity="0.8" />
                    <stop offset="0.71" stopColor="#42E8E0" />
                    <stop offset="1" stopColor="#D7F9EF" />
                </radialGradient>
                <radialGradient
                    id="paint62_radial_10025_65882"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(37.5552 226.08) scale(58.1264 61.3334)"
                >
                    <stop stopColor="#C1E8E0" stopOpacity="0.8" />
                    <stop offset="0.71" stopColor="#42E8E0" />
                    <stop offset="1" stopColor="#D7F9EF" />
                </radialGradient>
            </defs>
        </svg>
    );
};

export default marketplace;
