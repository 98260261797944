import React from 'react';
import type { Size } from '../../types';

const bundle = (width: Size = '303', height: Size = '398') => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 303 398"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M284.732 225.46C288.042 198.31 283.226 170.788 270.895 146.375C258.564 121.962 239.27 101.754 215.455 88.3048C191.639 74.856 164.37 68.7708 137.096 70.8187C109.822 72.8666 83.7682 82.9557 62.2279 99.8102C40.6876 116.665 24.6286 139.528 16.0813 165.509C7.53404 191.489 6.88238 219.421 14.2087 245.772C21.5351 272.124 36.5104 295.711 57.2413 313.551C77.9721 331.392 103.528 342.685 130.676 346.003C148.707 348.206 166.995 346.836 184.497 341.97C201.998 337.104 218.369 328.838 232.675 317.644C246.981 306.45 258.942 292.548 267.874 276.73C276.806 260.913 282.535 243.491 284.732 225.46Z"
                fill="#8227B7"
            />
            <path
                opacity="0.4"
                d="M190.465 151.294C188.632 152.291 184.869 153.385 183.036 154.51C176.359 157.682 170.421 162.218 165.604 167.825C155.956 180.176 156.856 195.581 169.689 210.022C188.986 231.603 228.288 238.968 259.421 228.515C289.364 217.162 298.208 190.403 279.265 169.916C271.482 160.685 257.073 153.513 242.182 149.332C234.505 147.201 226.575 146.119 218.607 146.116C210.824 147.113 201.143 149.204 190.465 151.294Z"
                fill="url(#paint0_linear_10025_65880)"
            />
            <path
                d="M184.355 108.615L199.664 109.837L216.356 113.857C216.356 113.857 222.563 110.963 213.14 94.721C207.157 84.397 201.69 84.6543 191.848 71.5322C183.679 61.4655 181.524 55.9336 176.282 55.4512C169.528 64.1028 182.972 99.5132 184.355 108.615Z"
                fill="url(#paint1_linear_10025_65880)"
            />
            <path
                d="M156.888 135.47C156.888 135.47 150.198 117.717 158.561 91.8907C162.418 79.1035 168.036 66.9152 175.253 55.6763C176.217 54.2612 177.44 55.3868 177.89 56.1587C177.89 65.2606 191.784 82.4351 197.187 102.987L156.888 135.47Z"
                fill="url(#paint2_linear_10025_65880)"
            />
            <path
                d="M264.213 204.844C289.943 167.279 249.386 110.963 220.215 102.89C191.044 94.8175 162.967 115.273 148.333 150.683C136.401 179.404 150.584 204.394 170.203 217.226C201.079 237.488 246.202 233.854 264.213 204.844Z"
                fill="url(#paint3_radial_10025_65880)"
            />
            <g opacity="0.8">
                <g opacity="0.8">
                    <path
                        opacity="0.8"
                        d="M208.669 159.881C214.008 161.2 218.317 166.314 220.215 174.805C223.11 187.477 215.326 201.274 202.011 198.379C190.465 195.935 186.606 189.631 186.606 179.082C186.606 168.533 196.672 155.379 208.669 159.881Z"
                        fill="#318CB6"
                    />
                </g>
            </g>
            <g opacity="0.8">
                <g opacity="0.8">
                    <path
                        opacity="0.8"
                        d="M161.294 138.558C171.715 134.988 178.469 148.206 176.378 161.94C175.092 172.457 168.145 179.982 162.613 176.863C152.193 170.848 149.941 162.583 150.585 156.601C151.228 150.619 153.125 141.292 161.294 138.558Z"
                        fill="#3493B9"
                    />
                </g>
            </g>
            <path
                d="M257.748 151.584L258.005 152.163C259.215 150.223 259.923 148.012 260.064 145.73V145.216C260.097 143.271 259.56 141.359 258.52 139.716C258.942 141.263 259.222 142.846 259.356 144.444C259.292 146.906 258.746 149.332 257.748 151.584Z"
                fill="#744984"
            />
            <path
                d="M231.247 176.895L170.879 143.865C168.659 142.675 166.858 144.09 166.858 147.081L148.655 162.39C146.21 162.39 144.634 187.541 147.272 190.886L196.222 217.998C204.842 222.598 222.563 214.3 227.902 201.917C232.726 190.725 234.592 184.936 234.592 184.936C234.703 183.427 234.459 181.913 233.878 180.517C233.297 179.12 232.395 177.879 231.247 176.895Z"
                fill="url(#paint4_linear_10025_65880)"
            />
            <path
                d="M224.396 191.626L233.82 185.901L228.803 182.524L222.627 183.875L224.396 191.626Z"
                fill="url(#paint5_linear_10025_65880)"
            />
            <path
                d="M154.734 126.915L164.382 121.094L167.952 133.444C167.952 133.444 162.71 135.856 162.838 135.888C162.967 135.921 154.734 126.915 154.734 126.915Z"
                fill="url(#paint6_linear_10025_65880)"
            />
            <path
                d="M190.723 159.141C190.723 159.141 199.438 172.231 204.938 175.222C210.438 178.213 230.668 185.868 230.668 185.868C233.241 187.283 235.363 185.611 235.363 182.169V164.319C235.363 160.589 236.007 159.206 233.788 157.887C231.72 156.964 229.565 156.253 227.355 155.764C221.148 154.478 193.81 141.388 182.328 135.341C172.68 130.228 168.949 128.587 167.566 123.056C167.701 122.013 168.04 121.008 168.563 120.097C168.563 120.097 162.13 120.933 162.13 123.506L162.516 142.803C162.87 146.116 188.117 157.726 190.723 159.141Z"
                fill="url(#paint7_linear_10025_65880)"
            />
            <path
                d="M212.27 198.701L224.234 191.722L210.758 179.082L208.828 180.112L212.27 198.701Z"
                fill="url(#paint8_radial_10025_65880)"
            />
            <path
                d="M142.96 133.798L155.15 126.658L161.55 144.476L142.96 133.798Z"
                fill="url(#paint9_radial_10025_65880)"
            />
            <path
                d="M162.547 154.189L221.404 191.465C223.977 192.848 226.099 191.175 226.099 187.734V169.884C226.075 168.147 225.636 166.441 224.82 164.909C224.003 163.376 222.832 162.06 221.404 161.072L158.302 126.69C155.729 125.275 153.606 126.948 153.606 130.389L157.755 145.377C157.776 147.124 158.224 148.841 159.059 150.376C159.894 151.912 161.092 153.221 162.547 154.189Z"
                fill="url(#paint10_radial_10025_65880)"
            />
            <path
                d="M146.05 164.287L209.087 198.668C211.66 200.051 213.783 198.379 213.783 194.938V177.088C213.764 175.346 213.328 173.633 212.511 172.094C211.694 170.556 210.52 169.235 209.087 168.243L146.05 133.894C143.477 132.511 141.354 134.151 141.354 137.625V155.378C141.363 157.131 141.794 158.857 142.612 160.407C143.429 161.958 144.608 163.289 146.05 164.287Z"
                fill="url(#paint11_linear_10025_65880)"
            />
            <path
                d="M207.254 201.756L212.271 198.54L210.76 195.002C210.76 195.002 204.745 197.736 204.745 197.929C204.745 198.122 207.254 201.756 207.254 201.756Z"
                fill="url(#paint12_linear_10025_65880)"
            />
            <path
                d="M138.105 136.724L142.962 133.798L145.02 135.792L140.518 140.037L138.105 136.724Z"
                fill="url(#paint13_linear_10025_65880)"
            />
            <path
                d="M141.097 167.279L204.134 201.66C206.707 203.075 208.83 201.403 208.83 197.961V180.111C208.785 178.401 208.337 176.725 207.521 175.222C206.705 173.718 205.544 172.429 204.134 171.46L141.097 136.918C138.524 135.503 136.401 137.175 136.401 140.616V158.595C136.44 160.311 136.886 161.993 137.703 163.502C138.519 165.012 139.682 166.306 141.097 167.279Z"
                fill="url(#paint14_linear_10025_65880)"
            />
            <path
                d="M204.134 171.46L161.327 148.239C154.219 144.444 147.047 140.359 139.971 136.853C139.547 136.739 139.099 136.751 138.682 136.888C138.264 137.025 137.897 137.282 137.623 137.625C137.057 138.388 136.752 139.313 136.755 140.262V146.309C136.755 150.361 136.755 154.414 136.497 158.466V140.23C136.515 139.194 136.84 138.187 137.43 137.336C137.758 136.92 138.202 136.61 138.705 136.444C139.208 136.278 139.749 136.264 140.26 136.403C147.529 140.037 154.508 144.122 161.648 147.949L204.134 171.46Z"
                fill="url(#paint15_linear_10025_65880)"
            />
            <path
                d="M202.815 172.811L142.415 139.812C140.228 138.59 138.427 140.038 138.427 143.029V158.306C138.449 159.786 138.822 161.24 139.515 162.548C140.209 163.857 141.202 164.982 142.415 165.832L202.815 198.765C205.002 199.988 206.836 198.54 206.836 195.549V180.369C206.812 178.88 206.436 177.419 205.737 176.105C205.038 174.791 204.036 173.662 202.815 172.811Z"
                fill="url(#paint16_linear_10025_65880)"
            />
            <path
                d="M202.815 172.81L163.031 151.197L143.155 140.391C142.423 139.844 141.527 139.561 140.614 139.587C140.188 139.686 139.798 139.9 139.486 140.207C139.174 140.513 138.952 140.9 138.845 141.324C138.626 142.228 138.54 143.16 138.588 144.09V146.92C138.588 150.683 138.588 154.446 138.588 158.241C138.588 154.446 138.588 150.683 138.588 146.92V144.09C138.513 143.128 138.6 142.16 138.845 141.227C138.956 140.73 139.21 140.276 139.576 139.922C139.941 139.567 140.403 139.328 140.904 139.233C141.897 139.2 142.873 139.506 143.67 140.101L163.481 151.004L202.815 172.81Z"
                fill="url(#paint17_linear_10025_65880)"
            />
            <path
                d="M156.567 151.937L187.056 168.661V166.506L156.567 149.782V151.937Z"
                fill="white"
            />
            <path
                d="M223.174 187.219L217.996 190.435C216.999 191.046 216.195 190.435 216.195 189.148V182.201C216.205 181.522 216.373 180.855 216.686 180.252C216.998 179.649 217.447 179.127 217.996 178.728L223.174 175.512C224.171 174.9 224.975 175.512 224.975 176.798V183.713C224.97 184.397 224.805 185.071 224.492 185.68C224.179 186.289 223.728 186.816 223.174 187.219Z"
                fill="url(#paint18_linear_10025_65880)"
            />
            <path
                d="M223.142 174.547L222.467 174.161L222.241 174.386L222.917 174.965L223.142 174.547Z"
                fill="#BFB9DB"
            />
            <path
                d="M222.627 186.994L217.449 190.21C216.452 190.789 215.648 190.21 215.648 188.891V181.944C215.668 181.267 215.84 180.603 216.152 180.001C216.464 179.4 216.907 178.877 217.449 178.471L222.627 175.254C223.624 174.675 224.428 175.254 224.428 176.541V183.488C224.414 184.171 224.244 184.841 223.932 185.448C223.62 186.056 223.174 186.584 222.627 186.994Z"
                fill="url(#paint19_linear_10025_65880)"
            />
            <path
                d="M222.628 181.365C222.611 182.202 222.405 183.024 222.026 183.77C221.648 184.516 221.106 185.167 220.441 185.675C219.218 186.415 218.221 185.675 218.221 184.067C218.242 183.232 218.453 182.413 218.838 181.672C219.222 180.931 219.77 180.287 220.441 179.789C221.502 179.05 222.628 179.757 222.628 181.365Z"
                fill="url(#paint20_linear_10025_65880)"
            />
            <path
                d="M234.142 164.963L231.569 166.603V184.131L234.142 182.555V164.963Z"
                fill="url(#paint21_linear_10025_65880)"
            />
            <path
                d="M259.581 158.595V145.73C259.462 148.011 258.765 150.224 257.555 152.162C255.759 154.969 253.405 157.377 250.64 159.238C245.263 162.867 239.393 165.705 233.209 167.664L232.855 168.147L233.209 181.108C248.711 176.316 259.581 168.018 259.581 158.595Z"
                fill="url(#paint22_linear_10025_65880)"
            />
            <path
                d="M260.128 158.595V145.73C259.967 148.017 259.238 150.228 258.005 152.162C256.173 155.01 253.776 157.451 250.962 159.334C245.354 163.095 239.234 166.031 232.79 168.05V181.912C248.871 177.088 260.064 168.597 260.064 159.013L260.128 158.595Z"
                fill="url(#paint23_linear_10025_65880)"
            />
            <path
                d="M231.569 166.603L230.668 166.153V183.617L231.569 184.132V166.603Z"
                fill="url(#paint24_radial_10025_65880)"
            />
            <path
                d="M230.668 166.153L231.569 166.603L234.141 164.963L233.305 164.545L230.668 166.153Z"
                fill="white"
            />
            <path
                d="M178.63 200.984V203.171C178.63 206.999 176.475 208.703 173.677 206.999C172.136 205.905 170.879 204.458 170.012 202.78C169.145 201.101 168.692 199.239 168.692 197.35V194.134L171.618 197.35L174.224 197.832C175.253 199.762 176.282 201.274 178.63 200.984Z"
                fill="#FA4A76"
            />
            <path
                d="M174.127 198.186L171.522 197.704L168.595 194.488V197.704C168.628 199.541 169.114 201.342 170.01 202.946C170.056 201.804 170.489 200.711 171.236 199.847C171.984 198.982 173.003 198.397 174.127 198.186Z"
                fill="#020A21"
            />
            <path
                d="M180.817 190.757C180.173 189.116 179.723 187.541 175.124 185.096C170.525 182.652 167.276 182.395 166.89 183.456V183.713C166.472 184.935 167.694 186.929 172.133 190.853L172.776 191.496H173.033C172.776 191.496 172.776 191.496 172.776 191.754H173.291C173.516 192.011 173.516 192.011 173.677 192.011C173.837 192.011 174.352 192.236 174.513 192.236C176.121 193.458 181.138 193.458 180.817 190.757Z"
                fill="url(#paint25_radial_10025_65880)"
            />
            <path
                d="M175.092 186.64C176.025 187.09 177.247 188.055 176.957 188.795C176.668 189.535 175.735 189.277 175.092 188.795C174.449 188.312 173.194 187.38 173.194 186.64C173.194 185.9 174.127 185.965 175.092 186.64Z"
                fill="#CCCCCC"
            />
            <path
                d="M182.007 197.607C182.235 197.769 182.423 197.98 182.557 198.226C182.69 198.471 182.766 198.744 182.779 199.023C182.751 199.804 182.482 200.557 182.007 201.177C181.556 202.11 180.334 202.593 177.986 201.949L176.571 201.177C175.887 200.694 175.261 200.133 174.706 199.505C174.223 198.733 173.773 198.09 173.484 197.607C173.219 197.017 173.056 196.387 173.001 195.742V192.526C173.001 192.043 173.291 191.754 173.773 192.043C173.995 192.208 174.177 192.421 174.305 192.666C174.433 192.911 174.504 193.182 174.513 193.459C174.513 193.459 174.802 195.613 174.802 195.806C174.658 196.582 174.658 197.378 174.802 198.154C174.914 198.514 175.096 198.847 175.34 199.135C175.583 199.422 175.881 199.657 176.218 199.827C178.823 201.531 179.755 200.599 180.238 199.827C180.519 199.417 180.685 198.939 180.72 198.444C180.817 197.447 181.749 197.607 182.007 197.607Z"
                fill="#020A21"
            />
            <path
                d="M174.384 191.432C174.615 191.583 174.806 191.788 174.941 192.029C175.075 192.27 175.149 192.54 175.156 192.815V196.031C175.127 196.452 175.029 196.865 174.867 197.254C174.577 197.704 174.384 198.476 173.452 198.669C172.794 198.721 172.137 198.551 171.586 198.186C169.202 196.743 167.351 194.565 166.312 191.979C165.833 191.089 165.579 190.095 165.572 189.084C165.572 188.602 165.861 188.313 166.312 188.602C166.543 188.76 166.734 188.971 166.868 189.217C167.002 189.463 167.076 189.737 167.084 190.017C167.368 192.272 168.522 194.326 170.3 195.742C170.664 196.105 171.075 196.419 171.522 196.675C171.972 196.964 172.455 197.157 172.744 196.964C172.864 196.949 172.975 196.893 173.057 196.805C173.139 196.716 173.188 196.602 173.194 196.482C173.41 196.112 173.511 195.687 173.484 195.26V192.043C173.387 191.239 173.87 190.95 174.384 191.432Z"
                fill="#020A21"
            />
            <path
                d="M279.554 95.6539C275.695 92.4377 271.868 93.4991 259.743 106.911C247.618 120.322 241.346 116.559 231.311 126.819C228.254 129.437 226.162 133.005 225.369 136.951C224.576 140.898 225.128 144.997 226.937 148.593C229.253 153.571 232.533 158.042 236.586 161.747L246.91 167.954L255.272 148.335C258.52 137.336 285.537 107.393 279.554 95.6539Z"
                fill="url(#paint26_linear_10025_65880)"
            />
            <path
                d="M240.124 138.268C251.863 116.495 269.327 117.427 276.724 97.1652C276.852 96.8205 277.082 96.5232 277.384 96.3133C277.686 96.1034 278.045 95.9908 278.412 95.9908C278.78 95.9908 279.139 96.1034 279.441 96.3133C279.743 96.5232 279.973 96.8205 280.101 97.1652C283.317 103.823 288.463 112.732 290.907 135.181C291.574 140.141 291.173 145.186 289.731 149.979C288.288 154.771 285.839 159.2 282.545 162.969C267.654 181.848 223.624 168.887 240.124 138.268Z"
                fill="url(#paint27_linear_10025_65880)"
            />
            <path
                d="M152.997 161.393C153.106 161.452 153.197 161.541 153.26 161.648C153.322 161.756 153.354 161.879 153.351 162.004V165.863L150.134 163.934C150.025 163.866 149.934 163.772 149.872 163.659C149.81 163.546 149.779 163.419 149.781 163.29V159.431L152.997 161.393Z"
                fill="url(#paint28_linear_10025_65880)"
            />
            <path
                d="M160.49 157.437C160.6 157.504 160.69 157.598 160.752 157.711C160.814 157.824 160.846 157.951 160.844 158.08V161.811L157.628 159.881C157.52 159.812 157.431 159.716 157.369 159.604C157.308 159.492 157.275 159.366 157.274 159.238V155.378L160.49 157.437Z"
                fill="url(#paint29_linear_10025_65880)"
            />
            <g opacity="0.4">
                <path
                    opacity="0.4"
                    d="M170.75 169.916C170.854 169.983 170.94 170.073 171.001 170.179C171.063 170.285 171.098 170.405 171.104 170.527V174.387L167.888 172.457C167.778 172.39 167.688 172.295 167.626 172.182C167.563 172.07 167.532 171.942 167.534 171.814V167.986L170.75 169.916Z"
                    fill="url(#paint30_linear_10025_65880)"
                />
            </g>
            <g opacity="0.4">
                <path
                    opacity="0.4"
                    d="M200.113 176.606C200.222 176.666 200.313 176.754 200.376 176.862C200.438 176.97 200.47 177.093 200.466 177.217V181.077L197.25 179.147C197.14 179.08 197.05 178.985 196.988 178.872C196.926 178.76 196.894 178.633 196.896 178.504V174.677L200.113 176.606Z"
                    fill="url(#paint31_linear_10025_65880)"
                />
            </g>
            <path
                d="M185.673 168.951C185.787 169.017 185.882 169.11 185.95 169.223C186.017 169.335 186.055 169.463 186.059 169.594V173.422L182.843 171.492C182.733 171.433 182.642 171.344 182.58 171.236C182.517 171.129 182.486 171.006 182.489 170.881V167.021L185.673 168.951Z"
                fill="url(#paint32_linear_10025_65880)"
            />
            <path
                d="M189.243 180.465C189.353 180.524 189.444 180.612 189.507 180.72C189.569 180.828 189.6 180.951 189.597 181.076V184.935L186.381 183.005C186.271 182.938 186.181 182.843 186.119 182.731C186.057 182.618 186.025 182.491 186.027 182.362V178.535L189.243 180.465Z"
                fill="url(#paint33_linear_10025_65880)"
            />
            <path
                d="M203.329 186.255C203.439 186.322 203.529 186.416 203.591 186.529C203.653 186.642 203.685 186.769 203.683 186.898V190.725L200.467 188.795C200.357 188.736 200.266 188.648 200.203 188.54C200.141 188.432 200.11 188.309 200.113 188.184V184.325L203.329 186.255Z"
                fill="url(#paint34_linear_10025_65880)"
            />
            <g opacity="0.4">
                <path
                    opacity="0.4"
                    d="M189.436 175.962C189.546 176.021 189.637 176.11 189.699 176.218C189.762 176.326 189.793 176.449 189.79 176.573V180.433L186.574 178.503C186.464 178.436 186.373 178.341 186.311 178.228C186.249 178.115 186.218 177.988 186.22 177.86V174L189.436 175.962Z"
                    fill="url(#paint35_linear_10025_65880)"
                />
            </g>
            <g opacity="0.4">
                <path
                    opacity="0.4"
                    d="M157.789 161.072C157.951 161.11 158.093 161.207 158.188 161.343C158.282 161.48 158.324 161.646 158.303 161.811V165.671L155.087 163.741C154.974 163.676 154.878 163.582 154.811 163.47C154.743 163.357 154.706 163.229 154.701 163.098V159.142L157.789 161.072Z"
                    fill="url(#paint36_linear_10025_65880)"
                />
            </g>
            <g opacity="0.4">
                <path
                    opacity="0.4"
                    d="M166.087 160.621C166.196 160.68 166.287 160.769 166.35 160.876C166.412 160.984 166.444 161.107 166.44 161.232V165.027L163.224 163.097C163.109 163.036 163.015 162.942 162.952 162.828C162.889 162.714 162.861 162.584 162.87 162.454V158.595L166.087 160.621Z"
                    fill="url(#paint37_linear_10025_65880)"
                />
            </g>
            <g opacity="0.4">
                <path
                    opacity="0.4"
                    d="M171.811 163.837C171.921 163.904 172.012 163.999 172.074 164.112C172.136 164.225 172.167 164.352 172.165 164.48V168.243L168.788 166.281C168.679 166.222 168.588 166.134 168.525 166.026C168.463 165.918 168.431 165.795 168.434 165.67V161.811L171.811 163.837Z"
                    fill="url(#paint38_linear_10025_65880)"
                />
            </g>
            <g opacity="0.4">
                <path
                    opacity="0.4"
                    d="M178.79 166.346C178.904 166.404 178.999 166.491 179.067 166.598C179.135 166.706 179.173 166.83 179.176 166.957V170.816L175.96 168.887C175.85 168.819 175.76 168.725 175.698 168.612C175.636 168.499 175.604 168.372 175.606 168.243V164.384L178.79 166.346Z"
                    fill="url(#paint39_linear_10025_65880)"
                />
            </g>
            <g opacity="0.4">
                <path
                    opacity="0.4"
                    d="M204.07 183.97C204.18 184.03 204.271 184.118 204.333 184.226C204.396 184.334 204.427 184.457 204.424 184.582V188.441L201.208 186.511C201.098 186.444 201.007 186.349 200.945 186.237C200.883 186.124 200.852 185.997 200.854 185.868V182.041L204.07 183.97Z"
                    fill="url(#paint40_linear_10025_65880)"
                />
            </g>
            <g opacity="0.4">
                <path
                    opacity="0.4"
                    d="M180.817 175.094C180.93 175.152 181.026 175.239 181.093 175.346C181.161 175.454 181.199 175.578 181.203 175.705V179.564L177.986 177.635C177.876 177.567 177.786 177.473 177.724 177.36C177.662 177.247 177.63 177.12 177.633 176.991V173.164L180.817 175.094Z"
                    fill="url(#paint41_linear_10025_65880)"
                />
            </g>
            <path
                opacity="0.4"
                d="M217.405 312.652C215.317 313.191 211.909 313.798 209.793 314.361C201.936 316.346 194.274 318.82 186.876 321.759C170.389 328.506 158.436 337.142 155.605 345.148C151.428 357.134 172.917 361.16 202.786 355.425C232.628 349.128 260.492 334.286 263.899 322.906C265.988 317.779 261.783 313.911 254.859 311.482C250.398 310.229 245.696 309.65 240.982 309.773C234.717 310.335 226.418 311.482 217.405 312.652Z"
                fill="url(#paint42_linear_10025_65880)"
            />
            <path
                d="M235.653 239L221.437 240.126L205.807 243.889C205.807 243.889 200.018 241.187 209.023 226.071C214.619 216.422 219.701 216.712 228.867 204.49C236.457 195.131 238.516 189.985 243.34 189.535C249.386 197.415 236.972 230.349 235.653 239Z"
                fill="url(#paint43_linear_10025_65880)"
            />
            <path
                d="M235.653 239L221.437 240.126L205.807 243.889C205.807 243.889 200.018 241.187 209.023 226.071C214.619 216.422 219.701 216.712 228.867 204.49C236.457 195.131 238.516 189.985 243.34 189.535C249.386 197.415 236.972 230.349 235.653 239Z"
                fill="url(#paint44_linear_10025_65880)"
            />
            <path
                d="M256.687 261.996C256.687 261.996 266.335 246.687 258.552 222.662C252.763 205.101 247.714 194.52 244.111 189.631C243.243 188.313 242.117 189.374 241.667 190.082C241.667 198.54 228.802 214.525 223.689 233.693L256.687 261.996Z"
                fill="url(#paint45_linear_10025_65880)"
            />
            <path
                d="M161.262 328.411C137.237 293.451 175.06 241.026 202.205 233.5C229.349 225.975 255.497 245.047 269.102 277.981C280.198 304.739 267.011 327.96 248.775 339.957C217.482 360.476 178.019 355.33 161.262 328.411Z"
                fill="url(#paint46_radial_10025_65880)"
            />
            <path
                d="M154.605 272.48C153.929 271.773 153.318 271.162 153.929 270.744C154.54 270.326 155.216 269.425 155.666 270.036C156.374 270.036 156.374 271.355 156.374 271.773C156.17 272.039 155.898 272.245 155.586 272.37C155.275 272.494 154.936 272.532 154.605 272.48Z"
                fill="white"
            />
            <path
                d="M133.313 242.73C136.883 239.836 149.169 231.152 166.666 240.833C184.162 250.514 185.545 246.333 194.872 255.788C210.406 270.454 189.983 288.304 189.983 288.304L180.334 294.061L172.583 275.793C169.624 265.501 133.539 245.432 133.313 242.73Z"
                fill="url(#paint47_linear_10025_65880)"
            />
            <path
                d="M186.735 266.466C175.832 246.172 144.442 242.184 141.708 241.798C138.974 241.412 134.214 241.155 133.249 242.73C130.033 248.938 141.708 242.73 139.424 263.571C138.825 268.167 139.209 272.837 140.551 277.273C141.892 281.709 144.161 285.809 147.208 289.301C161.166 306.926 202.108 294.929 186.735 266.466Z"
                fill="url(#paint48_linear_10025_65880)"
            />
            <path
                d="M240.027 324.84V326.834C240.027 330.437 242.021 331.98 244.658 330.437C246.079 329.415 247.238 328.073 248.043 326.52C248.848 324.966 249.275 323.245 249.29 321.495V318.633L246.588 321.849L244.144 322.267C243.179 323.682 242.31 325.001 240.027 324.84Z"
                fill="#030922"
            />
            <path
                d="M240.027 324.84V326.834C240.027 330.437 242.021 331.98 244.658 330.437C246.079 329.415 247.238 328.073 248.043 326.52C248.848 324.966 249.275 323.245 249.29 321.495V318.633L246.588 321.849L244.144 322.267C243.179 323.682 242.31 325.001 240.027 324.84Z"
                fill="#FF6CD0"
            />
            <path
                d="M244.305 322.106L246.749 321.688L249.451 318.472V321.367C249.439 323.082 248.985 324.766 248.132 326.255C247.988 325.258 247.545 324.327 246.862 323.587C246.179 322.846 245.287 322.33 244.305 322.106Z"
                fill="#030922"
            />
            <path
                d="M236.714 315.481C237.108 314.036 237.834 312.702 238.833 311.586C239.832 310.469 241.078 309.601 242.471 309.049C247.36 306.154 251.123 306.605 251.541 307.73V307.988C251.991 309.306 250.673 311.204 245.977 315.578L245.141 316.189C245.398 316.189 245.398 316.189 245.398 316.446H244.883C244.594 316.736 244.594 316.736 244.433 316.736C244.272 316.736 243.726 316.993 243.565 316.993C241.603 318.344 236.264 318.344 236.714 315.481Z"
                fill="url(#paint49_radial_10025_65880)"
            />
            <path
                d="M243.34 311.461C242.471 311.911 241.313 312.779 241.603 313.455C241.892 314.13 242.729 313.905 243.34 313.455C243.951 313.005 245.076 312.168 245.076 311.461C245.076 310.753 244.305 310.753 243.34 311.461Z"
                fill="white"
            />
            <path
                d="M236.972 321.689C236.756 321.835 236.578 322.031 236.455 322.261C236.332 322.49 236.266 322.747 236.264 323.007C236.289 323.729 236.536 324.425 236.972 325.001C237.422 325.87 238.548 326.32 240.735 325.709L242.021 325.001C242.666 324.542 243.259 324.013 243.79 323.425C244.208 322.718 244.658 322.107 244.916 321.689C245.166 321.129 245.319 320.531 245.366 319.92V317.057C245.366 316.607 245.077 316.35 244.658 316.607C244.442 316.753 244.265 316.949 244.142 317.179C244.019 317.409 243.953 317.665 243.951 317.926C243.951 317.926 243.694 319.92 243.694 320.113C243.822 320.836 243.822 321.576 243.694 322.3C243.589 322.637 243.418 322.949 243.192 323.22C242.966 323.49 242.688 323.713 242.375 323.876C239.931 325.452 239.159 324.551 238.644 323.876C238.381 323.483 238.226 323.028 238.194 322.557C238.185 322.414 238.144 322.274 238.074 322.149C238.004 322.023 237.907 321.915 237.79 321.832C237.673 321.748 237.538 321.692 237.397 321.668C237.255 321.643 237.11 321.65 236.972 321.689Z"
                fill="#030922"
            />
            <path
                d="M244.047 315.899C243.837 316.051 243.664 316.248 243.541 316.477C243.418 316.705 243.349 316.959 243.34 317.218V320.113C243.365 320.499 243.452 320.879 243.597 321.238C243.886 321.689 244.047 322.364 244.916 322.557C245.528 322.604 246.139 322.446 246.652 322.107C248.888 320.79 250.62 318.763 251.573 316.35C252.03 315.522 252.273 314.593 252.281 313.648C252.281 313.198 252.023 312.94 251.573 313.198C251.367 313.351 251.199 313.55 251.081 313.778C250.964 314.007 250.901 314.26 250.898 314.516C250.573 316.651 249.417 318.571 247.681 319.855C247.326 320.169 246.95 320.459 246.556 320.724C246.106 320.981 245.687 321.142 245.398 320.981C245.284 320.973 245.178 320.922 245.1 320.838C245.023 320.755 244.98 320.645 244.98 320.531C244.788 320.188 244.699 319.797 244.723 319.405V316.51C244.916 315.642 244.497 315.481 244.047 315.899Z"
                fill="#030922"
            />
            <g opacity="0.8">
                <g opacity="0.8">
                    <path
                        opacity="0.8"
                        d="M257.684 265.148C247.199 261.546 240.542 274.797 242.568 288.562C243.854 299.047 250.769 306.573 256.365 303.517C266.85 297.471 269.037 289.27 268.426 283.223C267.815 277.177 265.885 267.753 257.684 265.148Z"
                        fill="#5DA7F3"
                    />
                </g>
            </g>
            <g opacity="0.8">
                <path
                    opacity="0.8"
                    d="M262.734 267.592C261.298 266.166 259.486 265.179 257.509 264.746C255.533 264.313 253.474 264.452 251.573 265.148C245.141 267.946 241.442 277.594 242.729 288.047C243.887 296.506 249.901 302.552 256.269 299.818C265.371 295.38 268.072 288.722 267.815 279.813C267.903 277.53 267.494 275.255 266.617 273.145C265.739 271.035 264.414 269.14 262.734 267.592Z"
                    fill="white"
                />
            </g>
            <path
                d="M254.339 268.3C249.451 270.744 245.881 275.633 246.138 285.41C246.138 292.068 252.152 298.95 258.199 296.506C263.345 294.319 269.102 287.597 266.657 276.951C265.081 270.551 258.359 266.274 254.339 268.3Z"
                fill="url(#paint50_radial_10025_65880)"
            />
            <path
                d="M254.339 268.3C249.451 270.744 245.881 275.633 246.138 285.41C246.138 292.068 252.152 298.95 258.199 296.506C263.345 294.319 269.102 287.597 266.657 276.951C265.081 270.551 258.359 266.274 254.339 268.3Z"
                fill="url(#paint51_radial_10025_65880)"
            />
            <path
                d="M258.295 272.384C258.295 272.384 258.295 272.384 258.102 272.384C257.569 272.599 257.099 272.944 256.734 273.388C256.368 273.832 256.12 274.36 256.012 274.925C256.012 275.697 256.269 277.015 257.137 277.176C258.006 277.337 259.324 275.697 259.678 274.828C260.032 273.96 259.775 271.966 258.295 272.384Z"
                fill="white"
            />
            <path
                d="M252.763 278.848V279.041C252.763 279.749 253.374 280.617 254.082 280.521C254.79 280.424 255.208 279.556 255.401 278.945C255.432 278.748 255.414 278.545 255.349 278.356C255.284 278.167 255.174 277.997 255.028 277.861C254.882 277.724 254.705 277.625 254.512 277.573C254.319 277.521 254.116 277.517 253.921 277.562C253.64 277.669 253.388 277.842 253.187 278.066C252.986 278.289 252.84 278.558 252.763 278.848Z"
                fill="white"
            />
            <path
                d="M261.415 292.742C260.739 293.193 260.128 293.45 259.421 293.9C257.52 295.132 255.733 296.531 254.082 298.081C250.319 301.683 247.649 305.414 247.875 308.148C247.875 312.329 253.921 312.329 261.672 307.891C269.423 303.452 275.502 296.505 275.502 292.324C275.536 291.893 275.476 291.46 275.326 291.055C275.176 290.649 274.941 290.281 274.635 289.975C274.329 289.669 273.961 289.433 273.555 289.284C273.15 289.134 272.717 289.074 272.286 289.108C271.012 289.139 269.752 289.378 268.555 289.816C266.011 290.332 263.589 291.325 261.415 292.742Z"
                fill="url(#paint52_radial_10025_65880)"
            />
            <path
                d="M264.438 269.328C264.438 269.328 258.874 261.995 251.573 265.147C245.141 267.945 241.346 277.594 242.632 288.047C242.917 289.735 243.358 291.393 243.951 293C244.814 289.623 245.888 286.304 247.167 283.062C249 278.334 252.41 272.127 258.778 270.197C260.617 269.655 262.521 269.363 264.438 269.328Z"
                fill="url(#paint53_radial_10025_65880)"
            />
            <path
                d="M244.047 293.032C244.598 290.243 245.329 287.493 246.234 284.798C247.053 282.062 248.289 279.469 249.901 277.112C251.538 274.722 253.651 272.696 256.108 271.162C256.72 270.802 257.354 270.479 258.005 270.197C258.687 269.901 259.385 269.643 260.096 269.425C261.517 269.077 262.975 268.904 264.438 268.91C265.052 268.824 265.642 268.616 266.175 268.299C266.657 267.981 267.029 267.52 267.236 266.98C267.442 266.409 267.442 265.783 267.236 265.212C267.152 264.903 266.998 264.618 266.786 264.378C266.574 264.138 266.31 263.95 266.014 263.829C266.366 263.895 266.699 264.04 266.988 264.251C267.277 264.462 267.516 264.736 267.686 265.051C268.047 265.684 268.162 266.429 268.008 267.141C267.935 267.512 267.79 267.866 267.58 268.18C267.37 268.495 267.1 268.765 266.786 268.975C266.178 269.367 265.502 269.64 264.792 269.779C263.414 269.774 262.041 269.947 260.707 270.293C259.378 270.664 258.11 271.228 256.944 271.966C254.426 273.365 252.233 275.284 250.512 277.594C248.874 279.872 247.574 282.374 246.652 285.023C245.622 287.637 244.752 290.312 244.047 293.032Z"
                fill="#0D1276"
            />
            <g opacity="0.8">
                <g opacity="0.8">
                    <path
                        opacity="0.8"
                        d="M210.245 289.076C204.906 290.298 200.596 294.865 198.731 302.359C195.836 313.648 203.62 325.869 216.967 323.328C228.513 321.141 232.34 315.642 232.34 306.122C232.34 296.602 222.306 284.991 210.245 289.076Z"
                        fill="#5090CD"
                    />
                    <path
                        opacity="0.8"
                        d="M210.502 287.95C205.163 289.172 200.853 293.707 198.988 301.233C196.093 312.522 203.877 324.744 217.224 322.203C228.77 320.016 232.597 314.516 232.597 304.996C232.597 295.476 222.917 284.027 210.502 287.95Z"
                        fill="white"
                    />
                </g>
            </g>
            <path
                d="M214.104 293.032C205.613 294.769 203.008 303.678 204.038 310.432C204.831 313.741 206.804 316.648 209.587 318.607C212.369 320.567 215.77 321.445 219.154 321.077C222.873 320.408 226.173 318.288 228.33 315.185C230.486 312.082 231.321 308.249 230.652 304.53C229.982 300.811 227.862 297.51 224.759 295.354C221.656 293.198 217.823 292.363 214.104 293.032Z"
                fill="url(#paint54_radial_10025_65880)"
            />
            <path
                d="M214.104 293.032C205.613 294.769 203.008 303.678 204.038 310.432C204.831 313.741 206.804 316.648 209.587 318.607C212.369 320.567 215.77 321.445 219.154 321.077C222.873 320.408 226.173 318.288 228.33 315.185C230.486 312.082 231.321 308.249 230.652 304.53C229.982 300.811 227.862 297.51 224.759 295.354C221.656 293.198 217.823 292.363 214.104 293.032Z"
                fill="url(#paint55_radial_10025_65880)"
            />
            <path
                d="M217.835 297.664L217.224 298.082C216.098 299.047 213.815 303.324 217.417 303.324C217.94 303.402 218.471 303.402 218.993 303.324C219.52 303.021 219.972 302.603 220.317 302.103C220.662 301.602 220.891 301.03 220.987 300.43C221.148 299.401 220.28 297.214 218.543 297.632C218.307 297.591 218.066 297.602 217.835 297.664Z"
                fill="white"
            />
            <path
                d="M220.022 317.765L217.578 318.376C215.082 319.064 212.632 319.913 210.245 320.917C204.938 323.265 201.175 326.223 200.114 329.054C198.795 333.17 205.71 334.553 215.23 332.656C224.75 330.758 233.659 325.291 234.785 321.367C235.492 319.63 234.077 318.151 231.89 317.443C230.459 316.977 228.956 316.77 227.452 316.832C225.619 316.961 222.917 317.411 220.022 317.765Z"
                fill="url(#paint56_radial_10025_65880)"
            />
            <path
                d="M210.245 286.729C204.906 287.951 200.596 293.643 198.892 301.137C198.613 302.497 198.409 303.872 198.281 305.254C198.281 305.254 201.497 305.962 210.502 298.436C217.932 292.003 231.536 298.436 231.536 298.436C231.536 298.436 225.522 283.512 210.245 286.729Z"
                fill="url(#paint57_radial_10025_65880)"
            />
            <path
                d="M195.129 300.269C194.997 300.818 194.984 301.388 195.089 301.942C195.195 302.497 195.416 303.023 195.74 303.485C196.033 303.886 196.415 304.214 196.856 304.443C197.296 304.671 197.784 304.795 198.281 304.804C198.569 304.846 198.861 304.846 199.149 304.804L200.178 304.546C200.886 304.289 201.497 304 202.205 303.678C203.52 302.95 204.797 302.156 206.032 301.298C207.254 300.43 208.476 299.561 209.634 298.596C210.237 298.086 210.871 297.614 211.532 297.181L212.593 296.667C212.929 296.486 213.285 296.346 213.654 296.248C216.629 295.363 219.773 295.209 222.82 295.798C224.328 296.052 225.812 296.428 227.259 296.924C228.7 297.384 230.108 297.942 231.472 298.596C228.658 297.487 225.721 296.72 222.724 296.313C219.806 295.8 216.805 296.044 214.008 297.02C213.662 297.111 213.335 297.263 213.043 297.471L212.078 297.985C211.466 298.393 210.875 298.834 210.309 299.304C209.184 300.269 207.962 301.201 206.739 302.102C205.492 303.01 204.18 303.827 202.816 304.546C202.104 304.906 201.374 305.228 200.629 305.511C200.275 305.511 199.857 305.768 199.503 305.833C199.069 305.931 198.618 305.931 198.184 305.833C197.523 305.837 196.873 305.66 196.305 305.32C195.738 304.979 195.275 304.489 194.968 303.903C194.663 303.342 194.518 302.708 194.55 302.07C194.52 301.746 194.556 301.42 194.656 301.11C194.755 300.801 194.916 300.514 195.129 300.269Z"
                fill="#0D1276"
            />
            <path
                d="M210.341 301.33C210.001 301.577 209.741 301.919 209.593 302.312C209.445 302.706 209.415 303.134 209.507 303.545C209.6 303.955 209.81 304.329 210.113 304.621C210.415 304.914 210.796 305.111 211.21 305.189C211.42 305.218 211.635 305.192 211.832 305.113C212.03 305.034 212.203 304.905 212.335 304.739C213.043 304.321 213.043 304.128 213.236 302.905C213.282 302.663 213.276 302.414 213.22 302.174C213.164 301.934 213.059 301.708 212.91 301.512C212.762 301.315 212.574 301.151 212.359 301.031C212.143 300.911 211.905 300.838 211.66 300.815C211.196 300.917 210.751 301.091 210.341 301.33Z"
                fill="white"
            />
            <path
                d="M294.928 199.216C294.181 198.891 293.364 198.762 292.554 198.841C291.743 198.92 290.966 199.204 290.296 199.666L266.271 213.624C264.069 215.205 262.268 217.279 261.012 219.68C259.755 222.082 259.078 224.744 259.035 227.454V248.649C258.904 249.805 259.089 250.974 259.57 252.034C260.052 253.093 260.811 254.001 261.768 254.663C262.991 255.242 266.496 256.528 266.496 256.528L269.712 252.122C269.873 252.122 270.002 252.122 270.002 252.347V262.703C269.982 262.902 270.037 263.101 270.156 263.262C270.275 263.422 270.449 263.533 270.645 263.572L274.987 265.373L279.876 246.301L280.133 246.076L290.168 240.061C292.396 238.478 294.221 236.392 295.495 233.973C296.769 231.554 297.456 228.869 297.5 226.135L299.109 200.856C299.109 200.856 295.249 199.441 294.928 199.216Z"
                fill="url(#paint58_linear_10025_65880)"
            />
            <path
                d="M294.896 201.499L270.87 215.393C268.651 216.986 266.84 219.081 265.582 221.506C264.325 223.932 263.657 226.619 263.634 229.351V250.257C263.634 255.595 266.85 258.008 270.87 255.724L274.312 253.762C274.312 253.762 274.601 253.762 274.601 253.987V264.344C274.601 265.566 275.695 265.501 276.338 264.344L284.475 248.07L284.7 247.844L294.896 241.991C297.094 240.4 298.893 238.319 300.149 235.913C301.404 233.508 302.084 230.843 302.132 228.129V206.999C302.132 201.66 298.851 199.215 294.896 201.499Z"
                fill="url(#paint59_linear_10025_65880)"
            />
            <path
                d="M290.94 216.261C289.106 214.91 286.148 216.582 284.314 220.12L283.253 222.082C282.963 222.532 282.545 222.79 282.32 222.597L281.323 221.825C279.49 220.442 276.499 222.146 274.698 225.652C272.897 229.158 272.865 233.178 274.698 234.561L282.385 240.382C282.642 240.382 283.06 240.382 283.285 239.868L290.972 225.17C292.805 221.664 292.805 217.676 290.94 216.261Z"
                fill="url(#paint60_linear_10025_65880)"
            />
            <path
                d="M290.94 216.261C289.106 214.91 286.148 216.582 284.314 220.12L283.253 222.082C282.963 222.532 282.545 222.79 282.32 222.597L281.323 221.825C279.49 220.442 276.499 222.146 274.698 225.652C272.897 229.158 272.865 233.178 274.698 234.561L282.385 240.382C282.642 240.382 283.06 240.382 283.285 239.868L290.972 225.17C292.805 221.664 292.805 217.676 290.94 216.261Z"
                fill="#FFF5F5"
            />
            <g opacity="0.11">
                <path
                    opacity="0.11"
                    d="M66.0305 268.814C64.769 268.086 63.3381 267.702 61.8816 267.702C60.425 267.702 58.9941 268.086 57.7327 268.814L8.23528 297.309C8.06237 297.416 7.9196 297.565 7.82057 297.742C7.72154 297.92 7.66956 298.119 7.66956 298.323C7.66956 298.526 7.72154 298.725 7.82057 298.903C7.9196 299.08 8.06237 299.229 8.23528 299.336L58.1508 328.121C59.2585 328.768 60.5183 329.109 61.8012 329.109C63.084 329.109 64.3439 328.768 65.4515 328.121L115.431 299.368C115.604 299.261 115.747 299.112 115.846 298.935C115.945 298.758 115.997 298.558 115.997 298.355C115.997 298.152 115.945 297.952 115.846 297.774C115.747 297.597 115.604 297.448 115.431 297.342L66.0305 268.814Z"
                    fill="url(#paint61_linear_10025_65880)"
                />
            </g>
            <g opacity="0.11">
                <path
                    opacity="0.11"
                    d="M66.0305 260.484C64.769 259.756 63.3381 259.373 61.8816 259.373C60.425 259.373 58.9941 259.756 57.7327 260.484L8.23528 288.98C8.06237 289.086 7.9196 289.235 7.82057 289.413C7.72154 289.59 7.66956 289.79 7.66956 289.993C7.66956 290.196 7.72154 290.396 7.82057 290.573C7.9196 290.75 8.06237 290.899 8.23528 291.006L58.1508 319.952C59.2585 320.599 60.5183 320.94 61.8012 320.94C63.084 320.94 64.3439 320.599 65.4515 319.952L115.431 291.199C115.604 291.092 115.747 290.943 115.846 290.766C115.945 290.589 115.997 290.389 115.997 290.186C115.997 289.983 115.945 289.783 115.846 289.606C115.747 289.428 115.604 289.279 115.431 289.173L66.0305 260.484Z"
                    fill="url(#paint62_linear_10025_65880)"
                />
            </g>
            <g opacity="0.11">
                <path
                    opacity="0.11"
                    d="M66.0305 252.154C64.769 251.426 63.3381 251.042 61.8816 251.042C60.425 251.042 58.9941 251.426 57.7327 252.154L8.23528 280.811C8.06237 280.917 7.9196 281.066 7.82057 281.243C7.72154 281.421 7.66956 281.621 7.66956 281.824C7.66956 282.027 7.72154 282.226 7.82057 282.404C7.9196 282.581 8.06237 282.73 8.23528 282.837L58.1508 311.783C59.2585 312.43 60.5183 312.771 61.8012 312.771C63.084 312.771 64.3439 312.43 65.4515 311.783L115.431 282.837C115.604 282.73 115.747 282.581 115.846 282.404C115.945 282.226 115.997 282.027 115.997 281.824C115.997 281.621 115.945 281.421 115.846 281.243C115.747 281.066 115.604 280.917 115.431 280.811L66.0305 252.154Z"
                    fill="url(#paint63_linear_10025_65880)"
                />
            </g>
            <g opacity="0.11">
                <path
                    opacity="0.11"
                    d="M66.0302 243.824C64.771 243.09 63.3392 242.703 61.8813 242.703C60.4235 242.703 58.9917 243.09 57.7324 243.824L8.23507 272.32C8.0617 272.422 7.91802 272.568 7.8182 272.743C7.71839 272.918 7.66589 273.116 7.66589 273.317C7.66589 273.518 7.71839 273.716 7.8182 273.891C7.91802 274.066 8.0617 274.212 8.23507 274.314L58.1505 303.26C59.2582 303.907 60.5181 304.248 61.8009 304.248C63.0838 304.248 64.3436 303.907 65.4513 303.26L115.431 274.314C115.604 274.207 115.747 274.058 115.846 273.881C115.945 273.704 115.997 273.504 115.997 273.301C115.997 273.098 115.945 272.898 115.846 272.721C115.747 272.543 115.604 272.394 115.431 272.288L66.0302 243.824Z"
                    fill="url(#paint64_linear_10025_65880)"
                />
            </g>
            <path
                d="M115.431 272.32L66.0303 243.825C64.7711 243.09 63.3393 242.703 61.8814 242.703C60.4235 242.703 58.9918 243.09 57.7325 243.825L8.23515 272.32C8.05536 272.416 7.9059 272.56 7.80362 272.736C7.70133 272.912 7.65028 273.114 7.65623 273.317V298.307C7.65151 298.515 7.70277 298.72 7.80462 298.901C7.90648 299.082 8.05519 299.232 8.23515 299.336L58.1506 328.121C59.2583 328.768 60.5181 329.109 61.801 329.109C63.0839 329.109 64.3437 328.768 65.4514 328.121L115.431 299.368C115.609 299.262 115.756 299.111 115.857 298.931C115.959 298.75 116.012 298.546 116.01 298.339V273.349C116.012 273.142 115.959 272.938 115.857 272.758C115.756 272.577 115.609 272.427 115.431 272.32Z"
                fill="url(#paint65_linear_10025_65880)"
            />
            <path
                d="M115.431 272.32L66.0303 243.825C64.7711 243.09 63.3393 242.703 61.8814 242.703C60.4235 242.703 58.9918 243.09 57.7325 243.825L8.23515 272.32C8.05536 272.416 7.9059 272.56 7.80362 272.736C7.70133 272.912 7.65028 273.114 7.65623 273.317L58.2793 302.263C59.4038 302.912 60.6794 303.254 61.9779 303.254C63.2764 303.254 64.552 302.912 65.6765 302.263L116.01 273.317C116.006 273.116 115.951 272.918 115.849 272.744C115.748 272.57 115.604 272.424 115.431 272.32Z"
                fill="url(#paint66_linear_10025_65880)"
            />
            <path
                d="M115.431 273.188L65.4517 302.134C64.344 302.781 63.0842 303.122 61.8013 303.122C60.5184 303.122 59.2586 302.781 58.1509 302.134L8.23541 273.188C8.06404 273.071 7.92115 272.917 7.8173 272.738C7.66509 273.002 7.62362 273.316 7.70193 273.612C7.78024 273.907 7.97198 274.159 8.23541 274.314L58.1509 303.259C59.2586 303.906 60.5184 304.248 61.8013 304.248C63.0842 304.248 64.344 303.906 65.4517 303.259L115.431 274.314C115.565 274.24 115.683 274.14 115.777 274.02C115.871 273.9 115.939 273.763 115.978 273.615C116.017 273.468 116.026 273.314 116.004 273.163C115.982 273.012 115.929 272.867 115.85 272.738C115.758 272.926 115.612 273.082 115.431 273.188Z"
                fill="white"
            />
            <path
                opacity="0.2"
                d="M61.8491 276.694C65.7747 276.694 68.957 274.851 68.957 272.577C68.957 270.304 65.7747 268.461 61.8491 268.461C57.9236 268.461 54.7413 270.304 54.7413 272.577C54.7413 274.851 57.9236 276.694 61.8491 276.694Z"
                fill="#180D5B"
            />
            <g opacity="0.61">
                <path
                    opacity="0.61"
                    d="M65.2907 244.724C64.2492 244.123 63.0679 243.807 61.8654 243.807C60.6629 243.807 59.4816 244.123 58.4401 244.724L17.6587 268.235C17.5117 268.319 17.3896 268.441 17.3047 268.588C17.2199 268.735 17.1752 268.902 17.1752 269.071C17.1752 269.241 17.2199 269.407 17.3047 269.554C17.3896 269.701 17.5117 269.823 17.6587 269.907L58.7939 293.675C59.7162 294.196 60.7577 294.471 61.8172 294.471C62.8766 294.471 63.9181 294.196 64.8404 293.675L106.04 269.939C106.187 269.855 106.309 269.733 106.394 269.586C106.479 269.439 106.523 269.273 106.523 269.103C106.523 268.934 106.479 268.767 106.394 268.62C106.309 268.473 106.187 268.352 106.04 268.267L65.2907 244.724Z"
                    fill="url(#paint67_linear_10025_65880)"
                />
                <path
                    opacity="0.61"
                    d="M65.2907 237.874C64.2492 237.272 63.0679 236.956 61.8654 236.956C60.6629 236.956 59.4816 237.272 58.4401 237.874L17.6587 261.513C17.5117 261.597 17.3896 261.719 17.3047 261.866C17.2199 262.013 17.1752 262.179 17.1752 262.349C17.1752 262.518 17.2199 262.685 17.3047 262.832C17.3896 262.979 17.5117 263.101 17.6587 263.185L58.7939 286.953C59.7162 287.474 60.7577 287.748 61.8172 287.748C62.8766 287.748 63.9181 287.474 64.8404 286.953L106.04 263.217C106.187 263.133 106.309 263.011 106.394 262.864C106.479 262.717 106.523 262.551 106.523 262.381C106.523 262.211 106.479 262.045 106.394 261.898C106.309 261.751 106.187 261.629 106.04 261.545L65.2907 237.874Z"
                    fill="url(#paint68_linear_10025_65880)"
                />
                <path
                    opacity="0.61"
                    d="M65.2907 230.991C64.2492 230.39 63.0679 230.073 61.8654 230.073C60.6629 230.073 59.4816 230.39 58.4401 230.991L17.6587 254.502C17.5117 254.586 17.3896 254.708 17.3047 254.855C17.2199 255.002 17.1752 255.168 17.1752 255.338C17.1752 255.508 17.2199 255.674 17.3047 255.821C17.3896 255.968 17.5117 256.09 17.6587 256.174L58.7939 279.91C59.7109 280.447 60.7545 280.73 61.8172 280.73C62.8799 280.73 63.9234 280.447 64.8404 279.91L106.04 256.174C106.187 256.09 106.309 255.968 106.394 255.821C106.479 255.674 106.523 255.508 106.523 255.338C106.523 255.168 106.479 255.002 106.394 254.855C106.309 254.708 106.187 254.586 106.04 254.502L65.2907 230.991Z"
                    fill="url(#paint69_linear_10025_65880)"
                />
            </g>
            <path
                d="M40.5582 141.903L55.4492 143.093L71.7553 147.017C71.7553 147.017 77.7696 144.186 68.5391 128.363C62.6856 118.296 57.3789 118.553 47.7946 105.849C39.8184 96.2006 37.7279 90.6687 32.6141 90.1863C26.1174 98.5162 39.3038 133.026 40.5582 141.903Z"
                fill="url(#paint70_linear_10025_65880)"
            />
            <path
                d="M13.7672 168.243C13.7672 168.243 7.3348 150.94 15.4075 125.757C19.1504 113.312 24.629 101.456 31.6815 90.5398C32.582 89.1569 33.772 90.2504 34.2544 90.9901C34.2544 99.8668 47.7946 116.72 53.1014 136.66L13.7672 168.243Z"
                fill="url(#paint71_linear_10025_65880)"
            />
            <path
                d="M13.7672 168.243C13.7672 168.243 7.3348 150.94 15.4075 125.757C19.1504 113.312 24.629 101.456 31.6815 90.5398C32.582 89.1569 33.772 90.2504 34.2544 90.9901C34.2544 99.8668 47.7946 116.72 53.1014 136.66L13.7672 168.243Z"
                fill="url(#paint72_linear_10025_65880)"
            />
            <path
                d="M118.39 235.784C143.541 199.119 103.949 144.186 75.4859 136.307C47.0225 128.427 19.7169 148.399 5.43699 182.909C-6.1735 210.922 7.62401 235.237 26.7604 247.78C56.8641 267.528 100.83 263.99 118.39 235.784Z"
                fill="url(#paint73_radial_10025_65880)"
            />
            <path
                d="M47.0547 189.503C51.6742 189.509 56.1918 188.145 60.0359 185.583C63.8799 183.021 66.8777 179.377 68.6499 175.111C70.4221 170.845 70.8891 166.149 69.9918 161.617C69.0945 157.086 66.8732 152.922 63.609 149.654C60.3448 146.385 56.1843 144.158 51.654 143.254C47.1238 142.351 42.4273 142.811 38.1588 144.578C33.8904 146.344 30.2418 149.337 27.6746 153.177C25.1075 157.018 23.7373 161.533 23.7373 166.153C23.7288 169.219 24.3259 172.256 25.4943 175.091C26.6627 177.926 28.3795 180.502 30.5459 182.671C32.7124 184.841 35.2861 186.561 38.1191 187.733C40.9521 188.906 43.9888 189.507 47.0547 189.503Z"
                fill="url(#paint74_radial_10025_65880)"
            />
            <path
                d="M49.1455 176.863C52.0629 176.882 54.9202 176.035 57.3553 174.428C59.7903 172.821 61.6933 170.527 62.823 167.837C63.9527 165.147 64.2582 162.182 63.7008 159.319C63.1434 156.455 61.7481 153.821 59.692 151.752C57.6358 149.682 55.0113 148.269 52.1514 147.693C49.2914 147.117 46.3247 147.403 43.6275 148.515C40.9302 149.627 38.624 151.515 37.0011 153.939C35.3782 156.364 34.5118 159.215 34.5118 162.133C34.5032 166.025 36.0391 169.763 38.7826 172.524C41.526 175.286 45.2529 176.846 49.1455 176.863Z"
                fill="url(#paint75_radial_10025_65880)"
            />
            <path
                d="M46.8617 166.442C49.363 166.449 51.8099 165.713 53.8927 164.328C55.9755 162.943 57.6006 160.971 58.5622 158.662C59.5238 156.353 59.7787 153.811 59.2946 151.357C58.8105 148.903 57.6093 146.647 55.8429 144.877C54.0765 143.106 51.8243 141.899 49.3716 141.408C46.9189 140.918 44.3758 141.166 42.0643 142.122C39.7529 143.078 37.7769 144.698 36.3866 146.777C34.9963 148.856 34.2542 151.301 34.2542 153.803C34.25 155.461 34.5729 157.104 35.2046 158.637C35.8363 160.171 36.7643 161.564 37.9355 162.738C39.1066 163.913 40.4979 164.844 42.0296 165.48C43.5613 166.115 45.2034 166.442 46.8617 166.442Z"
                fill="url(#paint76_radial_10025_65880)"
            />
            <path
                d="M46.8617 166.442C49.363 166.449 51.8099 165.713 53.8927 164.328C55.9755 162.943 57.6006 160.971 58.5622 158.662C59.5238 156.353 59.7787 153.811 59.2946 151.357C58.8105 148.903 57.6093 146.647 55.8429 144.877C54.0765 143.106 51.8243 141.899 49.3716 141.408C46.9189 140.918 44.3758 141.166 42.0643 142.122C39.7529 143.078 37.7769 144.698 36.3866 146.777C34.9963 148.856 34.2542 151.301 34.2542 153.803C34.25 155.461 34.5729 157.104 35.2046 158.637C35.8363 160.171 36.7643 161.564 37.9355 162.738C39.1066 163.913 40.4979 164.844 42.0296 165.48C43.5613 166.115 45.2034 166.442 46.8617 166.442Z"
                fill="url(#paint77_radial_10025_65880)"
            />
            <path
                d="M44.932 155.186C47.0198 155.192 49.0625 154.579 50.8015 153.423C52.5405 152.268 53.8976 150.623 54.701 148.696C55.5044 146.769 55.7179 144.647 55.3145 142.598C54.9111 140.55 53.9089 138.667 52.4349 137.189C50.9609 135.71 49.0812 134.702 47.034 134.292C44.9868 133.883 42.8641 134.09 40.9346 134.887C39.0051 135.685 37.3557 137.037 36.1951 138.772C35.0345 140.508 34.415 142.549 34.415 144.637C34.4065 146.022 34.6725 147.394 35.1976 148.676C35.7227 149.958 36.4966 151.123 37.4744 152.103C38.4523 153.084 39.6148 153.862 40.8948 154.391C42.1748 154.92 43.547 155.19 44.932 155.186Z"
                fill="url(#paint78_radial_10025_65880)"
            />
            <path
                d="M44.932 155.186C47.0198 155.192 49.0625 154.579 50.8015 153.423C52.5405 152.268 53.8976 150.623 54.701 148.696C55.5044 146.769 55.7179 144.647 55.3145 142.598C54.9111 140.55 53.9089 138.667 52.4349 137.189C50.9609 135.71 49.0812 134.702 47.034 134.292C44.9868 133.883 42.8641 134.09 40.9346 134.887C39.0051 135.685 37.3557 137.037 36.1951 138.772C35.0345 140.508 34.415 142.549 34.415 144.637C34.4065 146.022 34.6725 147.394 35.1976 148.676C35.7227 149.958 36.4966 151.123 37.4744 152.103C38.4523 153.084 39.6148 153.862 40.8948 154.391C42.1748 154.92 43.547 155.19 44.932 155.186Z"
                fill="url(#paint79_radial_10025_65880)"
            />
            <path
                d="M44.0314 146.116C45.7376 146.122 47.4072 145.622 48.8289 144.679C50.2505 143.735 51.3603 142.391 52.0176 140.817C52.675 139.243 52.8503 137.509 52.5213 135.834C52.1924 134.16 51.374 132.621 50.1698 131.413C48.9657 130.204 47.4299 129.38 45.757 129.045C44.0842 128.71 42.3494 128.878 40.7726 129.53C39.1957 130.181 37.8476 131.286 36.8991 132.704C35.9505 134.122 35.4442 135.79 35.4442 137.496C35.4225 138.631 35.6295 139.759 36.0529 140.813C36.4764 141.866 37.1076 142.824 37.9089 143.628C38.7103 144.432 39.6653 145.067 40.7172 145.494C41.7691 145.922 42.8962 146.133 44.0314 146.116Z"
                fill="url(#paint80_radial_10025_65880)"
            />
            <path
                d="M44.0314 146.116C45.7376 146.122 47.4072 145.622 48.8289 144.679C50.2505 143.735 51.3603 142.391 52.0176 140.817C52.675 139.243 52.8503 137.509 52.5213 135.834C52.1924 134.16 51.374 132.621 50.1698 131.413C48.9657 130.204 47.4299 129.38 45.757 129.045C44.0842 128.71 42.3494 128.878 40.7726 129.53C39.1957 130.181 37.8476 131.286 36.8991 132.704C35.9505 134.122 35.4442 135.79 35.4442 137.496C35.4225 138.631 35.6295 139.759 36.0529 140.813C36.4764 141.866 37.1076 142.824 37.9089 143.628C38.7103 144.432 39.6653 145.067 40.7172 145.494C41.7691 145.922 42.8962 146.133 44.0314 146.116Z"
                fill="url(#paint81_radial_10025_65880)"
            />
            <path
                d="M42.5197 137.689C43.8633 137.696 45.1785 137.303 46.2987 136.561C47.4189 135.819 48.2937 134.762 48.8122 133.522C49.3308 132.283 49.4698 130.917 49.2116 129.599C48.9534 128.28 48.3096 127.068 47.3618 126.116C46.4141 125.164 45.205 124.514 43.8877 124.25C42.5704 123.985 41.2043 124.118 39.9624 124.63C38.7205 125.143 37.6588 126.013 36.9117 127.13C36.1645 128.246 35.7657 129.56 35.7657 130.903C35.7615 131.793 35.933 132.674 36.2706 133.498C36.6081 134.321 37.1049 135.069 37.7325 135.7C38.3601 136.33 39.1061 136.831 39.9276 137.172C40.7492 137.514 41.6301 137.689 42.5197 137.689Z"
                fill="url(#paint82_radial_10025_65880)"
            />
            <path
                d="M42.5197 137.689C43.8633 137.696 45.1785 137.303 46.2987 136.561C47.4189 135.819 48.2937 134.762 48.8122 133.522C49.3308 132.283 49.4698 130.917 49.2116 129.599C48.9534 128.28 48.3096 127.068 47.3618 126.116C46.4141 125.164 45.205 124.514 43.8877 124.25C42.5704 123.985 41.2043 124.118 39.9624 124.63C38.7205 125.143 37.6588 126.013 36.9117 127.13C36.1645 128.246 35.7657 129.56 35.7657 130.903C35.7615 131.793 35.933 132.674 36.2706 133.498C36.6081 134.321 37.1049 135.069 37.7325 135.7C38.3601 136.33 39.1061 136.831 39.9276 137.172C40.7492 137.514 41.6301 137.689 42.5197 137.689Z"
                fill="url(#paint83_radial_10025_65880)"
            />
            <path
                d="M41.4584 130.903C42.5298 130.909 43.5788 130.597 44.4723 130.006C45.3658 129.415 46.0633 128.571 46.4762 127.582C46.8892 126.594 46.9989 125.505 46.7914 124.454C46.584 123.403 46.0687 122.437 45.3111 121.679C44.5535 120.922 43.5878 120.406 42.5367 120.199C41.4856 119.991 40.3965 120.101 39.4079 120.514C38.4193 120.927 37.5758 121.625 36.9845 122.518C36.3933 123.412 36.081 124.461 36.0873 125.532C36.0742 126.241 36.2042 126.945 36.4695 127.603C36.7348 128.26 37.1299 128.858 37.6313 129.359C38.1327 129.86 38.73 130.256 39.3876 130.521C40.0451 130.786 40.7494 130.916 41.4584 130.903Z"
                fill="url(#paint84_radial_10025_65880)"
            />
            <path
                d="M41.4584 130.903C42.5298 130.909 43.5788 130.597 44.4723 130.006C45.3658 129.415 46.0633 128.571 46.4762 127.582C46.8892 126.594 46.9989 125.505 46.7914 124.454C46.584 123.403 46.0687 122.437 45.3111 121.679C44.5535 120.922 43.5878 120.406 42.5367 120.199C41.4856 119.991 40.3965 120.101 39.4079 120.514C38.4193 120.927 37.5758 121.625 36.9845 122.518C36.3933 123.412 36.081 124.461 36.0873 125.532C36.0742 126.241 36.2042 126.945 36.4695 127.603C36.7348 128.26 37.1299 128.858 37.6313 129.359C38.1327 129.86 38.73 130.256 39.3876 130.521C40.0451 130.786 40.7494 130.916 41.4584 130.903Z"
                fill="url(#paint85_radial_10025_65880)"
            />
            <path
                d="M130.194 129.263C126.431 126.24 122.7 127.173 110.897 140.263C99.093 153.353 92.9501 149.911 83.1728 159.56C66.8988 174.933 88.2866 193.587 88.2866 193.587L98.3533 199.634L106.49 180.53C109.707 169.916 136.047 140.713 130.194 129.263Z"
                fill="url(#paint86_linear_10025_65880)"
            />
            <path
                d="M91.7925 170.817C103.21 149.59 120.224 150.49 127.46 130.743C127.591 130.423 127.813 130.15 128.1 129.957C128.386 129.765 128.723 129.662 129.068 129.662C129.413 129.662 129.751 129.765 130.037 129.957C130.323 130.15 130.546 130.423 130.676 130.743C133.893 137.175 138.813 145.923 141.193 167.794C141.848 172.632 141.46 177.554 140.057 182.23C138.653 186.906 136.267 191.228 133.056 194.906C118.583 213.271 75.6793 200.663 91.7925 170.817Z"
                fill="url(#paint87_linear_10025_65880)"
            />
            <path
                d="M38.4354 222.18C38.036 220.656 37.2834 219.247 36.2385 218.068C35.1937 216.89 33.8859 215.973 32.4211 215.393C27.2752 212.37 23.3514 212.821 22.7725 214.01V214.3C22.3222 215.683 23.673 217.773 28.626 222.244L29.6552 222.919C29.3979 222.919 29.3979 222.919 29.3979 223.209H29.9446C30.2019 223.466 30.2019 223.466 30.3949 223.466C30.5879 223.466 31.1346 223.756 31.2954 223.756C33.3216 225.171 38.9178 225.171 38.4354 222.18Z"
                fill="url(#paint88_radial_10025_65880)"
            />
            <path
                d="M38.4354 222.18C38.036 220.656 37.2834 219.247 36.2385 218.068C35.1937 216.89 33.8859 215.973 32.4211 215.393C27.2752 212.37 23.3514 212.821 22.7725 214.01V214.3C22.3222 215.683 23.673 217.773 28.626 222.244L29.6552 222.919C29.3979 222.919 29.3979 222.919 29.3979 223.209H29.9446C30.2019 223.466 30.2019 223.466 30.3949 223.466C30.5879 223.466 31.1346 223.756 31.2954 223.756C33.3216 225.171 38.9178 225.171 38.4354 222.18Z"
                fill="url(#paint89_radial_10025_65880)"
            />
            <path
                d="M31.4885 217.934C32.4212 218.416 33.6112 219.317 33.3217 220.057C33.0323 220.796 32.1317 220.507 31.4885 220.057C30.8453 219.606 29.6553 218.674 29.6553 217.934C29.6553 217.194 30.588 217.323 31.4885 217.934Z"
                fill="white"
            />
            <path
                d="M38.2744 228.676C38.491 228.829 38.6681 229.031 38.7911 229.266C38.9141 229.501 38.9796 229.762 38.982 230.027C38.9572 230.788 38.7113 231.526 38.2744 232.15C37.792 233.05 36.602 233.5 34.3185 232.857L32.8712 232.15C32.1963 231.666 31.5809 231.105 31.038 230.477C30.5877 229.77 30.1053 229.126 29.848 228.676C29.5997 228.093 29.4476 227.474 29.3977 226.843V223.82C29.3977 223.369 29.655 223.08 30.1053 223.369C30.3297 223.517 30.5145 223.717 30.6435 223.953C30.7725 224.188 30.8417 224.452 30.845 224.72C30.845 224.72 31.1345 226.843 31.1345 227.004C30.9892 227.769 30.9892 228.554 31.1345 229.319C31.3576 230.019 31.8412 230.606 32.4853 230.96C35.0582 232.6 35.9588 231.667 36.4412 230.96C36.7053 230.544 36.8601 230.068 36.8915 229.577C37.0844 228.483 37.985 228.676 38.2744 228.676Z"
                fill="#030922"
            />
            <path
                d="M30.7807 222.629C31.0013 222.78 31.1833 222.982 31.3118 223.216C31.4403 223.451 31.5118 223.713 31.5204 223.98V227.003C31.4952 227.414 31.3972 227.817 31.2309 228.193C30.9415 228.676 30.7807 229.383 29.848 229.576C29.2056 229.613 28.567 229.456 28.0147 229.126C25.7035 227.714 23.9099 225.593 22.901 223.079C22.4252 222.211 22.1711 221.239 22.1613 220.249C22.1613 219.799 22.4507 219.509 22.901 219.799C23.1216 219.95 23.3036 220.151 23.4321 220.386C23.5606 220.621 23.6321 220.882 23.6407 221.15C23.941 223.363 25.0953 225.372 26.8569 226.746C27.223 227.084 27.6216 227.386 28.0469 227.646C28.5293 227.936 28.9796 228.097 29.2369 227.936C29.3573 227.929 29.4716 227.881 29.5598 227.798C29.648 227.716 29.7044 227.605 29.7193 227.486C29.9068 227.118 29.9955 226.708 29.9766 226.296V223.272C29.848 222.436 30.3304 222.179 30.7807 222.629Z"
                fill="#030922"
            />
            <g opacity="0.8">
                <g opacity="0.8">
                    <path
                        opacity="0.8"
                        d="M64.2614 191.851C69.4716 193.138 73.7492 198.283 75.486 206.42C78.3484 218.771 70.7581 232.15 57.7647 229.384C46.508 227.004 42.745 220.893 42.745 210.537C42.745 200.181 52.5544 187.541 64.2614 191.851Z"
                        fill="#5090CD"
                    />
                    <path
                        opacity="0.8"
                        d="M64.0687 190.757C69.279 192.012 73.7173 197.19 75.3254 205.295C78.1557 217.645 70.5654 231.024 57.572 228.258C46.3153 225.717 42.5201 219.703 42.5201 209.25C42.5201 198.798 51.9115 186.287 64.0687 190.757Z"
                        fill="white"
                    />
                </g>
            </g>
            <path
                d="M59.5016 190.757C67.7351 192.654 70.3724 202.464 69.1502 209.861C67.9281 217.258 60.2735 222.726 54.42 221.6C50.5613 220.622 47.2489 218.151 45.2106 214.732C43.1722 211.313 42.5747 207.224 43.5492 203.364C45.2217 195.163 51.0752 188.827 59.5016 190.757Z"
                fill="url(#paint90_radial_10025_65880)"
            />
            <path
                d="M59.7588 193.619C60.9373 194.502 61.7774 195.762 62.1388 197.189C62.2879 197.87 62.1712 198.582 61.8124 199.18C61.4537 199.778 60.8802 200.216 60.209 200.405C59.6894 200.427 59.1694 200.373 58.6653 200.245C57.1858 199.762 56.5426 197.028 56.7355 195.742C56.7799 195.249 56.9554 194.778 57.2439 194.376C57.5324 193.975 57.9233 193.658 58.3758 193.458C58.6013 193.367 58.8462 193.333 59.0879 193.361C59.3296 193.39 59.5604 193.478 59.7588 193.619Z"
                fill="white"
            />
            <path
                d="M65.0333 200.76C65.3257 200.911 65.5776 201.13 65.7679 201.398C65.9582 201.667 66.0812 201.977 66.1268 202.303C66.1931 202.869 66.0543 203.44 65.7356 203.912C65.417 204.384 64.9395 204.726 64.39 204.876C63.2 205.134 62.396 203.3 62.396 202.303C62.4028 201.851 62.5441 201.41 62.8018 201.038C63.0595 200.666 63.422 200.379 63.8433 200.213C63.8433 200.213 64.1327 200.213 64.2292 200.213L64.583 200.374C64.692 200.544 64.8486 200.678 65.0333 200.76Z"
                fill="white"
            />
            <path
                d="M53.9052 223.337L56.2852 223.98C58.7383 224.728 61.1263 225.674 63.4251 226.811C67.6395 228.474 71.0806 231.651 73.0738 235.72C74.3602 240.287 67.6705 241.734 58.3435 239.643C49.0165 237.553 40.3328 231.571 39.2393 227.293C38.596 225.363 39.8825 223.82 42.0696 222.983C43.4532 222.501 44.9151 222.283 46.3793 222.34C48.4055 222.533 51.0749 222.919 53.9052 223.337Z"
                fill="url(#paint91_radial_10025_65880)"
            />
            <g opacity="0.8">
                <g opacity="0.8">
                    <path
                        opacity="0.8"
                        d="M18.0446 171.105C28.2078 167.632 34.801 180.432 32.8713 193.973C31.5848 204.232 24.8308 211.565 19.4276 208.542C9.20002 202.592 7.14166 194.519 7.62409 188.666C8.10652 182.812 10.1006 173.743 18.0446 171.105Z"
                        fill="#5DA7F3"
                    />
                </g>
            </g>
            <g opacity="0.8">
                <path
                    opacity="0.8"
                    d="M13.1238 173.486C14.517 172.089 16.2824 171.122 18.2097 170.7C20.137 170.278 22.145 170.419 23.9945 171.106C30.4269 173.647 33.9648 183.263 32.6783 193.426C31.5848 201.66 25.7313 207.513 19.4275 204.972C10.5508 200.663 7.9135 193.973 8.1708 185.386C8.08587 183.162 8.48476 180.946 9.33991 178.892C10.1951 176.837 11.4861 174.993 13.1238 173.486Z"
                    fill="white"
                />
            </g>
            <path
                d="M21.3573 171.267C26.1173 173.647 29.5908 178.407 29.3978 188.023C29.3978 194.456 23.5443 201.306 17.6908 198.926C14.2709 197.232 11.5648 194.377 10.0564 190.872C8.54806 187.366 8.33572 183.438 9.45736 179.79C10.9368 173.486 17.4014 169.337 21.3573 171.267Z"
                fill="url(#paint92_radial_10025_65880)"
            />
            <path
                d="M22.451 174.386C22.7287 174.415 22.9944 174.515 23.2229 174.676C23.9715 175.231 24.4872 176.045 24.6701 176.959C24.6701 177.409 24.4129 179.146 23.6731 179.146C23.4802 179.146 23.0299 179.339 22.8369 179.146C22.6439 178.953 21.5826 178.053 21.1002 176.766C20.6177 175.48 20.8429 174.579 21.8399 174.29C22.0487 174.254 22.2634 174.288 22.451 174.386Z"
                fill="white"
            />
            <path
                d="M25.8281 181.816C26.214 182.62 26.214 183.713 25.2813 183.713C25.0883 183.713 24.4772 183.906 23.5445 182.62C23.4563 182.402 23.4295 182.164 23.4671 181.931C23.5047 181.699 23.6052 181.482 23.7577 181.303C23.9102 181.124 24.1089 180.99 24.3322 180.916C24.5554 180.842 24.7947 180.83 25.024 180.883C25.2138 180.976 25.3828 181.107 25.5209 181.267C25.659 181.428 25.7635 181.614 25.8281 181.816Z"
                fill="white"
            />
            <path
                d="M14.5712 200.212C15.2144 200.663 15.8577 200.823 16.5009 201.306C18.3848 202.481 20.1322 203.862 21.7111 205.423C25.3776 208.896 28.1436 212.563 27.7576 215.071C27.7576 219.188 21.9041 219.188 14.3139 214.878C6.72364 210.568 0.87015 203.943 0.87015 199.826C0.854279 199.408 0.92819 198.992 1.0869 198.605C1.24562 198.218 1.48545 197.869 1.79023 197.583C2.09501 197.296 2.45766 197.078 2.85375 196.944C3.24983 196.809 3.67014 196.761 4.08635 196.803C5.33563 196.814 6.57453 197.031 7.75283 197.446C10.1936 197.883 12.516 198.825 14.5712 200.212Z"
                fill="url(#paint93_radial_10025_65880)"
            />
            <path
                d="M52.8119 255.917C48.2579 255.945 43.7188 255.394 39.3038 254.277L39.529 253.505C39.7219 253.505 57.8613 258.619 71.9805 250.482C75.8487 248.11 79.1834 244.962 81.774 241.236C84.3647 237.511 86.1551 233.289 87.0323 228.837L87.8042 229.062C86.9029 233.618 85.0654 237.937 82.408 241.746C79.7506 245.555 76.3312 248.771 72.3664 251.189C66.3661 254.428 59.6291 256.057 52.8119 255.917Z"
                fill="url(#paint94_linear_10025_65880)"
                fillOpacity="0.4"
            />
            <path
                d="M60.0484 258.748C57.4148 258.769 54.7827 258.618 52.1687 258.297V257.493C59.8247 258.401 67.5794 257.999 75.1002 256.303C90.9883 252.508 100.83 243.085 104.593 228.258L105.365 228.451C100.669 247.33 86.4213 254.438 75.261 257.075C70.2722 258.221 65.167 258.782 60.0484 258.748Z"
                fill="url(#paint95_linear_10025_65880)"
                fillOpacity="0.4"
            />
            <path
                d="M67.2526 260.388V259.584C68.6999 259.584 108.388 259.166 117.104 224.206L117.876 224.399C109.031 259.97 68.7642 260.388 67.2526 260.388Z"
                fill="url(#paint96_linear_10025_65880)"
                fillOpacity="0.4"
            />
            <path
                d="M68.2496 254.823C51.5964 254.525 35.2259 250.468 20.3604 242.956L20.7141 242.216C53.5837 257.943 84.3306 257.975 112.344 242.216L112.762 242.923C99.2137 250.698 83.8701 254.8 68.2496 254.823Z"
                fill="url(#paint97_linear_10025_65880)"
                fillOpacity="0.4"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_10025_65880"
                    x1="257.266"
                    y1="150.522"
                    x2="191.495"
                    y2="228.869"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.02" stopColor="#031D36" stopOpacity="0" />
                    <stop offset="0.94" stopColor="#031D36" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_10025_65880"
                    x1="190.047"
                    y1="89.7037"
                    x2="201.24"
                    y2="80.6662"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#73CFD7" />
                    <stop offset="0.14" stopColor="#50B7C3" />
                    <stop offset="0.3" stopColor="#3280B0" />
                    <stop offset="0.44" stopColor="#164E9D" />
                    <stop offset="0.52" stopColor="#083596" />
                    <stop offset="1" stopColor="#3A9076" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_10025_65880"
                    x1="194.003"
                    y1="99.288"
                    x2="165.926"
                    y2="95.1713"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#041836" />
                    <stop offset="1" stopColor="#3D93C1" />
                </linearGradient>
                <radialGradient
                    id="paint3_radial_10025_65880"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(193.373 169.729) rotate(-0.861679) scale(84.1982 84.2879)"
                >
                    <stop offset="0.02" stopColor="#A0ECE7" />
                    <stop offset="0.33" stopColor="#93F0EF" />
                    <stop offset="0.56" stopColor="#59CEE7" />
                    <stop offset="0.78" stopColor="#30A6C6" />
                    <stop offset="0.91" stopColor="#239BC5" />
                    <stop offset="1" stopColor="#896DAE" />
                </radialGradient>
                <linearGradient
                    id="paint4_linear_10025_65880"
                    x1="180.141"
                    y1="202.131"
                    x2="190.198"
                    y2="176.156"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.01" stopColor="#49BFDA" stopOpacity="0" />
                    <stop offset="0.5" stopColor="#2E7B9A" stopOpacity="0.52" />
                    <stop offset="0.99" stopColor="#153B5E" />
                </linearGradient>
                <linearGradient
                    id="paint5_linear_10025_65880"
                    x1="220.923"
                    y1="179.809"
                    x2="204.059"
                    y2="159.069"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#2D5382" />
                    <stop offset="1" stopColor="#3C88C4" />
                </linearGradient>
                <linearGradient
                    id="paint6_linear_10025_65880"
                    x1="1069.72"
                    y1="1159.97"
                    x2="991.166"
                    y2="1070.35"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#2D5382" />
                    <stop offset="1" stopColor="#3C88C4" />
                </linearGradient>
                <linearGradient
                    id="paint7_linear_10025_65880"
                    x1="5480"
                    y1="4602.27"
                    x2="5144.5"
                    y2="4129.23"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#2D5382" />
                    <stop offset="1" stopColor="#3C88C4" />
                </linearGradient>
                <radialGradient
                    id="paint8_radial_10025_65880"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(169.837 122.678) rotate(85.2233) scale(77.4618 94.7552)"
                >
                    <stop offset="0.00520833" stopColor="#ECF5FC" />
                    <stop offset="1" stopColor="#CEE4F3" />
                </radialGradient>
                <radialGradient
                    id="paint9_radial_10025_65880"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(169.837 122.678) rotate(85.2233) scale(77.4618 94.7552)"
                >
                    <stop offset="0.00520833" stopColor="#ECF5FC" />
                    <stop offset="1" stopColor="#CEE4F3" />
                </radialGradient>
                <radialGradient
                    id="paint10_radial_10025_65880"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(169.837 122.678) rotate(85.2233) scale(77.4618 94.7552)"
                >
                    <stop offset="0.00520833" stopColor="#ECF5FC" />
                    <stop offset="1" stopColor="#CEE4F3" />
                </radialGradient>
                <linearGradient
                    id="paint11_linear_10025_65880"
                    x1="4836.2"
                    y1="4916.69"
                    x2="4604.64"
                    y2="4505.38"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#2D5382" />
                    <stop offset="1" stopColor="#3C88C4" />
                </linearGradient>
                <linearGradient
                    id="paint12_linear_10025_65880"
                    x1="710.712"
                    y1="676.294"
                    x2="686.998"
                    y2="633.801"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#2D5382" />
                    <stop offset="1" stopColor="#3C88C4" />
                </linearGradient>
                <linearGradient
                    id="paint13_linear_10025_65880"
                    x1="560.602"
                    y1="602.129"
                    x2="538.632"
                    y2="562.976"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#2D5382" />
                    <stop offset="1" stopColor="#3C88C4" />
                </linearGradient>
                <linearGradient
                    id="paint14_linear_10025_65880"
                    x1="133.86"
                    y1="128.71"
                    x2="206.605"
                    y2="206.216"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="0.06" stopColor="#43E8E1" />
                    <stop offset="0.45" stopColor="#3A8FA2" />
                    <stop offset="0.84" stopColor="#387E97" />
                    <stop offset="1" stopColor="#385397" />
                </linearGradient>
                <linearGradient
                    id="paint15_linear_10025_65880"
                    x1="136.047"
                    y1="155.057"
                    x2="203.587"
                    y2="152.677"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.01" stopColor="#CFF9FC" />
                    <stop offset="0.41" stopColor="#96F2F1" stopOpacity="0.3" />
                    <stop offset="1" stopColor="#42E8E0" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                    id="paint16_linear_10025_65880"
                    x1="140.485"
                    y1="137.549"
                    x2="208.281"
                    y2="206.309"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#37A0DA" />
                    <stop offset="0.85" stopColor="#43E8E1" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint17_linear_10025_65880"
                    x1="3142.41"
                    y1="1266.16"
                    x2="4418.26"
                    y2="1181.6"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.01" stopColor="#CFF9FC" />
                    <stop offset="0.41" stopColor="#96F2F1" stopOpacity="0.3" />
                    <stop offset="1" stopColor="#42E8E0" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                    id="paint18_linear_10025_65880"
                    x1="852.851"
                    y1="1239.27"
                    x2="890.976"
                    y2="1251.7"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="0.06" stopColor="#43E8E1" />
                    <stop offset="0.45" stopColor="#3A8FA2" />
                    <stop offset="0.84" stopColor="#387E97" />
                    <stop offset="1" stopColor="#385397" />
                </linearGradient>
                <linearGradient
                    id="paint19_linear_10025_65880"
                    x1="784.782"
                    y1="1295.68"
                    x2="721.554"
                    y2="1237.57"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#3C88C4" />
                </linearGradient>
                <linearGradient
                    id="paint20_linear_10025_65880"
                    x1="535.16"
                    y1="608.894"
                    x2="538.321"
                    y2="624.795"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.01" stopColor="white" />
                    <stop offset="1" stopColor="#42E8E0" />
                </linearGradient>
                <linearGradient
                    id="paint21_linear_10025_65880"
                    x1="418.186"
                    y1="1336.35"
                    x2="456.892"
                    y2="1344.06"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.01" stopColor="white" />
                    <stop offset="1" stopColor="#42E8E0" />
                </linearGradient>
                <linearGradient
                    id="paint22_linear_10025_65880"
                    x1="2268.89"
                    y1="2232.77"
                    x2="2490.99"
                    y2="2232.77"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#041836" />
                    <stop offset="1" stopColor="#3D93C1" />
                </linearGradient>
                <linearGradient
                    id="paint23_linear_10025_65880"
                    x1="2315.38"
                    y1="2285.8"
                    x2="2547.2"
                    y2="2285.8"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#041836" />
                    <stop offset="1" stopColor="#3D93C1" />
                </linearGradient>
                <radialGradient
                    id="paint24_radial_10025_65880"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(230.696 164.524) scale(0.925764 18.4823)"
                >
                    <stop stopColor="#D3FFF8" />
                    <stop offset="0.0474436" stopColor="#D2FDF7" />
                    <stop offset="0.5011" stopColor="#C8E9EF" />
                    <stop offset="0.8307" stopColor="#C2DDE9" />
                    <stop offset="1" stopColor="#C0D8E7" />
                </radialGradient>
                <radialGradient
                    id="paint25_radial_10025_65880"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(173.823 188.049) scale(8.72717 8.73585)"
                >
                    <stop offset="0.47" stopColor="#041836" />
                    <stop offset="1" stopColor="#3A9076" />
                </radialGradient>
                <linearGradient
                    id="paint26_linear_10025_65880"
                    x1="258.006"
                    y1="129.07"
                    x2="246.042"
                    y2="114.115"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#95F1EF" />
                    <stop offset="0.22" stopColor="#50B7C3" />
                    <stop offset="0.38" stopColor="#3280B0" />
                    <stop offset="0.51" stopColor="#164E9D" />
                    <stop offset="0.64" stopColor="#083596" />
                    <stop offset="1" stopColor="#3A9076" />
                </linearGradient>
                <linearGradient
                    id="paint27_linear_10025_65880"
                    x1="4569.72"
                    y1="-160704"
                    x2="5199.67"
                    y2="-160736"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#041836" />
                    <stop offset="1" stopColor="#3D93C1" />
                </linearGradient>
                <linearGradient
                    id="paint28_linear_10025_65880"
                    x1="151.517"
                    y1="165.863"
                    x2="151.517"
                    y2="159.431"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#C1E8E0" stopOpacity="0.8" />
                    <stop offset="0.71" stopColor="#B2E0F9" />
                    <stop offset="1" stopColor="#96D6F7" />
                </linearGradient>
                <linearGradient
                    id="paint29_linear_10025_65880"
                    x1="347.238"
                    y1="380.77"
                    x2="347.238"
                    y2="367.905"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#C1E8E0" stopOpacity="0.8" />
                    <stop offset="0.71" stopColor="#B2E0F9" />
                    <stop offset="1" stopColor="#96D6F7" />
                </linearGradient>
                <linearGradient
                    id="paint30_linear_10025_65880"
                    x1="368.887"
                    y1="417.02"
                    x2="368.887"
                    y2="404.284"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#C1E8E0" stopOpacity="0.8" />
                    <stop offset="0.71" stopColor="#B2E0F9" />
                    <stop offset="1" stopColor="#96D6F7" />
                </linearGradient>
                <linearGradient
                    id="paint31_linear_10025_65880"
                    x1="439.677"
                    y1="444.511"
                    x2="439.677"
                    y2="431.775"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#C1E8E0" stopOpacity="0.8" />
                    <stop offset="0.71" stopColor="#B2E0F9" />
                    <stop offset="1" stopColor="#96D6F7" />
                </linearGradient>
                <linearGradient
                    id="paint32_linear_10025_65880"
                    x1="400.45"
                    y1="414.135"
                    x2="400.45"
                    y2="401.399"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#C1E8E0" stopOpacity="0.8" />
                    <stop offset="0.71" stopColor="#B2E0F9" />
                    <stop offset="1" stopColor="#96D6F7" />
                </linearGradient>
                <linearGradient
                    id="paint33_linear_10025_65880"
                    x1="407.921"
                    y1="448.561"
                    x2="407.921"
                    y2="435.824"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#C1E8E0" stopOpacity="0.8" />
                    <stop offset="0.71" stopColor="#B2E0F9" />
                    <stop offset="1" stopColor="#96D6F7" />
                </linearGradient>
                <linearGradient
                    id="paint34_linear_10025_65880"
                    x1="443.536"
                    y1="466.575"
                    x2="443.536"
                    y2="453.839"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#C1E8E0" stopOpacity="0.8" />
                    <stop offset="0.71" stopColor="#B2E0F9" />
                    <stop offset="1" stopColor="#96D6F7" />
                </linearGradient>
                <linearGradient
                    id="paint35_linear_10025_65880"
                    x1="408.328"
                    y1="436.378"
                    x2="408.328"
                    y2="423.513"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#C1E8E0" stopOpacity="0.8" />
                    <stop offset="0.71" stopColor="#B2E0F9" />
                    <stop offset="1" stopColor="#96D6F7" />
                </linearGradient>
                <linearGradient
                    id="paint36_linear_10025_65880"
                    x1="343.611"
                    y1="395.227"
                    x2="343.611"
                    y2="382.235"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#C1E8E0" stopOpacity="0.8" />
                    <stop offset="0.71" stopColor="#B2E0F9" />
                    <stop offset="1" stopColor="#96D6F7" />
                </linearGradient>
                <linearGradient
                    id="paint37_linear_10025_65880"
                    x1="359.147"
                    y1="390.29"
                    x2="359.147"
                    y2="377.489"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#C1E8E0" stopOpacity="0.8" />
                    <stop offset="0.71" stopColor="#B2E0F9" />
                    <stop offset="1" stopColor="#96D6F7" />
                </linearGradient>
                <linearGradient
                    id="paint38_linear_10025_65880"
                    x1="380.1"
                    y1="400.003"
                    x2="380.1"
                    y2="387.138"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#C1E8E0" stopOpacity="0.8" />
                    <stop offset="0.71" stopColor="#B2E0F9" />
                    <stop offset="1" stopColor="#96D6F7" />
                </linearGradient>
                <linearGradient
                    id="paint39_linear_10025_65880"
                    x1="385.92"
                    y1="407.529"
                    x2="385.92"
                    y2="394.664"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#C1E8E0" stopOpacity="0.8" />
                    <stop offset="0.71" stopColor="#B2E0F9" />
                    <stop offset="1" stopColor="#96D6F7" />
                </linearGradient>
                <linearGradient
                    id="paint40_linear_10025_65880"
                    x1="439.206"
                    y1="459.043"
                    x2="439.206"
                    y2="446.307"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#C1E8E0" stopOpacity="0.8" />
                    <stop offset="0.71" stopColor="#B2E0F9" />
                    <stop offset="1" stopColor="#96D6F7" />
                </linearGradient>
                <linearGradient
                    id="paint41_linear_10025_65880"
                    x1="390.053"
                    y1="432.502"
                    x2="390.053"
                    y2="419.766"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#C1E8E0" stopOpacity="0.8" />
                    <stop offset="0.71" stopColor="#B2E0F9" />
                    <stop offset="1" stopColor="#96D6F7" />
                </linearGradient>
                <linearGradient
                    id="paint42_linear_10025_65880"
                    x1="264.421"
                    y1="333.903"
                    x2="155.083"
                    y2="333.903"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.06" stopColor="#051937" />
                    <stop offset="0.98" stopColor="#051937" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                    id="paint43_linear_10025_65880"
                    x1="230.314"
                    y1="221.247"
                    x2="219.862"
                    y2="212.82"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#9EAAEA" />
                    <stop offset="0.14" stopColor="#8192DF" />
                    <stop offset="0.3" stopColor="#5066C9" />
                    <stop offset="0.44" stopColor="#233EB4" />
                    <stop offset="0.52" stopColor="#0D2AAC" />
                    <stop offset="1" stopColor="#FF6CD0" />
                </linearGradient>
                <linearGradient
                    id="paint44_linear_10025_65880"
                    x1="228.063"
                    y1="218.867"
                    x2="219.347"
                    y2="213.206"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FCA4F3" />
                    <stop offset="0.22" stopColor="#DF81DB" />
                    <stop offset="0.38" stopColor="#C750C9" />
                    <stop offset="0.51" stopColor="#B423A5" />
                    <stop offset="0.64" stopColor="#AC0D9C" />
                    <stop offset="1" stopColor="#FF6CD0" />
                </linearGradient>
                <linearGradient
                    id="paint45_linear_10025_65880"
                    x1="226.358"
                    y1="228.965"
                    x2="251.573"
                    y2="225.267"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#6726A5" />
                    <stop offset="1" stopColor="#DA4DEF" />
                </linearGradient>
                <radialGradient
                    id="paint46_radial_10025_65880"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(212.977 282.42) rotate(-119.578) scale(68.4152 65.6231)"
                >
                    <stop offset="0.02" stopColor="#F0B2F5" />
                    <stop offset="0.33" stopColor="#FCA2F3" />
                    <stop offset="0.56" stopColor="#F966E1" />
                    <stop offset="0.78" stopColor="#E345D3" />
                    <stop offset="0.91" stopColor="#E233D0" />
                    <stop offset="1" stopColor="#FF6CD0" />
                </radialGradient>
                <linearGradient
                    id="paint47_linear_10025_65880"
                    x1="3926.62"
                    y1="-124395"
                    x2="4118.52"
                    y2="-124115"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FCA4F3" />
                    <stop offset="0.22" stopColor="#DF81DB" />
                    <stop offset="0.38" stopColor="#C750C9" />
                    <stop offset="0.51" stopColor="#B423A5" />
                    <stop offset="0.64" stopColor="#AC0D9C" />
                    <stop offset="1" stopColor="#FF6CD0" />
                </linearGradient>
                <linearGradient
                    id="paint48_linear_10025_65880"
                    x1="3646.91"
                    y1="-121036"
                    x2="2974.92"
                    y2="-121085"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#6726A5" />
                    <stop offset="1" stopColor="#DA4DEF" />
                </linearGradient>
                <radialGradient
                    id="paint49_radial_10025_65880"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(246.747 316.189) rotate(-135) scale(11.371 11.4044)"
                >
                    <stop offset="0.47" stopColor="#051140" />
                    <stop offset="1" stopColor="#FF6CD0" />
                </radialGradient>
                <radialGradient
                    id="paint50_radial_10025_65880"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(225.561 274.204) rotate(53.1301) scale(16.75 13.4324)"
                >
                    <stop offset="0.02" stopColor="#0D1276" />
                    <stop offset="0.47" stopColor="#0D2AAC" />
                    <stop offset="1" stopColor="#FF6CD0" />
                </radialGradient>
                <radialGradient
                    id="paint51_radial_10025_65880"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(256.452 282.317) rotate(53.148) scale(16.8068 13.4897)"
                >
                    <stop offset="0.0221768" stopColor="#0D1276" />
                    <stop offset="0.471" stopColor="#0D2AAC" />
                    <stop offset="1" stopColor="#FF6CD0" />
                </radialGradient>
                <radialGradient
                    id="paint52_radial_10025_65880"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(260.743 284.865) rotate(142.406) scale(14.9681 6.76012)"
                >
                    <stop stopColor="#FF6CD0" />
                    <stop
                        offset="0.21"
                        stopColor="#FC6ED1"
                        stopOpacity="0.78"
                    />
                    <stop
                        offset="0.37"
                        stopColor="#F374D3"
                        stopOpacity="0.62"
                    />
                    <stop
                        offset="0.51"
                        stopColor="#E37DD7"
                        stopOpacity="0.48"
                    />
                    <stop
                        offset="0.64"
                        stopColor="#CC8BDC"
                        stopOpacity="0.35"
                    />
                    <stop
                        offset="0.76"
                        stopColor="#AF9DE3"
                        stopOpacity="0.22"
                    />
                    <stop
                        offset="0.87"
                        stopColor="#8BB3EB"
                        stopOpacity="0.11"
                    />
                    <stop offset="0.98" stopColor="#64CBF4" stopOpacity="0" />
                </radialGradient>
                <radialGradient
                    id="paint53_radial_10025_65880"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(424319 -133643) rotate(180) scale(118.94 329.1)"
                >
                    <stop offset="0.02" stopColor="#F0B2F5" />
                    <stop offset="0.33" stopColor="#FCA2F3" />
                    <stop offset="0.56" stopColor="#F966E1" />
                    <stop offset="0.78" stopColor="#E345D3" />
                    <stop offset="0.91" stopColor="#E233D0" />
                    <stop offset="1" stopColor="#FF6CD0" />
                </radialGradient>
                <radialGradient
                    id="paint54_radial_10025_65880"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(257627 -353545) rotate(124.579) scale(134.132 142.298)"
                >
                    <stop offset="0.02" stopColor="#0D1276" />
                    <stop offset="0.47" stopColor="#0D2AAC" />
                    <stop offset="1" stopColor="#FF6CD0" />
                </radialGradient>
                <radialGradient
                    id="paint55_radial_10025_65880"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(217.058 306.901) rotate(45.2967) scale(18.3612 15.3533)"
                >
                    <stop offset="0.0221768" stopColor="#0D1276" />
                    <stop offset="0.471" stopColor="#0D2AAC" />
                    <stop offset="1" stopColor="#FF6CD0" />
                </radialGradient>
                <radialGradient
                    id="paint56_radial_10025_65880"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(636423 -210574) rotate(160.477) scale(181.315 42.3436)"
                >
                    <stop stopColor="#FF6CD0" />
                    <stop
                        offset="0.21"
                        stopColor="#FC6ED1"
                        stopOpacity="0.78"
                    />
                    <stop
                        offset="0.37"
                        stopColor="#F374D3"
                        stopOpacity="0.62"
                    />
                    <stop
                        offset="0.51"
                        stopColor="#E37DD7"
                        stopOpacity="0.48"
                    />
                    <stop
                        offset="0.64"
                        stopColor="#CC8BDC"
                        stopOpacity="0.35"
                    />
                    <stop
                        offset="0.76"
                        stopColor="#AF9DE3"
                        stopOpacity="0.22"
                    />
                    <stop
                        offset="0.87"
                        stopColor="#8BB3EB"
                        stopOpacity="0.11"
                    />
                    <stop offset="0.98" stopColor="#64CBF4" stopOpacity="0" />
                </radialGradient>
                <radialGradient
                    id="paint57_radial_10025_65880"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(827899 -38257.4) rotate(180) scale(225.433 129.735)"
                >
                    <stop offset="0.02" stopColor="#F0B2F5" />
                    <stop offset="0.33" stopColor="#FCA2F3" />
                    <stop offset="0.56" stopColor="#F966E1" />
                    <stop offset="0.78" stopColor="#E345D3" />
                    <stop offset="0.91" stopColor="#E233D0" />
                    <stop offset="1" stopColor="#FF6CD0" />
                </radialGradient>
                <linearGradient
                    id="paint58_linear_10025_65880"
                    x1="292.387"
                    y1="208.446"
                    x2="272.382"
                    y2="236.17"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#C74966" />
                    <stop offset="1" stopColor="#CC305A" />
                </linearGradient>
                <linearGradient
                    id="paint59_linear_10025_65880"
                    x1="282.899"
                    y1="200.663"
                    x2="282.899"
                    y2="265.244"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FF7999" />
                    <stop offset="1" stopColor="#F42058" />
                </linearGradient>
                <linearGradient
                    id="paint60_linear_10025_65880"
                    x1="282.835"
                    y1="215.811"
                    x2="282.835"
                    y2="240.447"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#EF7C97" />
                    <stop offset="1" stopColor="#E53866" />
                </linearGradient>
                <linearGradient
                    id="paint61_linear_10025_65880"
                    x1="7.65637"
                    y1="298.403"
                    x2="116.01"
                    y2="298.403"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#5761D7" />
                    <stop offset="0.07" stopColor="#5472D8" />
                    <stop offset="0.28" stopColor="#4E9CDB" />
                    <stop offset="0.48" stopColor="#49BDDD" />
                    <stop offset="0.67" stopColor="#45D5DF" />
                    <stop offset="0.85" stopColor="#43E3E0" />
                    <stop offset="1" stopColor="#42E8E0" />
                </linearGradient>
                <linearGradient
                    id="paint62_linear_10025_65880"
                    x1="674.967"
                    y1="4869.55"
                    x2="4324.52"
                    y2="4869.55"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#5761D7" />
                    <stop offset="0.07" stopColor="#5472D8" />
                    <stop offset="0.28" stopColor="#4E9CDB" />
                    <stop offset="0.48" stopColor="#49BDDD" />
                    <stop offset="0.67" stopColor="#45D5DF" />
                    <stop offset="0.85" stopColor="#43E3E0" />
                    <stop offset="1" stopColor="#42E8E0" />
                </linearGradient>
                <linearGradient
                    id="paint63_linear_10025_65880"
                    x1="674.967"
                    y1="4713.38"
                    x2="4324.52"
                    y2="4713.38"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#5761D7" />
                    <stop offset="0.07" stopColor="#5472D8" />
                    <stop offset="0.28" stopColor="#4E9CDB" />
                    <stop offset="0.48" stopColor="#49BDDD" />
                    <stop offset="0.67" stopColor="#45D5DF" />
                    <stop offset="0.85" stopColor="#43E3E0" />
                    <stop offset="1" stopColor="#42E8E0" />
                </linearGradient>
                <linearGradient
                    id="paint64_linear_10025_65880"
                    x1="674.985"
                    y1="4532.42"
                    x2="4324.66"
                    y2="4532.42"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#5761D7" />
                    <stop offset="0.07" stopColor="#5472D8" />
                    <stop offset="0.28" stopColor="#4E9CDB" />
                    <stop offset="0.48" stopColor="#49BDDD" />
                    <stop offset="0.67" stopColor="#45D5DF" />
                    <stop offset="0.85" stopColor="#43E3E0" />
                    <stop offset="1" stopColor="#42E8E0" />
                </linearGradient>
                <linearGradient
                    id="paint65_linear_10025_65880"
                    x1="675.119"
                    y1="6600.5"
                    x2="4325.58"
                    y2="6600.5"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#2D5382" />
                    <stop offset="1" stopColor="#3C88C4" />
                </linearGradient>
                <linearGradient
                    id="paint66_linear_10025_65880"
                    x1="7.65623"
                    y1="273.06"
                    x2="116.01"
                    y2="273.06"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#1D9DD7" />
                </linearGradient>
                <linearGradient
                    id="paint67_linear_10025_65880"
                    x1="61.8493"
                    y1="297.245"
                    x2="61.8493"
                    y2="263.571"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#42E8E0" stopOpacity="0" />
                    <stop
                        offset="0.18"
                        stopColor="#42E8E0"
                        stopOpacity="0.25"
                    />
                    <stop offset="0.71" stopColor="#42E8E0" />
                    <stop offset="1" stopColor="#A7F9EF" />
                </linearGradient>
                <linearGradient
                    id="paint68_linear_10025_65880"
                    x1="2067.72"
                    y1="4055.53"
                    x2="2070.03"
                    y2="3408.93"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#42E8E0" stopOpacity="0" />
                    <stop
                        offset="0.18"
                        stopColor="#42E8E0"
                        stopOpacity="0.25"
                    />
                    <stop offset="0.71" stopColor="#42E8E0" />
                    <stop offset="1" stopColor="#A7F9EF" />
                </linearGradient>
                <linearGradient
                    id="paint69_linear_10025_65880"
                    x1="2073.08"
                    y1="3847.45"
                    x2="2073.08"
                    y2="3425.99"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#42E8E0" stopOpacity="0" />
                    <stop
                        offset="0.18"
                        stopColor="#42E8E0"
                        stopOpacity="0.25"
                    />
                    <stop offset="0.71" stopColor="#42E8E0" />
                    <stop offset="1" stopColor="#A7F9EF" />
                </linearGradient>
                <linearGradient
                    id="paint70_linear_10025_65880"
                    x1="806.948"
                    y1="-120342"
                    x2="982.079"
                    y2="-120235"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#9EAAEA" />
                    <stop offset="0.14" stopColor="#8192DF" />
                    <stop offset="0.3" stopColor="#5066C9" />
                    <stop offset="0.44" stopColor="#233EB4" />
                    <stop offset="0.52" stopColor="#0D2AAC" />
                    <stop offset="1" stopColor="#FF6CD0" />
                </linearGradient>
                <linearGradient
                    id="paint71_linear_10025_65880"
                    x1="49.9495"
                    y1="132.801"
                    x2="22.5796"
                    y2="128.813"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#2525C6" />
                    <stop offset="1" stopColor="#4D91EE" />
                </linearGradient>
                <linearGradient
                    id="paint72_linear_10025_65880"
                    x1="49.0785"
                    y1="126.784"
                    x2="19.8519"
                    y2="128.637"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#2525C6" />
                    <stop offset="1" stopColor="#4D91EE" />
                </linearGradient>
                <radialGradient
                    id="paint73_radial_10025_65880"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(65.0315 189.149) rotate(-3.9005) scale(70.9208 70.9952)"
                >
                    <stop offset="0.02" stopColor="#B2E5F5" />
                    <stop offset="0.33" stopColor="#A2D5FC" />
                    <stop offset="0.56" stopColor="#66AFF9" />
                    <stop offset="0.78" stopColor="#4593E3" />
                    <stop offset="0.91" stopColor="#3389E2" />
                    <stop offset="1" stopColor="#BBEEF8" />
                </radialGradient>
                <radialGradient
                    id="paint74_radial_10025_65880"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(26.0877 159.107) scale(17.774)"
                >
                    <stop stopColor="#3781E8" />
                    <stop offset="1" stopColor="#3D8EE3" stopOpacity="0" />
                </radialGradient>
                <radialGradient
                    id="paint75_radial_10025_65880"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(49.0264 162.127) scale(14.4904 14.4078)"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#FF8FE7" />
                </radialGradient>
                <radialGradient
                    id="paint76_radial_10025_65880"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(10.8698 153.059) scale(12.4814)"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#FFB1E2" />
                </radialGradient>
                <radialGradient
                    id="paint77_radial_10025_65880"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(46.7023 153.638) scale(12.5342 12.5406)"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#FFB1E2" />
                </radialGradient>
                <radialGradient
                    id="paint78_radial_10025_65880"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(25.2327 139.852) scale(10.6135)"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#FFBAE0" />
                </radialGradient>
                <radialGradient
                    id="paint79_radial_10025_65880"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(44.8157 144.147) scale(10.6784 10.6838)"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#FFBAE0" />
                </radialGradient>
                <radialGradient
                    id="paint80_radial_10025_65880"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(37.4704 129.515) scale(8.7455)"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#FFCCDE" />
                </radialGradient>
                <radialGradient
                    id="paint81_radial_10025_65880"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(43.8437 137.653) scale(8.79994 8.80422)"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#FFCCDE" />
                </radialGradient>
                <radialGradient
                    id="paint82_radial_10025_65880"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(46.0717 119.173) scale(6.87753)"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#FFCCD5" />
                </radialGradient>
                <radialGradient
                    id="paint83_radial_10025_65880"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(42.1847 131.188) scale(6.92443 6.92778)"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#FFCCD5" />
                </radialGradient>
                <radialGradient
                    id="paint84_radial_10025_65880"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(50.8632 107.57) scale(5.00956)"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#FFCCC8" />
                </radialGradient>
                <radialGradient
                    id="paint85_radial_10025_65880"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(41.338 125.755) scale(5.04204 5.0446)"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#FFCCC8" />
                </radialGradient>
                <linearGradient
                    id="paint86_linear_10025_65880"
                    x1="109.192"
                    y1="161.843"
                    x2="97.5493"
                    y2="147.274"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#A4D7FC" />
                    <stop offset="0.22" stopColor="#8192DF" />
                    <stop offset="0.38" stopColor="#5066C9" />
                    <stop offset="0.51" stopColor="#233EB4" />
                    <stop offset="0.64" stopColor="#0D2AAC" />
                    <stop offset="1" stopColor="#FF6CD0" />
                </linearGradient>
                <linearGradient
                    id="paint87_linear_10025_65880"
                    x1="97.925"
                    y1="160.232"
                    x2="132.267"
                    y2="165.896"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#2525C6" />
                    <stop offset="1" stopColor="#4D91EE" />
                </linearGradient>
                <radialGradient
                    id="paint88_radial_10025_65880"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(-92405.3 26822.7) scale(47.1942 34.7247)"
                >
                    <stop offset="0.47" stopColor="#051140" />
                    <stop offset="1" stopColor="#FF6CD0" />
                </radialGradient>
                <radialGradient
                    id="paint89_radial_10025_65880"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(33.3151 222.907) rotate(-135.124) scale(11.9824 12.0175)"
                >
                    <stop offset="0.47" stopColor="#051140" />
                    <stop offset="1" stopColor="#FF6CD0" />
                </radialGradient>
                <radialGradient
                    id="paint90_radial_10025_65880"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(55.9774 205.987) rotate(48.916) scale(19.1379 15.8543)"
                >
                    <stop offset="0.0221768" stopColor="#0D1276" />
                    <stop offset="0.471" stopColor="#0D2AAC" />
                    <stop offset="1" stopColor="#FF6CD0" />
                </radialGradient>
                <radialGradient
                    id="paint91_radial_10025_65880"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(-423699 -139751) rotate(21.8562) scale(175.218 50.1608)"
                >
                    <stop stopColor="#FF6CD0" />
                    <stop
                        offset="0.21"
                        stopColor="#FC6ED1"
                        stopOpacity="0.78"
                    />
                    <stop
                        offset="0.37"
                        stopColor="#F374D3"
                        stopOpacity="0.62"
                    />
                    <stop
                        offset="0.51"
                        stopColor="#E37DD7"
                        stopOpacity="0.48"
                    />
                    <stop
                        offset="0.64"
                        stopColor="#CC8BDC"
                        stopOpacity="0.35"
                    />
                    <stop
                        offset="0.76"
                        stopColor="#AF9DE3"
                        stopOpacity="0.22"
                    />
                    <stop
                        offset="0.87"
                        stopColor="#8BB3EB"
                        stopOpacity="0.11"
                    />
                    <stop offset="0.98" stopColor="#64CBF4" stopOpacity="0" />
                </radialGradient>
                <radialGradient
                    id="paint92_radial_10025_65880"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(18.8523 185.017) rotate(53.1763) scale(16.4563 13.2046)"
                >
                    <stop offset="0.0221768" stopColor="#0D1276" />
                    <stop offset="0.471" stopColor="#0D2AAC" />
                    <stop offset="1" stopColor="#FF6CD0" />
                </radialGradient>
                <radialGradient
                    id="paint93_radial_10025_65880"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(-239314 -171408) rotate(37.6024) scale(122.321 43.809)"
                >
                    <stop stopColor="#FF6CD0" />
                    <stop
                        offset="0.21"
                        stopColor="#FC6ED1"
                        stopOpacity="0.78"
                    />
                    <stop
                        offset="0.37"
                        stopColor="#F374D3"
                        stopOpacity="0.62"
                    />
                    <stop
                        offset="0.51"
                        stopColor="#E37DD7"
                        stopOpacity="0.48"
                    />
                    <stop
                        offset="0.64"
                        stopColor="#CC8BDC"
                        stopOpacity="0.35"
                    />
                    <stop
                        offset="0.76"
                        stopColor="#AF9DE3"
                        stopOpacity="0.22"
                    />
                    <stop
                        offset="0.87"
                        stopColor="#8BB3EB"
                        stopOpacity="0.11"
                    />
                    <stop offset="0.98" stopColor="#64CBF4" stopOpacity="0" />
                </radialGradient>
                <linearGradient
                    id="paint94_linear_10025_65880"
                    x1="63.554"
                    y1="228.837"
                    x2="63.554"
                    y2="255.926"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#E6EDFF" stopOpacity="0" />
                    <stop offset="1" stopColor="#BCEEF9" />
                </linearGradient>
                <linearGradient
                    id="paint95_linear_10025_65880"
                    x1="78.7667"
                    y1="228.258"
                    x2="78.7667"
                    y2="258.75"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#E6EDFF" stopOpacity="0" />
                    <stop offset="1" stopColor="#BCEEF9" />
                </linearGradient>
                <linearGradient
                    id="paint96_linear_10025_65880"
                    x1="92.5641"
                    y1="224.206"
                    x2="92.5641"
                    y2="260.388"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#E6EDFF" stopOpacity="0" />
                    <stop offset="1" stopColor="#BCEEF9" />
                </linearGradient>
                <linearGradient
                    id="paint97_linear_10025_65880"
                    x1="66.5611"
                    y1="242.216"
                    x2="66.5611"
                    y2="254.823"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#E6EDFF" stopOpacity="0" />
                    <stop offset="1" stopColor="#BCEEF9" />
                </linearGradient>
            </defs>
        </svg>
    );
};

export default bundle;
